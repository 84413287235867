.page-privacy-game .btn {
  background-color: #fff;
  padding: 10px 0;
  border: 2px solid #1c8e91;
  border-radius: 999px;
  font-size: 24px;
  font-weight: 600;
  transition: 200ms all ease-in-out; }
  .page-privacy-game .btn:hover {
    box-shadow: 3px 3px 0 rgba(28, 142, 145, 0.6); }

.page-privacy-game .game-container {
  color: #333;
  width: 100%;
  background-color: #aeeae4;
  margin-top: 120px;
  min-height: calc(100vh - 120px); }
  @media (max-width: 1260px) {
    .page-privacy-game .game-container {
      margin-top: 64px;
      min-height: calc(100vh - 64px); } }
  .page-privacy-game .game-container .popup-container {
    position: fixed;
    z-index: 99999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: none; }
    .page-privacy-game .game-container .popup-container .shadow {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.45);
      opacity: 0;
      transition: 200ms all ease-in-out;
      cursor: pointer; }
    .page-privacy-game .game-container .popup-container .popup {
      background-color: #fff;
      border: 3px solid #1c8e91;
      box-shadow: 3px 3px 0 #1c8e91;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 20px;
      padding: 50px;
      width: 90%;
      max-width: 450px;
      opacity: 0;
      margin-top: 30px;
      transition: 200ms all ease-in-out; }
      .page-privacy-game .game-container .popup-container .popup .title {
        font-size: 32px;
        font-weight: 600;
        text-align: center; }
      .page-privacy-game .game-container .popup-container .popup img {
        width: 90%;
        max-width: 242px;
        margin: 30px auto;
        display: block; }
      .page-privacy-game .game-container .popup-container .popup .content {
        text-align: center;
        font-size: 24px;
        font-weight: 400; }
        .page-privacy-game .game-container .popup-container .popup .content .highlight {
          color: #f35d08;
          font-weight: 600;
          font-size: 30px; }
      .page-privacy-game .game-container .popup-container .popup .btn-start {
        background-color: #f35d08;
        width: 100%;
        text-align: center;
        padding: 5px 0;
        margin: 0 auto;
        color: #fff;
        font-size: 28px;
        font-weight: 600;
        border-radius: 999px;
        margin-top: 30px;
        cursor: pointer;
        border: 0; }
        .page-privacy-game .game-container .popup-container .popup .btn-start:hover {
          box-shadow: 3px 3px 0 rgba(243, 93, 8, 0.6); }
      @media screen and (max-width: 768px) {
        .page-privacy-game .game-container .popup-container .popup {
          padding: 30px; }
          .page-privacy-game .game-container .popup-container .popup .title {
            font-size: 20px; }
          .page-privacy-game .game-container .popup-container .popup .content {
            font-size: 16px; }
            .page-privacy-game .game-container .popup-container .popup .content .highlight {
              font-size: 20px; }
          .page-privacy-game .game-container .popup-container .popup .btn-start {
            font-size: 18px; } }
    .page-privacy-game .game-container .popup-container.active {
      pointer-events: all;
      display: block; }
      .page-privacy-game .game-container .popup-container.active .shadow {
        opacity: 1; }
      .page-privacy-game .game-container .popup-container.active .popup {
        opacity: 1;
        margin-top: 0; }
  .page-privacy-game .game-container .stage-intro {
    position: relative; }
    .page-privacy-game .game-container .stage-intro .title {
      text-align: center;
      font-size: 20px;
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translateX(-50%); }
      @media (max-width: 768px) {
        .page-privacy-game .game-container .stage-intro .title {
          top: 15%;
          font-size: 14px; } }
    .page-privacy-game .game-container .stage-intro img.intro {
      width: 100%; }
      .page-privacy-game .game-container .stage-intro img.intro.desktop {
        margin: 40px auto; }
      .page-privacy-game .game-container .stage-intro img.intro.mobile {
        display: none;
        margin: 0 auto;
        max-width: 300px; }
      @media (max-width: 768px) {
        .page-privacy-game .game-container .stage-intro img.intro.mobile {
          display: block; }
        .page-privacy-game .game-container .stage-intro img.intro.desktop {
          display: none; } }
    .page-privacy-game .game-container .stage-intro .lead-in {
      text-align: center;
      text-align: center;
      font-size: 24px; }
      @media (max-width: 992px) {
        .page-privacy-game .game-container .stage-intro .lead-in {
          font-size: 18px; } }
    .page-privacy-game .game-container .stage-intro .btn-start {
      border-radius: 80px;
      border: 3px solid #fff;
      background: #f35d08;
      color: #fff;
      font-size: 28px;
      margin: 30px auto;
      display: table;
      padding: 12px 104px;
      cursor: pointer; }
      .page-privacy-game .game-container .stage-intro .btn-start:hover {
        box-shadow: 3px 3px 0 rgba(243, 93, 8, 0.6); }
      @media (max-width: 768px) {
        .page-privacy-game .game-container .stage-intro .btn-start {
          font-size: 22px;
          width: 100%;
          margin: 15px auto; } }
  .page-privacy-game .game-container .stage-menu .title {
    text-align: center;
    font-size: 32px;
    margin: 190px auto 95px;
    font-weight: bold; }
  .page-privacy-game .game-container .stage-menu .items {
    display: flex;
    justify-content: space-between; }
    .page-privacy-game .game-container .stage-menu .items .item {
      padding: 15px 15px 25px 15px;
      background-color: #fff;
      border-radius: 20px;
      width: 30%;
      transition: all 0.2s ease-in-out;
      cursor: pointer; }
      .page-privacy-game .game-container .stage-menu .items .item .thumbnail {
        width: 100%;
        height: 0;
        position: relative;
        padding-top: 100%;
        border-radius: 15px;
        overflow: hidden;
        border: 0; }
        .page-privacy-game .game-container .stage-menu .items .item .thumbnail img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          object-fit: cover;
          width: 110%;
          height: 110%; }
      .page-privacy-game .game-container .stage-menu .items .item .name {
        font-size: 18px;
        text-align: center;
        margin-top: 25px; }
      .page-privacy-game .game-container .stage-menu .items .item:nth-child(1) .thumbnail img {
        object-position: 43%; }
      .page-privacy-game .game-container .stage-menu .items .item:nth-child(2) .thumbnail img {
        object-position: 5%; }
      .page-privacy-game .game-container .stage-menu .items .item:nth-child(3) .thumbnail img {
        object-position: 15%; }
      .page-privacy-game .game-container .stage-menu .items .item:hover {
        transform: scale(1.05);
        border: 3px solid #1c8e91;
        box-shadow: 3px 3px 0 #1c8e91; }
  @media (max-width: 768px) {
    .page-privacy-game .game-container .stage-menu .title {
      font-size: 20px;
      margin: 20px 0; }
    .page-privacy-game .game-container .stage-menu .items {
      flex-wrap: wrap;
      justify-content: center; }
      .page-privacy-game .game-container .stage-menu .items .item {
        width: 100%;
        margin-bottom: 15px;
        max-width: 400px; }
        .page-privacy-game .game-container .stage-menu .items .item .thumbnail {
          padding-top: 40%; } }
  .page-privacy-game .game-container .stage-game {
    max-width: 1600px;
    width: 95%; }
    .page-privacy-game .game-container .stage-game .info {
      position: relative;
      margin-top: 50px; }
      .page-privacy-game .game-container .stage-game .info .score {
        border-radius: 99px;
        border: 3px solid #1c8e91;
        background: #fff;
        display: table;
        padding: 15px 38px;
        z-index: 9;
        position: relative; }
        .page-privacy-game .game-container .stage-game .info .score img {
          width: 50px; }
          .page-privacy-game .game-container .stage-game .info .score img:not(:last-child) {
            margin-right: 10px; }
      .page-privacy-game .game-container .stage-game .info .time-container {
        border-radius: 60px;
        border: 3px solid #1c8e91;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 230px;
        transform: translateY(-50%);
        width: calc(100% - 230px - 60px);
        height: 44px; }
        .page-privacy-game .game-container .stage-game .info .time-container .time {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 100%;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          height: 18px;
          padding: 0 15px; }
        .page-privacy-game .game-container .stage-game .info .time-container .progress {
          width: 100%;
          border-radius: 100px;
          background: #09d2bf;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
      .page-privacy-game .game-container .stage-game .info .timer {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%); }
        .page-privacy-game .game-container .stage-game .info .timer img {
          height: 100px; }
        .page-privacy-game .game-container .stage-game .info .timer .value {
          color: #096163;
          font-size: 24px;
          position: absolute;
          text-align: center;
          top: 58%;
          left: 50%;
          transform: translate(-50%, -50%); }
    .page-privacy-game .game-container .stage-game .canvases {
      display: flex;
      justify-content: space-between;
      margin-top: 40px; }
      .page-privacy-game .game-container .stage-game .canvases .canvas {
        width: 49%;
        cursor: pointer; }
        .page-privacy-game .game-container .stage-game .canvases .canvas canvas {
          width: 100%; }
    @media (max-width: 768px) {
      .page-privacy-game .game-container .stage-game .info {
        margin-top: 15px; }
        .page-privacy-game .game-container .stage-game .info .score {
          padding: 10px 20px; }
          .page-privacy-game .game-container .stage-game .info .score img {
            width: 30px; }
        .page-privacy-game .game-container .stage-game .info .time-container {
          left: 130px;
          width: calc(100% - 130px - 30px); }
        .page-privacy-game .game-container .stage-game .info .timer img {
          height: 60px; }
        .page-privacy-game .game-container .stage-game .info .timer .value {
          font-size: 20px; }
      .page-privacy-game .game-container .stage-game .canvases {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 10px; }
        .page-privacy-game .game-container .stage-game .canvases .canvas {
          width: 100%; } }
  .page-privacy-game .game-container .stage-result > .title {
    margin: 15px auto 0; }
    .page-privacy-game .game-container .stage-result > .title img {
      max-width: 200px;
      margin: 0 auto;
      display: block; }
  .page-privacy-game .game-container .stage-result .founds {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    letter-spacing: 2px; }
    .page-privacy-game .game-container .stage-result .founds .highlight {
      font-size: 36px;
      color: #f35d08;
      transform: translateY(5px);
      padding: 0 5px;
      display: inline-block;
      text-shadow: -1px -1px 0 #fff, 0 -1px 0 #fff, 1px -1px 0 #fff, 1px 0 0 #fff, 1px 1px 0 #fff, 0 1px 0 #fff, -1px 1px 0 #fff, -1px 0 0 #fff; }
  .page-privacy-game .game-container .stage-result .result-text {
    width: 100%; }
  .page-privacy-game .game-container .stage-result .btns {
    display: flex;
    justify-content: space-between;
    margin-top: 50px; }
    .page-privacy-game .game-container .stage-result .btns .btn {
      width: 30%; }
      .page-privacy-game .game-container .stage-result .btns .btn.btn-share {
        background-color: #f35d08;
        color: #fff;
        border-color: #fff; }
        .page-privacy-game .game-container .stage-result .btns .btn.btn-share:hover {
          box-shadow: 3px 3px 0 rgba(243, 93, 8, 0.6); }
      @media (max-width: 768px) {
        .page-privacy-game .game-container .stage-result .btns .btn {
          width: 32%;
          font-size: 18px;
          padding: 6px; } }
  .page-privacy-game .game-container .stage-result .remind {
    text-align: center;
    font-weight: 600;
    font-size: 28px;
    margin: 60px 0; }
    .page-privacy-game .game-container .stage-result .remind img {
      margin-left: 5px; }
    @media (max-width: 768px) {
      .page-privacy-game .game-container .stage-result .remind {
        font-size: 16px; }
        .page-privacy-game .game-container .stage-result .remind img {
          width: 14px; } }
  .page-privacy-game .game-container .stage-result .tips {
    background-color: #fff;
    border: 2px solid #1c8e91;
    box-shadow: 3px 3px 0 #1c8e91;
    border-radius: 20px;
    padding: 0 60px;
    margin-bottom: 50px; }
    .page-privacy-game .game-container .stage-result .tips .tip {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 50px 0; }
      .page-privacy-game .game-container .stage-result .tips .tip:nth-child(even) {
        flex-direction: row-reverse; }
      .page-privacy-game .game-container .stage-result .tips .tip .content {
        width: 52%; }
        .page-privacy-game .game-container .stage-result .tips .tip .content .title {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 24px; }
        .page-privacy-game .game-container .stage-result .tips .tip .content .detail {
          line-height: 2;
          font-size: 16px; }
          .page-privacy-game .game-container .stage-result .tips .tip .content .detail ul {
            margin-left: 15px; }
            .page-privacy-game .game-container .stage-result .tips .tip .content .detail ul li {
              list-style: disc; }
        .page-privacy-game .game-container .stage-result .tips .tip .content .app-download a {
          display: table;
          width: 29.3%;
          float: left;
          margin-left: 1%; }
      .page-privacy-game .game-container .stage-result .tips .tip .image {
        width: 45%; }
        .page-privacy-game .game-container .stage-result .tips .tip .image img {
          width: 100%; }
    @media (max-width: 768px) {
      .page-privacy-game .game-container .stage-result .tips {
        padding: 20px 10px; }
        .page-privacy-game .game-container .stage-result .tips .tip {
          margin: 20px 0;
          flex-direction: column-reverse !important; }
          .page-privacy-game .game-container .stage-result .tips .tip .content,
          .page-privacy-game .game-container .stage-result .tips .tip .image {
            width: 80%; } }

[lang="en"] .page-privacy-game .game-container .stage-result .result-text .text-container .text {
  width: 60%; }
  @media (max-width: 600px) {
    [lang="en"] .page-privacy-game .game-container .stage-result .result-text .text-container .text {
      font-size: 10px;
      left: 42%; }
      [lang="en"] .page-privacy-game .game-container .stage-result .result-text .text-container .text .highlight {
        font-size: 18px; } }

@media (max-width: 600px) {
  [lang="en"] .page-privacy-game .game-container .stage-result .btns .btn {
    width: 32%;
    font-size: 12px; } }

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

.absolute-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  &.back {
    z-index: 97;
  }

  &.middle {
    z-index: 98;
  }

  &.front {
    z-index: 99;
  }
}

div.page-container {
  //font-family: "RobotoR", "SourceHanSansCNR", "Arial", "Microsoft YaHei","sans-serif";
  // -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow-x: hidden;
  width: 100%;
}

div#ProNav * {
  // font-family: "RobotoR", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

div.page-container-en {
  font-family: "RobotoR", "SourceHanSansCNR", "Arial", "Microsoft YaHei",
    "sans-serif" !important;
}

.page-container {
  img {
    max-width: 100%;
    transform: translateZ(0) scale(1, 1);
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
    // image-rendering: crisp-edges;
    // image-rendering: -moz-crisp-edges; /* Firefox */
    // image-rendering: -o-crisp-edges; /* Opera */
    // image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming)*/
    // -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    //     filter: blur(0);
    // -webkit-filter: blur(0);
    // backface-visibility: hidden;
    // -webkit-backface-visibility: hidden;
    // transform: translateZ(0);
    // -webkit-transform: translateZ(0);
    // -webkit-font-smoothing: antialised;
    // -webkit-font-smoothing: subpixel-antialiased;
  }
}

.logo,
.disabledLogo {
  img {
    max-width: 100%;
  }
}

body {
  margin: 0px;
}

.mobile-show {
  display: none;
}

.tablet-show {
  display: none;
}

.desktop-show {
  display: block;
}

.container {
  max-width: 900px;
}

.vertical-align {
  display: flex;
  align-items: center;
}

/********** Slider *********/
div#second-slider {
  position: relative;
  overflow: hidden;
  padding: 0px;
  padding-bottom: 50px;
  padding-top: 100px;
}

a.second-slider-btn:focus {
  outline: none;
}

#second-slider .container.slider-content-container {
  max-width: 750px;
  z-index: 3;
  position: relative;
}

.container.slider-content-container .col-sm-pull-6 {
  padding-right: 0px;
}

slider-circlebg-container #second-slider .container.slider-photobg-container {
  width: 1150px;
  max-width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}

#second-slider .container.slider-pagination-container {
  width: 1150px;
  max-width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 1;
}

div#slider_pagination {
  position: absolute;
  right: 30px;
  z-index: 1;
  top: 40%;
}

div#slider_nav {
  display: none;
}

.slider-pagination-btn {
  cursor: pointer;
  clear: both;
  display: block;
}

div#slider_pagination .slider-pagination-btn a {
  background: #fff;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  text-align: center;
  display: block;
  line-height: 30px;
  margin-bottom: 10px;
  color: #73c4bc;
  opacity: 0.5;
}

.slider-pagination-btn a,
.slider-pagination-btn span {
  float: left;
  line-height: 30px;
}

.slider-pagination-btn span {
  background: #fff;
  margin-left: 10px;
  width: 10px;
  height: 1px;
  margin-top: 15px;
  opacity: 0.5;
}

div#slider_pagination .slider-pagination-btn.active a {
  opacity: 1;
}

div#slider_pagination .slider-pagination-btn.active span {
  opacity: 1;
}

.slider_nav_right,
.slider_nav_left {
  display: none;
}

.container.slider-photobg-container {
  width: 1150px;
  max-width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 1;
}

img.childgroup-bg {
  position: absolute;
  left: -250px;
  bottom: 0;
  width: 700px;
}

.second-slider-text {
  color: #767676;
  font-size: 23px;
  margin-top: 10px;
}

#second-slider .second-slider-title,
#second-slider .second-slider-text {
  text-align: right;
}

#second-slider .second-slider-title {
  color: #222222;
  font-weight: 900;
  font-size: 42px;
  letter-spacing: 0px;
  max-width: 360px;
  margin: auto;
}

img.slider-bg {
  position: absolute;
  width: 80vw;
  right: -35vw;
  top: -40vw;
}

a.second-slider-btn {
  float: right;
  background: #72c9c0;
  color: #fff;
  width: 200px;
  border: 0px;
  line-height: 2.4em;
  font-size: 19px;
  margin-top: 18px;
  border-radius: 10px;
  text-align: center;
}

a.second-slider-btn:hover {
  color: #fff;
  text-decoration: none;
  background: #47aba0;
}

/********** Features *********/

div.second-feature-section,
div.second-feature-section:nth-child(2n).light-bg-section {
  background: #f4fbfb !important;
}

div.second-feature-section:nth-child(2n) {
  background: #ebf7f7 !important;
}

.container-fluid.second-feature-section {
  overflow: hidden;
}

.second-feature-title {
  color: #237b70;
  font-weight: 600;
  font-size: 28px;
  letter-spacing: 0px;
  max-width: 380px;
  margin: auto;
}

html[lang="en"] .second-feature-title,
.page-container-en .second-feature-title {
  letter-spacing: 0px;
  font-size: 26px;
  line-height: 1.3em;
  font-weight: 700;
}

html[lang="en"] .second-feature-text,
html[lang="en"] .second-download-title,
.page-container-en .second-feature-text,
.page-container-en .second-download-title {
  letter-spacing: 0px !important;
}

html[lang="en"] #second-slider .second-slider-title,
.page-container-en #second-slider .second-slider-title {
  font-size: 40px;
  letter-spacing: 0px;
  line-height: 1.2em;
  font-weight: 700;
}

html[lang="en"] .second-usage-text,
html[lang="en"] .second-usage-title,
.page-container-en .second-usage-text,
.page-container-en .second-usage-title {
  letter-spacing: 0px !important;
}

.second-feature-text {
  font-size: 20px;
  padding-top: 10px;
  letter-spacing: 0.5px;
  color: #333;
  line-height: 1.6em;
  max-width: 380px;
  margin: auto;
}

.second-video {
  background: none !important;
  margin-top: -200px;
}

.second-video.multi-video-last {
  margin-top: 20px;
}

.container-fluid.last-section {
  padding-bottom: 200px;
}

.second-video {
  background: none !important;
  z-index: 9999;
}

// .last-section .absolute-image {
//     z-index: 0 !important;
// }
.second-youtube-container {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  width: 800px;
  padding-top: 450px;
  margin: auto;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.15);
  z-index: 999;
}

.promulgation-animation-container
  .col-lg-6.col-sm-12
  .second-youtube-container {
  padding-top: 450px;
}

img.animation-episode-icon {
  width: 65px;
  float: left;
  margin-right: 10px;
  margin-top: 4px;
}

.animation-episode-info {
  padding-top: 12px;
  padding-bottom: 20px;
  display: table;
}

span.animation-episode-name {
  font-family: "RobotoB";
  font-size: 20px;
}

.single-animation-col {
  margin-bottom: 40px;
}

span.animation-episode {
  display: table;
  font-size: 15px;
  color: #666;
}

.promulgation-animation-container-1 {
  margin-top: 30px;
}

.animation-game-title {
  font-family: "RobotoB";
  text-align: center;
  font-size: 20px;
  margin-bottom: 5px;
}

.animation-game-des {
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
}

.col-sm-12.animation-game {
  margin-top: 40px;
  margin-bottom: 80px;
}

.second-responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 0px;
  background: #dedede;
}

/********** Usage section *********/
.second-usage-title.usage-mainTitle {
  text-align: center;
  margin-top: 80px;
}

.second-usage-text.usage-mainText {
  text-align: center;
}

.second-usage-title {
  color: #2c8f86;
  font-weight: 900;
  font-size: 28px;
  letter-spacing: 0px;
  margin: auto;
}

.second-usage-text {
  font-size: 22px;
  padding-top: 10px;
  letter-spacing: 0.5px;
  color: #333;
  line-height: 1.8em;
}

.usage-2nd-mainText {
  text-align: center;
  font-size: 20px;
  font-weight: 900;
  background: #fcc563;
  color: #111;
  padding: 8px 5px;
  border-radius: 100px;
  width: 550px;
  max-width: 100%;
  margin: auto;
  margin-top: 3px;
}

.child_tips_HW img.desktop-show {
  width: 700px;
  margin: auto;
  max-width: 100%;
}

/********** Usage section child_Height & Weight *********/
.container-fluid.child_input_HW {
  background: #fff4e4;
  padding-top: 70px;
  padding-bottom: 50px;
}

.container-fluid.child_input_HW .col-sm-3 {
  margin-bottom: -60px;
  margin-top: -100px;
}

.child_input_HW .col-sm-9 {
  padding-left: 50px;
}

.child_input_HW .col-sm-3 {
  padding-right: 4px;
  padding-left: 4px;
}

.container-fluid.child_input_HW .second-usage-title {
  color: #222222;
}

/********** Unicorn section *********/
.child_unicorn .row.vertical-align {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.container-fluid.child_unicorn .col-md-4 {
  padding: 0px;
}

.child_unicorn .row.vertical-align .col-md-8:before {
  content: "";
  background: url(../images/child_usage3_bg.png);
  position: absolute;
  width: 250px;
  height: 250px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  z-index: -1;
  top: -50%;
  left: -20%;
}

.child_unicorn .second-usage-text {
  font-size: 16px;
}

.child_unicorn .second-usage-title {
  color: #333;
}

/********** Usage section download *********/
#second-download-section {
  background: #d7eaeb;
  margin-top: 30px;
}

.second-download-title {
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #0b5d5c;
  font-family: "Roboto", "RobotoR";
  line-height: 1.2em;
}

.second-download-title:after {
  content: "";
  background: #b1d4d4;
  width: 70px;
  height: 6px;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}

.second-download-subtitle {
  font-size: 22px;
  font-weight: 700;
}

.second-download-text {
  max-width: 400px;
  color: #333;
  line-height: 1.8em;
}

.second-download-now img {
  width: 160px;
}

a.second-download-now-href {
  float: left;
  padding: 5px;
}

.second-download-now h4 {
  margin-bottom: 7px;
  font-size: 20px;
  font-weight: 400;
  margin-top: 20px;
}

.footer-download-btn {
  margin-left: -5px;
}

.absolute-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.empty-space-40 {
  height: 40px;
}

.empty-space-60 {
  height: 60px;
}

.empty-space-100 {
  height: 100px;
}

.empty-space-120 {
  height: 120px;
}

a.home-yt-btn-a {
  display: block;
  text-align: center;
  background: #e29349;
  position: absolute;
  z-index: 9;
  padding: 15px 32px;
  box-sizing: content-box;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 6px;
  color: #fff;
  font-size: 26px;
  margin-top: -40px;
  letter-spacing: 0.5px;
  font-weight: 600;
  transition: 0.5s all;
}

.home-yt-btn-shadow {
  display: block;
  text-align: center;
  background: #c07432;
  position: absolute;
  z-index: 5;
  padding: 15px 32px;
  box-sizing: content-box;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 6px;
  color: #fff;
  font-size: 26px;
  margin-top: -32px;
  letter-spacing: 0.5px;
  font-weight: 600;
  transition: 0.5s all;
}

a.home-yt-btn-a:hover {
  text-decoration: none;
  background: #ffac25;
  margin-top: -35px;
}

img.home-ytplay-icon {
  margin-top: -6px;
  margin-left: 2px;
}

a.home-yt-btn-a:focus {
  text-decoration: none;
  outline: 2px dotted #1a7978;
  outline-offset: 10px;
}

.covid-main-title-long img {
  width: 50px;
  height: 50px;
  margin: -6px 4px 0 10px;
}

.footer {
  background-color: #1a7978;
  color: #fff;
  padding-bottom: 20px;

  table {
    width: 100%;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  a:focus {
    outline: white dotted 2px;
  }

  .display-desktop {
    width: 330px;
  }

  .display-desktop-en {
    max-width: 350px;
  }

  .display-mobile {
    display: none;
    text-align: center;
  }

  .align-right {
    text-align: right;
  }

  .copyright-warp {
    display: none;
  }

  .footerLink {
    border-bottom: solid 1px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-left: 40px;
    margin-right: 40px;
    text-align: center;
  }

  .footerLinkLogo {
    cursor: pointer;
    margin-left: 24px;
    margin-right: 24px;

    img {
      position: relative;
      width: 48px;
    }
  }

  .footerLinkLogo:focus {
    outline: #ffffff dotted 2px;
  }
}

.copyright {
  background-color: #1a7978;
  color: #fff;
  font-size: 14px !important;
  line-height: 22px;
  height: 60px;
  display: block;
  text-align: center;
  align-items: center;
  justify-content: left;
  word-break: break-word;
  margin-top: 40px;
}

.footer .badge {
  font-size: 12px;
  color: #fff;
  display: block;
  background: none;
  text-align: left;
  font-weight: 500;
  margin-left: 40px;
  padding: 0;
  padding-bottom: 10px;
}

.footer .margin-t-20 {
  margin-top: 24px;
}

#home_slider_nav_left,
#home_slider_nav_right {
  position: absolute;
  top: 50%;
  cursor: pointer;
  width: 47px;
  margin: 0px 30px;
  background: rgba(255, 255, 255, 0.92);
  border-radius: 25px;
  padding: 8px;
}

#home_slider_nav_left {
  left: 20px;
}

#home_slider_nav_left:focus {
  img {
    outline: white dotted 2px;
  }
}

#home_slider_nav_right {
  right: 20px;
}

#home_slider_nav_right:focus {
  img {
    outline: white dotted 2px;
  }
}

/******Covid Page for QRcode + Adolescent*****/

.static_page_header {
  //padding: 30px 0px;
  margin-top: 100px;
}

.static_page_header_adolescent {
  background: #fff9f3;
}

.static_page_header_qr {
  background: #e7fafa;
}

.static_page_header .col-sm-6 {
  padding: 40px 0px;
}

p.static_page_top_subtitle {
  font-size: 30px;
  text-align: right;
  max-width: 380px;
  float: right;
}

p.static_page_top_title {
  font-size: 53px;
  text-align: right;
  font-weight: 900;
  color: #222;
  margin-bottom: 3px;
}

html[lang="en"] p.static_page_top_title {
  font-size: 45px;
  line-height: 1.35em;
}

.static_page_section_title {
  text-align: center;
  font-size: 30px;
  font-weight: 900;
  padding: 40px 0px;
  color: #007676;
}

span.short_line {
  height: 6px;
  width: 100%;
  background: #007676;
  display: block;
  max-width: 460px;
  margin: auto;
  margin-top: 10px;
}

.covid_feature_btn {
  margin-top: 10px;
}

.covid_feature_btn a {
  background: #007676;
  color: #fff;
  padding: 12px 40px;
  font-size: 21px;
  border-radius: 10px;
}

.covid_feature_btn a:hover {
  text-decoration: none;
  background: #006565;
}

.covid-main-title {
  width: 500px;
  max-width: 100%;
  margin: auto;
}

.covid-main-title.covid-main-title-long {
  width: 700px;
  max-width: 100%;
}

.covid-center-section-img {
  width: 600px !important;
  max-width: 100%;
  margin: auto !important;
  display: block;
}

.reminder-section .container {
  background: #ffe1b2;
  padding: 35px 30px;
  max-width: 700px;
  border-radius: 10px;
  position: relative;
  text-align: center;
}

.reminder-section {
  margin-top: -200px;
}

.reminder-sticker {
  height: 100px;
  width: 100px;
  display: block;
  background: #fff;
  font-size: 34px;
  text-align: center;
  border-radius: 10px;
  font-weight: 800;
  line-height: 1.1em;
  color: #fc8110;
  padding: 13px;
  border: 2px solid #ffa552;
  position: absolute;
  top: 40px;
  left: -40px;
  float: left;
}

p.reminder-title {
  font-size: 23px;
  font-weight: 800;
  margin-bottom: 13px;
}

img.reminder-tick {
  max-width: 35px;
}

.reminder-tick-div {
  text-align: left;
  max-width: 300px;
  margin: auto;
  font-size: 17px;
  margin-bottom: 7px;
}

.reminder-tick-div img {
  margin-right: 5px;
  margin-top: -1px;
}

html[lang="en"] .reminder-tick-div {
  max-width: 500px;
}

html[lang="en"] .reminder-tick-btn p {
  max-width: 500px;
}

html[lang="en"] .reminder-sticker {
  width: auto;
  height: auto;
  left: -80px;
  padding-bottom: 0;
  top: 80px;
  font-size: 30px;
}

html[lang="en"] .covid-main-title {
  width: 700px;
  font-size: 26px;
}

.reminder-tick-btn p {
  font-size: 20px;
  margin: auto;
  margin-top: 16px;
  display: block;
  font-weight: 900;
  color: #222;
  max-width: 330px;
  text-align: left;
}

.reminder-tick-btn a {
  text-decoration: underline;
  color: #2a5e94;
}

.static_page_header_bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-size: contain !important;
  background-position: 100% 0% !important;
}

.static_page_header {
  position: relative;
}

.covid-adolescent-bottom-phone {
  width: 400px !important;
  display: block;
  margin: auto;
  max-width: 100% !important;
}

.covid_center_section {
  background: #f4fbfb;
}

// .covid-qr-p2-row {
//     background: #e4f5f5;
// }
.covid-qr-p4-row {
  padding-top: 50px;
  padding-bottom: 50px;
}

div.second-feature-section.reminder-full-row:nth-child(2n),
.div.second-feature-section.reminder-full-row {
  background: #ffe1b2 !important;
}

.second-feature-section.reminder-full-row {
  padding-top: 10px;
}

.reminder-full-row .covid-feature-title {
  color: #222;
  margin-bottom: -7px;
  font-size: 30px;
}

.reminder-full-row .second-feature-text {
  font-size: 22px;
}

.covid19-bottom-phone-container img {
  height: auto !important;
}

.covid19-bottom-phone {
  width: 750px;
  max-width: 100%;
  position: relative;
  margin: auto;
  margin-top: 30px;
}

.covid-qr-indicate-row {
  padding-top: 0px !important;
}

.covid-qr-indicate-row .covid-main-title.covid-main-title-long {
  margin-top: 0px;
  font-size: 23px;
  text-align: left;
}

.redcolor {
  color: red;
}

.greencolor {
  color: #00bc12;
}

html[lang="en"] .cn_content {
  display: none;
}

html[lang="en"] .en_content {
  display: block;
}

html[lang="zh-CN"] .en_content,
html[lang="zh-HK"] .en_content {
  display: none;
}

html[lang="zh-CN"] .cn_content,
html[lang="zh-HK"] .cn_content {
  display: block;
}

.container-fluid.second-feature-section.reminder-full-row {
  background: #ffe1b2 !important;
}

.static_page_section_title p.subtitle {
  font-size: 28px;
  margin-bottom: 0;
}

.static_page_section_title p.maintitle {
  font-size: 28px;
  color: #e96100;
  margin-bottom: 0px;
}

.static_page_section_title p.thirdtitle {
  font-size: 28px;
  color: #e96100;
  margin-bottom: 5px;
}

.static_page_section_title p.maintitle span {
  color: #007676;
}

html[lang="en"] .static_page_section_title p.thirdtitle {
  color: #007676;
}

html[lang="en"] .static_page_section_title p.subtitle span {
  color: #e96100;
}

.ehealthpro-color-sliderbg {
  display: none;
}

.ehealthpro-color .ehealthpro-color-sliderbg {
  display: block;
}

.ehealthpro-color .second-slider-title {
  font-family: "RobotoB";
}

.ehealthpro-color #second-slider .second-slider-title {
  color: #1f436d;
}

.ehealthpro-color #second-slider .second-slider-text {
  color: #1e4d76;
  opacity: 0.8;
}

.ehealthpro-color .container.slider-content-container .col-sm-pull-6 {
  padding-right: 0px;
  right: 45%;
}

.ehealthpro-color .second-feature-text {
  color: #1e4d76 !important;
  opacity: 0.8 !important;
  font-size: 22px !important;
  line-height: 1.5em !important;
}

html[lang="en"] .ehealthpro-color .second-feature-title {
  font-family: "RobotoB";
  font-size: 30px;
}

.row.ehealthpro-step b,
.row.ehealth-tip-step b {
  font-family: "RobotoB";
  font-weight: 900;
}

img.pro-feature-img {
  max-width: 200px;
  display: block;
  margin: auto;
  margin-bottom: 20px;
}

.ehealthpro-feature {
  text-align: center;
  color: #0a4669;
  font-size: 16px;
}

img.ehealth-tip-img {
  max-width: 100%;
  display: block;
  margin: auto;
  margin-bottom: 20px;
}

div.ehealthpro-section {
  background: #eaf2fa;
  padding-top: 70px;
  padding-bottom: 70px;
}

.ehealthpro-color .second-feature-title {
  color: #133759 !important;
}

div.ehealthpro-bg {
  background: #eaf2fa !important;
}

div.ehealthpro-light-bg {
  background: #f8fbff !important;
}

div.ehealthpro-white-bg {
  background: #ffffff !important;
}

div.ehealthpro-2nd-section {
  background: #fff;
  padding-top: 70px;
  padding-bottom: 70px;
}

.download-btn-row {
  float: left;
  width: 100%;
  margin-top: 4px;
}

.ehealthpro-title {
  text-align: center;
  font-size: 25px;
  color: #133759 !important;
  font-weight: 900;
}

.ehealthpro-feature-row {
  margin-top: 50px;
}

.ehealthpro-color.single-phone div#slider_pagination {
  display: none;
}

span.pro-step-no,
span.ehealth-tip-no {
  display: block;
  font-size: 20px;
  margin-bottom: 5px;
}

.row.ehealthpro-step,
.row.ehealth-tip-step {
  margin: auto;
  margin-top: 40px;
  font-size: 16px;
  color: #1e4d76;
  width: 700px;
  max-width: 100%;
}

.ehealthpro-step.done-step {
  background: #fff6e0;
  border-radius: 15px;
}

.done-step img.pro-feature-img {
  margin-bottom: 0px;
}

.done-step span.pro-step-no {
  font-family: RobotoB;
  font-size: 28px;
  margin-bottom: 0px;
}

.done-step .col-md-8 span.pro-donestep-des {
  font-size: 16px;
}

img.pro-faq-img {
  display: table;
  float: left;
  vertical-align: middle;
  width: 130px;
  margin-right: 10px;
}

.ehealthpro-faq-entry div {
  display: flex;
  align-items: center;
}

.ehealthpro-faq-entry {
  width: auto;
  display: table;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.page404-content img {
  width: 600px;
  max-width: 90%;
  display: block;
  margin: auto;
}

.page404-content h1 {
  text-align: center;
  font-family: "RobotoB";
  color: #222;
}

.page404-content {
  text-align: center;
  font-size: 17px;
  margin-top: 150px;
}

a.not-found-btn {
  display: table;
  background: #0079c1;
  color: #fff;
  padding: 15px 30px;
  border-radius: 5px;
  margin: auto;
  margin-top: 30px;
}

a.not-found-btn:hover {
  text-decoration: none;
  background: #0071b4;
}

.life-overview-button-container .container {
  width: 100%;
  padding: 0;
}
.only-tc-school-notice {
  text-align: center;
}
html[lang="en"] .only-sc,
html[lang="zh-HK"] .only-sc {
  display: none;
}
a.scroll-anchor {
  display: block;
  position: relative;
  top: -50px;
  visibility: hidden;
}
.news-item-container {
  display: flex;
  flex-wrap: wrap;
}

.news-item {
  flex: 0 0 33.33%; /* Ensure each item occupies one-third of the row */
  max-width: 33.33%; /* Ensure each item occupies one-third of the row */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  padding: 10px;
}

.whats-new-thumbnail,
.whats-new-title,
.whats-new-data {
  flex-grow: 1;
}
.whats-new-container .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.whats-new-container .pagination span {
  margin: 0 5px;
  min-width: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
.pagination img {
  width: 30px;
}
.whats-new-container .pagination .disabled {
  pointer-events: none;
  opacity: 0.3;
}
.whats-new-container .pagination .active {
  font-weight: bold;
  background: #007272;
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-has-dropdown span.arrow-down {
    margin-top: 5px;
  }

  .elife-box-content .second-youtube-container {
    padding-top: 352px;
  }

  .container-fluid.child_input_HW {
    padding-bottom: 0px;
  }

  .container-fluid.child_input_HW .col-sm-3 {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .container-fluid.child_input_HW .col-sm-9 {
    margin-top: -40px;
  }

  .child_unicorn .second-usage-text br {
    display: none;
  }

  .child_unicorn .row.vertical-align {
    padding: 0;
  }

  .container-fluid.child_unicorn .col-md-4 img {
    max-width: 250px;
    margin: auto;
    display: block;
  }

  .child_unicorn .row.vertical-align {
    padding: 0;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 5%;
  }

  #second-download-section {
    margin-top: 0px;
  }
}

span.pro-faq-entry-text {
  font-size: 20px;
}

.single-phone .slider_nav_left,
.single-phone .slider_nav_right {
  display: none !important;
}

@media (max-width: 767px) {
  .under16-earn-coins-col {
    padding: 0;
  }

  .birth-kit-gift-redemption-intro {
    font-size: 16px;
  }
  .life-overview-button-container .container {
    padding: 0px 15px;
  }

  .single-phone img.pro-faq-img {
    width: 80px;
  }

  span.pro-faq-entry-text {
    font-size: 17px;
  }

  .container.slider-content-container .col-sm-pull-6 {
    padding-right: 15px;
  }

  .done-step img.pro-feature-img {
    margin-bottom: 20px;
  }

  .ehealthpro-step.done-step {
    padding-bottom: 30px;
  }

  .ehealthpro-title {
    font-size: 20px;
  }

  .ehealthpro-feature {
    width: 100%;
    margin-bottom: 40px;
  }

  div.ehealthpro-section {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .row.ehealthpro-step {
    text-align: center;
  }

  .container-fluid.ehealthpro-bg,
  .container-fluid.ehealthpro-light-bg,
  .container-fluid.ehealthpro-white-bg {
    padding-top: 50px;
    text-align: center;
  }

  div.faq-section,
  .faq-expand-all {
    padding-left: 20px;
    padding-right: 20px;
  }

  div.faq-question {
    font-size: 16px;
  }

  div.faq-question img {
    width: 18px;
    height: 18px;
  }

  div.faq-title h3 {
    font-size: 18px;
  }

  span.faq-expand {
    font-size: 14px;
  }

  .covid19-bottom-phone {
    margin-top: -30px;
  }

  .covid-qr-p4-row .covid19-bottom-phone {
    margin-top: 30px;
  }

  .covid-qr-p3-row img {
    width: 100% !important;
    height: auto !important;
  }

  .covid-qr-video-row {
    margin-bottom: 0px;
  }

  .reminder-tick-div img {
    display: block;
    float: left;
  }

  .reminder-tick-div span {
    vertical-align: middle;
    padding-top: 7px;
    display: table;
  }

  .reminder-tick-div {
    width: 100%;
    display: table;
    margin-bottom: 15px;
  }

  .reminder-full-row .second-feature-text br {
    display: none;
  }

  html[lang="en"] p.static_page_top_title {
    font-size: 26px;
    line-height: 1.3em;
  }

  .second-feature-text.covid_feature_btn {
    margin-top: 20px !important;
  }

  html[lang="en"] .reminder-full-row .second-feature-text {
    font-size: 18px;
    line-height: 1.4em;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  html[lang="en"] .second-feature-title,
  .page-container-en .second-feature-title,
  html[lang="en"] .ehealthpro-color .second-feature-title {
    font-size: 20px;
  }

  .reminder-doctor-col img {
    max-width: 60vw !important;
  }

  .static_page_header {
    margin-top: 65px;
    padding: 0px;
    padding-top: 40px;
    margin-bottom: 40px;
  }

  .static-page-header-left {
    background: #9de6e8;
    padding: 13px 8px !important;
    border-radius: 100px;
    width: 500px;
    max-width: 90vw;
    margin: auto;
    font-size: 26px;
    margin-top: 14px;
    margin-bottom: -44px;
  }

  .static_page_header img {
    max-width: 70vw;
    display: block;
    margin: auto;
    margin-bottom: 30px;
  }

  .static_page_header .banner-center-container img {
    max-width: 100vw;
  }

  .static_page_header_adolescent .static-page-header-left {
    background: #ffc269;
  }

  .static_page_header .col-sm-6 {
    padding: 0;
  }

  p.static_page_top_title {
    font-size: 29px;
    text-align: center;
  }

  p.static_page_top_subtitle {
    text-align: center;
    font-size: 19px;
    margin: auto;
    float: none;
  }

  .static_page_header_qr .static_page_header_bg,
  .static_page_header_adolescent .static_page_header_bg,
  .static_page_header_privacy .static_page_header_bg {
    display: none;
  }

  .static_page_section_title {
    font-size: 23px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .static_page_section_title br {
    display: none;
  }

  span.short_line {
    max-width: 70vw;
  }

  .second-feature-title.covid-feature-title {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .covid-main-title-long {
    font-size: 20px !important;
  }

  .covid_feature_btn {
    margin-top: 12px;
  }

  .covid_feature_btn a {
    font-size: 17px;
  }

  .reminder-sticker,
  html[lang="en"] .reminder-sticker {
    position: unset;
    width: 100%;
    background: none;
    border: none;
    color: #222;
    font-size: 25px;
    padding: 0;
    height: auto;
  }

  .reminder-sticker br {
    display: none;
  }

  .reminder-tick-div {
    font-size: 17px;
    margin-bottom: 5px;
  }

  .reminder-tick-btn p {
    font-size: 17px;
  }

  p.reminder-title {
    font-size: 19px;
    color: #333;
  }

  div.covid-main-title {
    font-size: 20px !important;
    margin-top: 30px !important;
    margin-bottom: 10px;
    font-weight: 700;
  }

  #home_slider_nav_left,
  #home_slider_nav_right {
    margin: 0px 0px;
    width: 40px;
    padding: 8px;
    top: auto;
    background: none;
  }

  #home_slider_nav_left {
    left: auto;
    right: 50px;
  }
  .second-download-now h4 {
    text-align: center;
    margin-top: 20px;
  }

  div#second-slider {
    margin-top: 55px;
    padding-top: 50px;
  }

  .empty-space-40 {
    height: 20px;
  }

  .empty-space-100 {
    display: none;
  }

  .empty-space-120 {
    height: 60px;
  }

  #second-download-section img.mobile-show {
    width: 500px;
    margin: auto;
    max-width: 100%;
  }

  .child_tips_HW img {
    width: 450px;
    margin: auto;
    max-width: 100%;
  }

  .child_tips_HW .second-usage-text {
    margin: auto;
  }

  .container-fluid.child_input_HW .col-sm-9 {
    margin-top: 0px;
  }

  .container-fluid.second-feature-section {
    padding-top: 40px;
    text-align: center;
  }

  .vertical-align {
    display: block;
  }

  .mobile-show {
    display: block;
  }

  .desktop-show {
    display: none;
  }

  #second-slider .container.slider-photobg-container {
    display: none;
  }

  .second-feature-section img {
    width: 350px;
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%) !important;
  }
  div.scanner-features-container img {
    float: none;
    padding-right: 0px;
    transform: none !important;
  }
  .services-detail-row .col-sm-6 {
    margin-bottom: 30px;
  }
  span.qr-service-note {
    margin: auto;
    margin-top: 15px;
    width: 100%;
    text-align: center;
  }
  .appt-service .container img {
    width: 250px;
    margin: auto;
    max-width: 100%;
    margin-bottom: 15px;
  }
  img.slider-bg {
    top: -150px;
    width: 500px;
    right: -30vw;
  }

  .silder-phone {
    width: 80vw;
    max-width: 400px;
    margin: auto;
    display: block;
    height: auto;
  }

  div#slider_pagination {
    display: none;
  }

  div#slider_nav {
    display: block;
    width: 100%;
    left: 0;
  }

  .slider_nav_left img,
  .slider_nav_right img {
    width: 30px;
  }

  .slider_nav_right {
    display: block;
    position: absolute;
    right: 10px;
    top: 50%;
    z-index: 3;
  }

  .slider_nav_left {
    display: block;
    position: absolute;
    left: 10px;
    top: 50%;
    z-index: 3;
  }

  #second-slider .second-slider-title {
    font-size: 24px;
    text-align: center;
  }

  .ehealthpro-color #second-slider .second-slider-title {
    font-size: 24px !important;
    text-align: center;
  }

  #second-slider .second-slider-text {
    text-align: center;
    font-size: 18px;
  }

  a.second-slider-btn {
    float: none;
    display: block;
    margin: 10px auto;
  }

  #second-slider {
    margin-bottom: 0px;
  }

  .second-feature-title {
    font-size: 28px;
    text-align: center;
  }

  html[lang="en"] .second-feature-title {
    letter-spacing: 0px;
  }

  .reminder-full-row .second-feature-text {
    font-size: 18px;
  }

  .second-feature-text {
    font-size: 18px;
    padding-top: 8px;
  }

  .second-usage-section.title-section {
    padding-bottom: 0px;
  }

  .second-usage-section.child_input_HW {
    padding-top: 50px;
  }

  .second-usage-section.child_input_HW img {
    margin-top: 0px;
    margin: auto;
    display: block;
    width: 150px;
  }

  .second-usage-section.child_input_HW .second-usage-left {
    width: 100%;
  }

  .second-usage-title {
    font-size: 26px;
  }

  .second-usage-text br {
    display: none;
  }

  .second-youtube-container {
    padding-top: 56%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  }

  .container-fluid.child_input_HW .col-sm-3 {
    margin: 0;
  }

  .container-fluid.child_input_HW .col-sm-3,
  .container-fluid.child_input_HW .col-sm-9 {
    margin-top: 0px;
  }

  .container-fluid.child_input_HW .col-sm-3 img {
    width: 150px;
    margin: auto;
    display: block;
    margin-top: 35px;
  }

  .container-fluid.child_input_HW {
    padding-top: 30px;
    padding-bottom: 0px;
  }

  .child_unicorn {
    text-align: center;
  }

  .child_unicorn .row.vertical-align {
    padding-left: 0;
  }

  .child_unicorn img {
    max-width: 150px;
    margin: -30px auto;
    display: block;
  }

  .container-fluid.child_unicorn {
    padding-top: 70px;
  }

  .second-usage-text {
    font-size: 16px;
    width: 330px;
    margin: auto;
    max-width: 100%;
  }

  .second-usage-text.usage-2nd-mainText {
    width: 100%;
  }

  .caregiver-tips .second-usage-title {
    width: 330px;
    margin: auto;
  }

  .child_input_HW .second-usage-text,
  .child_input_HW .second-usage-title {
    margin: auto;
    width: 330px;
    max-width: 100%;
  }

  .child_unicorn .row.vertical-align .col-md-8:before {
    width: 300px;
    height: 300px;
    left: -50vw;
  }

  div#second-download-content {
    flex-direction: column;
    padding: 0px 30px;
  }

  .second-download-now {
    margin-bottom: 50px;
  }

  .second-download-title {
    font-size: 32px;
    margin-top: 50px;
  }

  .second-download-text {
    max-width: 100%;
  }

  .copyright {
    font-size: 12px;
    padding: 0px;
  }

  div.copyright .margin-l-40 {
    margin-left: 20px;
  }

  div.copyright .w3clogo {
    margin-right: 20px;
  }

  .covid-qr-before-video {
    padding-top: 0px;
  }

  .container-fluid.second-feature-section.covid-qr-export-row.covid-qr-export-row {
    padding-top: 0px;
  }

  .covid-qr-export-row .container .covid-main-title.covid-main-title-long,
  .covid-qr-before-video .container .covid-main-title.covid-main-title-long,
  .covid-qr-indicate-row .container .covid-main-title.covid-main-title-long {
    width: auto;
    max-width: 500px !important;
  }

  .covid-qr-export-row .container .covid-main-title.covid-main-title-long {
    margin-bottom: 20px;
  }

  .covid-qr-export-row .container img,
  .covid-qr-before-video .container img,
  .covid-qr-indicate-row .container img {
    width: 100%;
    max-width: 100%;
  }

  .static_page_section_title p.subtitle {
    font-size: 24px;
  }

  .static_page_section_title p.maintitle,
  .static_page_section_title p.thirdtitle {
    font-size: 24px;
    line-height: 1.35em;
  }
}

@media (max-width: 1300px) and (min-width: 768px) {
  .second-usage-title.usage-mainTitle.covid-main-title.covid-main-title-long {
    font-size: 23px;
    padding-left: 13% !important;
    padding-right: 13% !important;
  }
}

/***********ADDED 29/1/2020*********/
.caregiver-tips {
  background: #fff9f0;
}

.container-fluid.caregiver-tips {
  background: #fff4e4;
  padding-top: 70px;
  padding-bottom: 50px;
}

.container-fluid.caregiver-tips .col-md-3 {
  margin-bottom: -50px;
  margin-top: -100px;
}

.container-fluid.caregiver-tips .second-usage-title {
  color: #222222;
}

.second-usage-title .small {
  font-size: 65%;
  font-weight: 400;
}

.two-video-bottom {
  margin-top: 40px;
}
.overview-item-text {
  height: 50px;
}
html[lang="en"] .overview-item-text {
  height: 80px;
}
.cover-white-container {
  margin-top: -200px;
  margin-bottom: -100px;
  z-index: 2;
  position: relative;
}
.cover-white-container .container {
  background: #fff;
  padding: 30px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  box-shadow: 0px 2px 5px #00000015;
}
img.addon-image {
  width: 160px;
  max-width: 100%;
}
.cover-white-container .container h3 {
  color: #007272;
  font-size: 22px;
  line-height: 1.5em;
  padding: 0px 24px;
  width: 730px;
  max-width: 100%;
}

.container-fluid.second-feature-section.bgf4fbfb {
  background: #f4fbfb !important;
}
.container-fluid.second-feature-section.bgebf7f7 {
  background: #ebf7f7 !important;
  padding-top: 120px;
}
.disclaimer-container ol li {
  list-style: auto;
}
.disclaimer-container ol {
  padding-left: 15px;
  padding-top: 15px;
}
.disclaimer-container {
  color: #666;
  padding: 60px 0px;
}
.birth-kit-container h3 {
  background: #2783b0;
  color: #fff;
  text-align: center;
  padding: 20px;
  font-size: 20px;
  width: 750px;
  max-width: 100%;
  margin: auto;
  border-radius: 8px;
  line-height: 1.3em;
  position: relative;
}
.about-redemption-btn-row {
  text-align: center;
}

.about-redemption-btn-row img {
  width: 80px;
  margin-right: 15px;
}
.about-redemption-btn-row a.mission-btn {
  display: inline-block;
  margin-top: 35px;
}
.under16-earn-coins-title {
  position: relative;
  margin-top: 80px;
}
.under16-earn-coins-container .container {
  background: #fff6e5;
  padding: 0px;
  border: 1px solid #f4c57c;
  border-radius: 8px;
  margin-top: 40px;
  padding-top: 40px;
  position: relative;
}
.redemption-info-pack-btn-row {
  background: #ffefd0;
  padding-bottom: 20px;
  margin-top: 25px;
  border-top: 1px solid #f4c57c;
}
.under16-earn-coins-title h3 {
  background: #e98300;
  color: #fff;
  font-size: 20px;
  padding: 20px 40px 20px 80px;
  text-align: center;
  border-radius: 4px;
  position: relative;
  display: table;
  z-index: 2;
  margin: auto;
}
.under16-earn-coins-col-content {
  padding: 35px;
}
div.under16-earn-coins-col:first-child {
  border-right: 1px solid #f4c57c;
}
.under16-earn-coins-col-content img.mission-graphic {
  display: table;
  width: 380px;
  max-width: 100%;
  margin: auto;
  margin-bottom: 20px;
}
img.under16-icon {
  position: absolute;
  width: 110px;
  left: -60px;
  top: -45%;
}
span.mission-detail {
  display: table;
  font-size: 18px;
  text-align: center;
  margin: 15px auto;
  margin-bottom: 8px;
}
.mission-date {
  color: #555;
  display: table;
  margin: auto;
  font-size: 15px;
  text-align: center;
}

.birth-kit-gift-redemption-intro {
  font-size: 18px;
  color: #333;
  text-align: center;
  width: 800px;
  margin: auto;
  max-width: 100%;
  margin-bottom: 8px;
}
.under16-earn-coins-container a.mission-btn img {
  width: 18px;
  height: 18px;
  margin-top: -4px;
  margin-left: 7px;
}
.under16-earn-coins-container a.mission-btn.download-btn img {
  width: 22px;
  height: 22px;
  margin-top: -4px;
  margin-left: 10px;
}

a.mission-btn {
  background: #e98302;
  box-shadow: 0px 4px 0px #c56947;
  color: #fff;
  padding: 11px 22px;
  font-size: 17px;
  border-radius: 5px;
  display: table;
  margin: auto;
  margin-top: 15px;
}
.mission-tag {
  display: table;
  margin: auto;
  background: #fff;
  padding: 5px 15px;
  color: #444;
}
img.birth-kit-icon {
  width: 100px;
  margin: auto;
  display: table;
  margin-top: -140px;
}
.birth-kit-container {
  margin-top: 250px;
}
.birth-kit-container .main-graphic {
  display: table;
  width: 700px;
  max-width: 100%;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 20px;
}
.birth-kit-container .container span.mission-date {
  font-size: 19px;
  color: #074c70;
  font-family: "RobotoB";
  font-weight: bold;
}
.mission-remarks {
  display: block;
  font-size: 14px;
  color: #666;
  margin-top: 14px;
  text-align: center;
}
.birth-kit-content {
  padding: 0px 40px 20px 40px;
}
.birth-kit-container .redemption-info-pack-btn-row {
  background: #e8f8fc;
  border-color: #9cc8dd;
}
.birth-kit-container .redemption-info-pack-btn-row a {
  background-color: #0f5b82;
  box-shadow: 0px 4px 0px #074c70;
}
.birth-kit-container .container {
  background: #f2fbfb;
  padding: 0px;
  border: 1px solid #9cc8dd;
  border-radius: 8px;
}
.birth-cert-video-container {
  margin-top: 30px;
}
@media (min-width: 1201px) {
  .promulgation-animation-container
    .col-lg-6.col-sm-12
    .second-youtube-container {
    padding-top: 235px !important;
  }
}

@media (min-width: 1800px) {
  .workplace-enrol-box,
  .individual-enrol-box {
    height: 400px;
  }
  html[lang="en"] .workplace-enrol-box,
  html[lang="en"] .individual-enrol-box {
    height: 450px;
  }

  html[lang="en"] .overview-item-text {
    height: 70px;
  }
  .container {
    max-width: 1100px;
  }

  .second-feature-section {
    padding-top: 50px;
  }

  .second-feature-title {
    font-size: 32px;
  }

  .second-feature-title,
  .second-feature-text {
    max-width: 440px;
  }

  .second-feature-text {
    font-size: 24px;
  }

  .child_unicorn .second-usage-title {
    font-size: 34px;
  }

  .child_unicorn .second-usage-text {
    font-size: 18px;
  }

  .second-youtube-container {
    width: 1050px;
    padding-top: 590px;
  }

  .elife-box-content .second-youtube-container {
    padding-top: 550px;
  }

  .promulgation-animation-container
    .col-lg-6.col-sm-12
    .second-youtube-container {
    padding-top: 295px !important;
  }

  .second-feature-title.covid-feature-title,
  .second-feature-text.covid-feature-text,
  .second-feature-text.covid_feature_btn {
    max-width: 500px;
  }
}

.promotion-nav .main-view {
  //display: table;
  position: relative;
  width: 100%;
  max-width: 100%;
  padding: 0px 25px;
}

.promotion-logo {
  width: 140px;
  float: left;
}

.disabledCursor {
  cursor: default;
}

.change-language {
  float: right;
  padding-top: 10px;
}

.dropdown a:hover,
.header-menu-container .header-menu-item:hover {
  text-decoration: none !important;
}

.header-menu-container .header-menu-item:hover .arrow-down {
  border-top: 10px solid #23527c;
}

.header-menu-container .disabledMobileItem a:hover {
  border-top: 10px solid #337ab7 !important;
}

.dropdown {
  display: inline;
}

.header-menu-container {
  float: left;
  padding-top: 5px;
}

.header-menu-container .header-menu-item {
  line-height: 2.5em;
  display: inline-block;
  margin-right: 30px;
}

.header-menu-container .disabled-header-menu-item {
  line-height: 2.5em;
  display: inline-block;
  margin-right: 30px;
  cursor: default;
  text-decoration: none;
  color: #337ab7;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 9px solid #107cc1;
  display: inline-block;
  padding-top: 2px;
  margin-left: 4px;
}

.dropdown-menu > li > a {
  line-height: 2em;
  font-size: 16px;
}

.dropdown ul.dropdown-menu {
  margin-top: 12px;
  border: none;
  min-width: 180px;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  background-color: #f1f9ff;
}

.dropdown-menu > li > a.active {
  background: #ecfaff !important;
  color: #0b80a6;
  font-weight: 700;
}

.copyright .margin-l-40 {
  margin-left: 40px;
}

.copyright .margin-l-10 {
  margin-left: 10px;
}

.copyright .hyperlink {
  cursor: pointer;
}

.copyright div {
  position: relative;
}

.copyright img {
  width: 16px;
  height: 16px;
  padding-right: 3px;
}

.w3clogo {
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 40px;
  cursor: pointer;
}

//height not align after apply the style
.promotion-nav.scroll-down .header-menu-container {
  //padding-top: 0px;
}

.promotion-nav.scroll-down .change-language {
  //padding-top: 10px;
}

.mobile-menu-icon {
  display: inline-block;
  cursor: pointer;
}

.mobile-menu-icon .bar1,
.mobile-menu-icon .bar2,
.mobile-menu-icon .bar3 {
  width: 30px;
  height: 3px;
  background-color: #0b80a6;
  margin: 6px 0;
  transition: 0.4s;
  border-radius: 100px;
}

.arrow-right-1st {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 20px solid #206ca2;
  display: inline-block;
  margin-left: 20px;
}

.part-title b:hover a,
.part-title b:hover span {
  color: #04375a !important;
  border-left-color: #04375a !important;
  text-decoration: none;
  transition: 0.3s all;
}

.part-title b a br {
  display: none;
}

div.second-feature-section.last-section-novideo {
  background: #d1ecec !important;
}

.covid-qr-export-row
  .container
  .col-md-5.col-sm-12.col-sm-push-7
  .covid-main-title.covid-main-title-long {
  margin-top: 30px;
  padding-left: 5%;
  padding-right: 5%;
}

.covid-qr-export-row .container {
  max-width: 1200px;
}

.covid-qr-export-row .container img {
  height: auto;
}

.covid-qr-export-row .container .covid-main-title.covid-main-title-long,
.covid-qr-before-video .container .covid-main-title.covid-main-title-long,
.covid-qr-indicate-row .container .covid-main-title.covid-main-title-long {
  width: auto;
  max-width: 100% !important;
}

.covid-qr-before-video .container .covid19-bottom-phone {
  width: auto;
}

.container-fluid.covid_center_section .col-md-12 {
  display: block;
  margin: auto;
}

.covid-main-title-long img.fullwidth-screen {
  height: auto;
  position: relative;
  width: 600px;
  max-width: 100%;
  margin-top: -60px;
}

.fullwidth-screen-container {
  margin-bottom: 20px;
}

.second-child-tips {
  background: #ffe4b3;
  padding-top: 50px;
}

.one-slide div#slider_pagination {
  display: none !important;
}

.one-slide .slider_nav_left,
.one-slide .slider_nav_right {
  display: none !important;
}

.col-md-4.ehealthpro-step-horizontal {
  text-align: center;
  margin-top: 35px;
}

.step-horizontal-content {
  font-size: 16px;
  max-width: 260px;
  margin: auto;
  color: #0a4669;
}

img.step-horizontal-arrow {
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
  width: 40px;
}

.step-horizontal-subtitle {
  text-align: center;
  font-size: 18px;
  margin-top: 12px;
  color: #0a4669;
}

img.pro-tips-image {
  width: 70px;
  margin-right: 10px;
  margin-top: -8px;
}

.step-horizontal-title {
  font-family: "Roboto", "RobotoR";
}

ul.dropdown-menu {
  max-height: calc(100vh - 68px);
  overflow-y: scroll;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.whats-new-container .container {
  text-align: center;
}
.whats-new-container .tabs {
  background-color: #fff;
}
.whats-new-container .tabs .tab {
  border: 0px;
  color: #666;
  border-radius: 0px;
  border-bottom: 3px solid #eeeeee;
}
.whats-new-container .tabs .tab.active {
  color: #222222;
  font-size: 18px;
  border-radius: 0px;
  border-bottom: 3px solid #007272;
}
.whats-new-title {
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
}
.whats-new-title a {
  color: #222;
}
.whats-new-data {
  color: #555555;
}
@media (min-width: 1800px) {
  .covid-qr-export-row {
    padding-top: 0px;
  }
}

@media (min-width: 768px) {
  .covid-qr-export-row .container .col-md-5.col-sm-12.col-sm-push-7 {
    left: 55%;
  }
}

@media (max-width: 1043px) {
  .part-title b a br {
    display: inline-block;
  }

  .arrow-right-1st {
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    border-left: 14px solid #206ca2;
    margin-left: 6px;
  }
}

@media (max-width: 768px) {
  img.under16-icon {
    width: 60px;
    left: 50%;
    transform: translateX(-50%);
    top: -50px;
  }
  .under16-earn-coins-container {
    padding: 0;
    width: 95%;
  }
  .under16-earn-coins-title h3 {
    padding: 20px 30px 20px 30px;
    margin-bottom: 20px;
  }

  div.under16-earn-coins-col:first-child {
    border-right: 0px;
    margin-bottom: 30px;
  }
  .about-redemption-btn-row img {
    display: block;
    margin: auto;
    margin-top: 30px;
  }
  .birth-kit-content {
    padding: 20px;
  }
  .about-redemption-btn-row a.mission-btn {
    margin-top: 12px;
    margin-bottom: 16px;
  }
  .under16-earn-coins-container .container {
    margin-top: 0;
  }
  .birth-kit-container h3 {
    max-width: 104%;
    margin-left: -2%;
    padding: 16px;
    line-height: 1.3em;
  }
  .birth-kit-container br {
    display: none;
  }
  .birth-kit-container .main-graphic {
    width: 400px;
  }
  .birth-kit-gift-redemption-intro {
    font-size: 16px;
  }
  .birth-kit-container .container span.mission-date {
    font-size: 18px;
  }
  div.hero-banner {
    margin-top: 65px;
  }
  .disclaimer-container {
    padding: 60px 20px;
  }
  img.birth-kit-icon {
    width: 80px;
  }
  .cover-white-container .container {
    text-align: center;
    padding: 12px;
  }
  .cover-white-container .container h3 {
    font-size: 16px;
    margin-top: 12px;
  }
  .cover-white-container {
    padding-bottom: 40px;
  }
  img.addon-image {
    width: 120px;
  }
  .expand-panel-content {
    text-align: center;
  }
  .step-number {
    margin: 16px auto 16px auto;
  }
  .elife-box-content .second-youtube-container {
    padding-top: 56%;
  }

  .overview-item-text {
    height: auto !important;
  }
  html[lang="en"] .overview-item-text {
    height: auto !important;
  }

  .life-overview-button-container {
    margin-bottom: 40px;
  }

  .overview-intro0 {
    font-size: 16px;
  }

  h2.life-section-title {
    font-size: 22px;
    z-index: 2;
    padding: 15px;
    text-align: center;
    min-width: 180px;
  }

  .life-tutorial-phone {
    margin-top: 60px;
  }

  .life-tutorial-btn-row {
    margin: 0px;
    margin-bottom: 60px;
  }

  h2.life-section-title:after {
    width: 80px;
    height: 66px;
    top: -60px;
  }

  img.overview-park-main {
    width: 300px;
    margin: auto;
    display: block;
    margin-bottom: 24px;
  }

  .life-overview-button {
    width: 100% !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .ehealthpro-color .container.slider-content-container .col-sm-pull-6 {
    right: auto;
  }

  .dropdown-menu > li > a:hover {
    background: #fff;
  }

  .part-title b a br {
    display: none !important;
  }

  img.home-ytplay-icon {
    width: 23px;
  }

  .home-yt-btn-shadow,
  a.home-yt-btn-a {
    font-size: 18px;
    padding: 10px 14px;
    width: 210px;
    margin-top: 5px;
  }

  .home-yt-btn-shadow {
    margin-top: 12px !important;
  }

  a.home-yt-btn-a:hover {
    margin-top: 10px;
  }

  .container-fluid.caregiver-tips .col-md-3 {
    margin-top: 0;
  }

  .caregiver-tips img#img1MyFamilySection4 {
    max-width: 70%;
    margin: auto;
    display: block;
    min-width: 180px;
    max-width: 230px;
  }
}

@media (max-width: 500px) {
  .static_page_section_title p {
    font-size: 21px !important;
    line-height: 1.35em;
  }
}

/********** Vaccine Pass Calculator **********/
.clear {
  clear: both;
}

.error-message {
  color: red;
  float: left;
}

.calculator-container {
  min-height: 100vh;
}

.calculator-container .container {
  margin-top: 170px;
  position: relative;
  width: 100%;
}

.calculator-title-container {
  position: relative;
}

h2.calculator-title {
  text-align: center;
  background: #fff;
  padding: 30px 120px 120px 80px;
  width: auto;
  display: table;
  border-radius: 40px;
  margin: auto;
  font-weight: 700;
  color: #007272;
}

html[lang="zh-HK"] h2.calculator-title,
html[lang="zh-CN"] h2.calculator-title {
  letter-spacing: 1px;
}

.calculator-title-character {
  bottom: 95px;
  position: absolute;
  right: 0;
  height: 150px;
}

.calculator-title-container {
  max-width: 600px;
  margin: auto;
}

.calculator-form-container {
  border: 1px solid #99c8c8;
  border-radius: 15px;
  background: #fff;
  width: 800px;
  max-width: 100%;
  position: relative;
  margin: auto;
  margin-top: -95px;
  padding: 35px;
  padding-top: 60px;
  padding-bottom: 170px;
  box-shadow: 20px 20px #72b9b9;
}

.calculator-form {
  width: 650px;
  max-width: 100%;
  margin: auto;
}

.calculator-question-container p.calculator-question {
  display: block;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 18px;
}

.calculator-question-container p.calculator-question b {
  font-size: 24px;
  font-weight: 900;
  margin-right: 10px;
}

img.tooltips-icon {
  width: 23px;
  margin-right: 10px;
  margin-top: -3px;
  cursor: pointer;
}

#recovery-tooltip-content {
  margin-top: -35px;
  background: #fff;
  position: absolute;
  z-index: 2;
  width: 410px;
  height: auto;
  text-align: justify;
  line-break: auto;
  padding: 25px 40px 25px 25px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #d5d5d5;
}

#recovery-tooltip-content a {
  line-break: anywhere;
}

img.close-tooltips-icon {
  width: 25px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.calculator-question-container {
  margin-bottom: 40px;
  display: table;
  width: 100%;
}

.accessibility-wrapper {
  position: relative;

  &.clickable {
    cursor: pointer;
  }

  .alt-image {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
  }
}

div.calculator-submit {
  transition: 0.5s all;
  width: 100%;
  padding: 15px;
  border: 0px;
  background: #007272;
  color: #fff;
  font-size: 18px;
  border-radius: 100px;
  font-weight: 700;
  box-shadow: 0px 7px #005249;
  cursor: pointer;
  text-align: center;
}

div.calculator-submit:hover {
  box-shadow: 0px 2px #005249;
  margin-top: 5px !important;
  margin-bottom: -5px !important;
  transition: 0.5s all;
}

div.calculator-submit.disabled {
  background: #9d9d9d;
  box-shadow: 0px 7px #ccc;
  cursor: not-allowed;
}

div.calculator-submit.disabled:hover {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

img.calculator-men-character {
  width: 40%;
  margin-left: -10px;
}

.calculator-select-date {
  background: #e6f1f1;
  position: absolute;
  top: 0;
  left: 50%;
  text-align: center;
  padding: 16px 20px;
  margin-top: -15px;
  width: 320px;
  transform: translateX(-50%);
  border-radius: 10px;
  cursor: pointer;
}

.calculator-select-date span {
  font-size: 18px;
  margin: 10px 0px;
}

.calculator-decoration1 {
  position: absolute;
  width: 55px;
  top: 30px;
  left: 40px;
}

.calculator-decoration2 {
  position: absolute;
  width: 100px;
  bottom: 240px;
  left: -30px;
  opacity: 0.5;
}

.calculator-decoration3 {
  position: absolute;
  width: 150px;
  right: -30px;
  top: 250px;
  width: 100px;
  opacity: 0.5;
}

.input-date-picker-container {
  margin-right: 2%;
  font-size: 18px;
  width: 32%;
  float: left;
  position: relative;
}

.input-date-picker-container input {
  border-radius: 0px;
}

.common-date-picker-container {
  float: left;
  width: 100%;
  display: block;
  margin-bottom: 6px;
  padding: 0px 2px;
}

.input-date-picker {
  border: 0px;
  border-bottom: 1px solid #bbb;
  text-align: center;
  width: 100%;
}

.input-date-picker-container:last-child {
  margin-right: 0px;
}

.input-date-picker-list ul {
  border: 1px solid #007272;
  margin-top: -1px;
  position: relative;
  max-height: 250px;
  height: auto;
  overflow-y: scroll;
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 2;
}

.input-date-picker-list li {
  border-bottom: 1px solid #daeff0;
  padding: 10px;
  text-align: center;
  color: #666;
  height: 45px;
}

.input-date-picker-list li:hover {
  background: #e7f4f5;
  cursor: pointer;
}

.custom-dropdown-container {
  position: relative;
}

.custom-dropdown-container .dropdown-selector {
  border: 1px solid #007272;
  padding: 12px;
  text-align: center;
  background: #fff;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 16px;
}

.dropdown-selector:before {
  content: "";
  position: absolute;
  background: url(../images/calculator-dropdown-arrow.png);
  width: 20px;
  height: 20px;
  right: 15px;
  top: 50%;
  transform: translate(-50%, -50%);
  background-size: cover;
}

.dropdown-selector.vaccine-selected {
  background: #007272;
  color: #fff;
}

.dropdown-selector.vaccine-selected:before {
  background-image: url(../images/calculator-dropdown-arrow-active.png);
}

.custom-dropdown-container ul {
  border: 1px solid #007272;
  margin-top: -11px;
  height: auto;
  overflow-y: scroll;
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 2;
  max-height: 400px;
}

.custom-dropdown-container ul li {
  border-bottom: 1px solid #daeff0;
  padding: 10px;
  text-align: center;
  color: #666;
  font-size: 16px;
}

.custom-dropdown-container ul li:hover {
  background: #e7f4f5;
  cursor: pointer;
}

.other-vaccine-button {
  margin-top: 10px;
}

div.calculator-radio-button.radio-last {
  margin-left: 1%;
}

div.calculator-radio-button.radio-first {
  margin-right: 1%;
}

div.calculator-radio-button {
  border: 1px solid #007272;
  width: 49%;
  float: left;
  text-align: center;
  padding: 12px;
  margin-bottom: 0px;
  font-weight: 400;
  cursor: pointer;
  background: #fff;
  font-size: 16px;
}

.calculator-radio-button input {
  display: none;
}

.calculator-question-container select {
  padding: 15px;
  width: 100%;
  text-align: center;
}

p.calculator-sub-question {
  color: #007272;
  font-size: 15px;
  margin-top: 12px;
}

.calculator-app-section {
  position: relative;
  display: flex;
  justify-items: center;
  align-items: end;
  margin-top: -120px;
}

.calculator-app-text {
  top: 0;
  width: 430px;
  background: #e5f1f1;
  border-radius: 30px;
  padding: 30px 30px;
  font-size: 18px;
  position: relative;
  margin-bottom: 30px;
}

html[lang="zh-HK"] .calculator-app-text,
html[lang="zh-CN"] .calculator-app-text {
  line-height: 1.7em;
}

.calculator-app-text:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 25px solid #e4f1f1;
  position: absolute;
  left: -25px;
  top: 50%;
}

.calculator-app-text b {
  display: block;
  font-size: 28px;
  color: #005050;
  font-weight: 600;
  line-height: 1.2em;
  font-family: "Roboto", "RobotoR";
}

p.vaccine-type-title {
  float: left;
  width: 65px;
  padding-top: 20px;
  margin-right: 5px;
}

div.calculator-radio-button.vaccine-type.radio-last {
  margin-right: 0px;
}

.vaccine-type-single {
  display: flex;
}

.vaccine-type-single-selection {
  flex: 1;
  position: relative;
}

.vaccine-type-dropdown {
  margin-top: 2px;
}

img.calculator-date-picker-icon {
  width: 30px;
  margin-top: -7px;
  margin-left: 10px;
}

img#vaccine-type-tooltip-button {
  position: absolute;
  left: -40px;
  bottom: 15px;
}

.vaccine-type-single {
  background: #f3f9fa;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.calculator-form .active-button {
  background: #007272 !important;
  color: #fff;
  font-weight: 900;
}

.calculator-datepicker-overlay {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
}

.calculator-datepicker-popup {
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
  z-index: 9999;
  background: #fff;
  width: 600px;
  max-width: 90%;
  padding: 30px;
  height: 300px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #000000;
}

img.close-popup-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  cursor: pointer;
}

.calculator-datepicker-popup h3 {
  text-align: center;
  color: #007272;
  font-size: 20px;
  margin-bottom: 40px;
  font-weight: 900;
}

.calculator-datepicker-popup div.calculator-submit {
  padding: 10px;
  width: 300px;
  margin: auto;
  display: block;
  margin-top: 30px;
}

.calculator-datepicker-popup div.calculator-submit:hover {
  margin-top: 35px !important;
}

input#date-picker-date {
  margin-right: 0px;
}

.calculator-result-child.result-fix-width img {
  width: 90%;
  margin-top: 0px;
}

div.calculator-radio-button:hover {
  background: #e5f1f1;
}

/***Not allow styling*/
.not-allow.result-fix-width {
  border-color: #c31d16;
}

.not-allow .result-big-text-container {
  background: #c31d16;
  border-color: #c31d16;
}

.not-allow .result-date-container .result-title-on-date {
  color: #c31d16;
}

.not-allow .result-top {
  border-color: #c31d16;
}
.news-item-true:before {
  content: "";
  background-image: url(../images/en/new-item-tag.png);
  position: absolute;
  top: 15px;
  left: 8px;
  z-index: 2;
  width: 100px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
}
.news-item-zh-HK-true:before {
  background-image: url(../images/zh-HK/new-item-tag.png);
}
.news-item-zh-CN-true:before {
  background-image: url(../images/zh-CN/new-item-tag.png);
}
.news-item-en-true:before {
  background-image: url(../images/en/new-item-tag.png);
}
.news-item-container .col-sm-4:nth-child(4),
.news-item-container .col-sm-4:nth-child(7) {
  clear: left;
}

@media (min-width: 1024px) {
  html[lang="zh-HK"] h2.calculator-title,
  html[lang="zh-CN"] h2.calculator-title {
    letter-spacing: 1px;
    padding: 30px 120px 120px 120px;
  }
}

@media (max-width: 1024px) {
  .calculator-app-text b {
    font-size: 24px;
  }
  .news-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .whats-new-container .tabs {
    width: 100%;
  }
  .whats-new-container .tabs .tab {
    font-size: 15px;
    padding-left: 0;
    padding-right: 0;
    width: 33.3%;
  }
  .whats-new-container .tabs .tab.active {
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .news-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .custom-dropdown-container .dropdown-selector {
    padding-right: 50px;
  }

  .calculator-app-text:before {
    left: -20px;
  }

  .calculator-app-text b {
    font-size: 22px;
  }

  .calculator-container {
    padding: 0px 40px;
  }

  .calculator-container .container {
    margin-top: 150px;
  }

  .calculator-form-container {
    box-shadow: 12px 12px #72b9b9;
    padding-bottom: 150px;
    margin-bottom: 50px;
  }

  h2.calculator-title {
    font-size: 25px;
    padding: 29px 90px 130px 90px;
  }

  .calculator-title-character {
    right: 20px;
  }

  .calculator-select-date {
    padding: 13px;
    top: 5px;
    max-width: 100%;
  }

  .calculator-question-container p.calculator-question {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .calculator-question-container p.calculator-question b {
    font-size: 21px;
    margin-right: 5px;
  }

  .input-date-picker {
    font-size: 16px;
  }

  .input-date-picker-list li {
    font-size: 16px;
  }

  .calculator-select-date span {
    font-size: 16px;
  }

  img.calculator-date-picker-icon {
    width: 25px;
  }

  p.calculator-sub-question {
    margin-top: 5px;
  }

  .calculator-decoration1 {
    width: 35px;
    left: 0px;
  }

  .calculator-decoration3 {
    top: -50px;
    width: 80px;
  }
}

@media (max-width: 650px) {
  .calculator-container {
    padding: 0px 10px;
  }

  .calculator-title-character {
    right: 5px;
    height: 105px;
    bottom: 105px;
  }

  h2.calculator-title {
    font-size: 23px;
  }

  .calculator-select-date {
    position: relative;
    margin-bottom: 35px;
    padding: 11px;
  }

  .calculator-form-container {
    padding-top: 40px;
    margin-top: -105px;
    box-shadow: 0px 0px #72b9b9;
  }

  div.calculator-radio-button {
    margin-right: 0px !important;
    margin-left: 0px !important;
    width: 100%;
    margin-bottom: 6px;
    padding: 9px;
  }

  .calculator-question-container select {
    padding: 9px;
    margin-top: 0px;
  }

  .calculator-app-text b {
    font-size: 19px;
  }
}

@media (max-width: 500px) {
  h2.calculator-title {
    width: 80%;
    margin: 0;
    padding: 25px 0px 120px 0px;
    border-radius: 25px;
    margin-left: 7px;
    font-size: 21px;
  }

  html[lang="en"] .result-date-container {
    padding-top: 6px;
  }

  html[lang="en"] a.result-bottom-btn {
    padding-left: 62px;
    width: 320px !important;
    font-size: 16px;
  }
}

@media (max-width: 425px) {
  div.calculator-submit {
    font-size: 16px;
    padding: 12px;
    font-weight: 500;
    line-height: 18px;
  }

  .calculator-container {
    padding: 0px;
  }

  .calculator-form-container {
    padding: 25px;
    padding-top: 45px;
  }

  .calculator-question-container p.calculator-question {
    font-size: 16px;
  }

  .calculator-question-container p.calculator-question b {
    font-size: 18px;
  }

  .calculator-form-container {
    padding-bottom: 100px;
  }

  .calculator-app-text b {
    font-size: 18px;
  }
}

/***************************/

/**************Cal Result************/

.calculator-result {
  border: 2px solid #007272;
}

.result-fix-width {
  width: 550px;
  max-width: 100%;
  margin: auto;
  font-weight: 400;
}

.result-top {
  display: flex;
  border-bottom: 2px solid #007272;
}

.result-date-container .result-title-on-date {
  font-weight: 900;
  color: #007272;
  margin-bottom: 0px;
  margin-top: 5px;
}

html[lang="en"] .result-date-container .result-title-on-date {
  line-height: 1.1em;
  font-weight: 600;
}

.result-date-container {
  font-size: 20px;
  padding: 12px;
  padding-bottom: 0px;
}

html[lang="en"] .result-date-container {
  padding-top: 8px;
}

.result-date-container button {
  background: #007272;
  border-radius: 100px;
  border: 0px;
  color: #fff;
  padding: 4px 20px;
  font-weight: 800;
  font-size: 14px;
}

.result-date-first-row {
  display: flex;
  align-items: center;
}

span.result-date {
  border-bottom: 1px solid;
  margin: auto 15px auto 5px;
}

html[lang="zh-CN"] span.result-date {
  border-bottom: 1px solid;
  margin: 4px 15px 4px 5px;
}

html[lang="zh-HK"] span.result-date {
  border-bottom: 1px solid;
  margin: 4px 15px 4px 5px;
}

.result-big-text-container {
  background: #01a77b;
  width: 120px;
  border-right: 2px solid #007272;
  padding: 15px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

img.calculator-result-maingraphic {
  width: 75%;
  margin: 40px auto;
  display: block;
  margin-top: 40px;
}

.result-main-text {
  font-size: 20px;
  text-align: center;
  margin-bottom: 40px;
  line-height: 1.5em;
  padding: 0px 20px;
}

.result-main-text b {
  font-size: 22px;
  font-weight: 900;
}

.disclaimer-text {
  font-size: 17px;
  text-align: left;
  margin-top: 40px;
  line-height: 1.5em;
}

.disclaimer-url {
  font-size: 17px;
  text-align: right;
  text-decoration: underline;
}

.disclaimer-popup-overlay {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
}

.disclaimer-popup-container {
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
  z-index: 9999;
  background: rgba(0, 0, 0, 0);
  width: 800px;
  max-width: 96%;
  height: auto;
  z-index: 10000;
}

.disclaimer-popup-img-desktop {
  width: auto;
  z-index: 10001;
  display: block;
}

.close-disclaimer-popup-icon-desktop {
  position: absolute;
  left: 50%;
  top: 76%;
  width: 145px;
  height: auto;
  cursor: pointer;
  z-index: 10002;
  display: block;
  margin-left: -72px;
}

.disclaimer-popup-text-desktop {
  position: absolute;
  font-size: 18px;
  text-align: center;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55%;
  color: rgb(0, 0, 0);
  z-index: 10003;
}

html[lang="en"] .disclaimer-popup-text-desktop {
  top: 55%;
  width: 68%;
}

.disclaimer-popup-img-mobile,
.close-disclaimer-popup-icon-mobile,
.disclaimer-popup-text-mobile {
  display: none;
}

/*.close-disclaimer-popup-icon-mobile {
    display: none;
}

.disclaimer-popup-text-mobile {
    display: none;
}*/

@media (max-width: 768px) {
  .disclaimer-popup-img-desktop,
  .close-disclaimer-popup-icon-desktop,
  .disclaimer-popup-text-desktop {
    display: none;
  }

  /*.close-disclaimer-popup-icon-desktop {
        display: none;
    }
    
    .disclaimer-popup-text-desktop {
        display: none;
    }*/

  .disclaimer-popup-img-mobile {
    width: auto;
    z-index: 10001;
    display: block;
  }

  .close-disclaimer-popup-icon-mobile {
    position: absolute;
    left: 50%;
    top: 67%;
    width: 145px;
    height: auto;
    cursor: pointer;
    z-index: 10002;
    display: block;
    margin-left: -72px;
  }

  html[lang="en"] .close-disclaimer-popup-icon-mobile {
    top: 72%;
    display: block;
  }

  .disclaimer-popup-text-mobile {
    font-size: 18px;
    text-align: center;
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55%;
    color: rgb(0, 0, 0);
    z-index: 10003;
    display: block;
  }

  html[lang="en"] .disclaimer-popup-text-mobile {
    top: 50%;
    width: 70%;
    display: block;
  }
}

@media (max-width: 550px) {
  .disclaimer-popup-text-mobile {
    font-size: 17px;
    width: 65%;
  }

  html[lang="en"] .disclaimer-popup-text-mobile {
    bottom: 10%;
    font-size: 16px;
    top: 48%;
  }
}

@media (max-width: 500px) {
  .disclaimer-popup-text-mobile {
    font-size: 16px;
  }

  html[lang="en"] .disclaimer-popup-text-mobile {
    font-size: 14px;
  }

  html[lang="en"] .close-disclaimer-popup-icon-mobile {
    top: 67%;
  }
}

@media (max-width: 440px) {
  .disclaimer-popup-text-mobile {
    font-size: 13px;
  }

  html[lang="en"] .disclaimer-popup-text-mobile {
    font-size: 12px;
  }
}

.result-other-info {
  border-radius: 5px;
  background: #f4f4f4;
  margin-top: 30px;
  padding: 18px;
  font-size: 18px;
}

.other-info-title {
  cursor: pointer;
}

img.other-info-toggle {
  width: 18px;
  margin-right: 10px;
  margin-top: -2px;
}

.other-info-content {
  font-size: 18px;
  margin-top: 12px;
  color: #333;
}

.result-button-container {
  margin-top: 30px;
}

.result-button-container .result-button {
  border: 1px solid #007272;
  width: 49%;
  float: left;
  text-align: center;
  margin-right: 1%;
  padding: 12px;
  background: #fff;
  color: #007272;
  font-size: 18px;
}

.result-button-container .result-button:hover {
  background: #f1fbfb;
}

.result-button-container .result-button:last-child {
  margin-right: 0px;
  margin-left: 1%;
}

.calculator-disclaimer {
  color: #676767;
  font-size: 13px;
  margin-top: 40px;
}

.other-action-button {
  margin-top: 30px;
}

a.result-bottom-btn:hover {
  text-decoration: none;
}

a.result-bottom-btn {
  background: #007272;
  color: #fff;
  padding: 15px;
  width: 290px;
  display: table;
  margin: auto;
  margin-bottom: 18px;
  border-radius: 100px;
  text-align: left;
  font-size: 17px;
  position: relative;
  padding-left: 77px;
  opacity: 0.95;
}

html[lang="en"] a.result-bottom-btn {
  width: 360px;
}

a.result-bottom-btn:hover {
  opacity: 1;
}

a.result-bottom-btn:before {
  content: "";
  width: 65px;
  height: 65px;
  position: absolute;
  left: -14px;
  top: -5px;
  background: url(../images/calculator-result-vaccine-button.png);
  background-size: cover;
  background-position: center;
}

#result-website.result-bottom-btn:before {
  background-image: url(../images/calculator-result-website-button.png);
}

#result-doctor-search.result-bottom-btn:before {
  background-image: url(../images/calculator-result-doctor-button.png);
}

#result-doctor-search.result-bottom-btn:before {
  background-image: url(../images/calculator-result-doctor-button.png);
  height: 70px;
  top: -9px;
}

img.result-doctor-search-icon {
  width: 20px;
  margin-top: -4px;
  margin-left: 5px;
}

.calculator-result-child.result-fix-width {
  border: 0px;
}

@media (max-width: 768px) {
  #recovery-tooltip-content {
    width: calc(100% - 30px);
  }

  img.calculator-result-maingraphic {
    width: 75%;
    margin: 40px auto;
  }

  .result-main-text {
    font-size: 18px;
  }

  .result-main-text b {
    font-size: 20px;
  }

  .other-info-content {
    font-size: 16px;
  }

  a.result-bottom-btn {
    padding: 12px;
    padding-left: 70px;
  }

  a.result-bottom-btn:before {
    width: 60px;
    height: 60px;
    left: -10px;
  }

  #result-doctor-search.result-bottom-btn:before {
    height: 65px;
  }

  .result-button-container .result-button {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 615px) {
  .calculator-result-container {
    padding: 20px;
    padding-bottom: 130px;
  }

  .result-date-container {
    font-size: 16px;
    padding: 12px;
    padding-top: 6px;
    padding-bottom: 0px;
  }

  .result-date-container button {
    margin-top: 0px;
    font-size: 12px;
    padding: 2px 14px;
  }

  html[lang="zh-CN"] .result-date-container button {
    margin-top: 0px;
    font-size: 12px;
    padding: 4px 14px 2px 14px;
  }

  html[lang="zh-HK"] .result-date-container button {
    margin-top: 0px;
    font-size: 12px;
    padding: 4px 14px 2px 14px;
  }

  span.result-date {
    margin-right: 6px;
  }

  .result-big-text-container {
    width: 90px;
  }

  .calculator-datepicker-popup {
    width: 500px;
    padding: 20px;
    height: auto;
  }

  .calculator-datepicker-popup div.calculator-submit {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 16px;
    padding: 10px;
    width: 200px;
  }

  .calculator-datepicker-popup h3 {
    margin-bottom: 30px;
    font-size: 18px;
  }

  .calculator-datepicker-popup .common-date-picker-container {
    margin-bottom: 30px;
  }
}

@media (max-width: 500px) {
  .result-big-text-container {
    width: 80px;
  }

  .result-date-container {
    padding-top: 0px;
    padding-right: 6px;
    padding-left: 10px;
  }

  .result-date-container button {
    font-size: 12px;
  }

  img.calculator-result-maingraphic {
    width: 84%;
    margin: 28px auto;
  }

  .result-main-text {
    margin-bottom: 30px;
  }

  .result-other-info {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 17px;
  }

  .result-button-container .result-button {
    padding: 10px;
    font-size: 16px;
  }

  .disclaimer-text {
    font-size: 16px;
  }

  .disclaimer-url {
    font-size: 16px;
  }

  .disclaimer-popup-text-desktop {
    font-size: 16px;
  }
}

@media (max-width: 412px) {
  .result-date-first-row {
    display: block;
  }
}

/***************************/

/*************Maintenance**************/
.maintenance-container {
  min-height: 100vh;
}

.maintenance-container .container {
  width: 420px;
  max-width: 100%;
  padding-top: 150px;
}

.maintenance-img {
  width: 240px;
  display: block;
  margin: auto;
  margin-bottom: 30px;
}

.error-page-title {
  text-align: center;
  font-size: 24px;
  color: #000;
  font-weight: 900;
  margin-bottom: 5px;
}

.error-page-content {
  text-align: center;
  font-size: 20px;
  color: #444;
}

a.error-page-back {
  background: #007272;
  color: #fff;
  padding: 13px 50px;
  display: table;
  margin: auto;
  font-size: 20px;
  border-radius: 100px;
  margin-top: 30px;
  box-shadow: 0px 7px #005249;
}

a.error-page-back:hover {
  box-shadow: 0px 2px #005249;
  margin-top: 35px !important;
  transition: 0.5s all;
  text-decoration: none;
}

.maintenance-container {
  padding-bottom: 300px;
}

.static_page_header_privacy .container .row {
  height: 95vh;
  max-height: 490px;
}

.static_page_header_privacy p {
  line-height: 1.1em;
  text-align: left;
  color: #007272;
  font-family: "RobotoB";
  letter-spacing: 0px;
  font-size: 50px;
}

html[lang="en"] .static_page_header_privacy p {
  line-height: 1.1em !important;
}

.static_page_header_privacy p span {
  font-size: 24px;
  float: left;
  font-weight: 300;
  line-height: 1.4em;
  margin-top: 10px;
  color: #666;
  font-family: "RobotoR";
}

.static_page_header_privacy .col-sm-6 {
  max-width: 500px;
}

.privacy-title {
  font-size: 30px;
  text-align: center;
  font-weight: 900;
  color: #007272;
  margin-bottom: 5px;
  font-family: "RobotoB";
}

.privacy-text {
  text-align: center;
  color: #333333;
  font-size: 21px;
}

.privacy-photo img {
  height: auto;
}

.privacy-photo {
  margin-top: 30px;
}

.privacy-reminder {
  // margin-top: 70px;
  position: relative;
  z-index: 99;
}

.reminder-speak-bubble {
  background: #ffc86b;
  padding: 25px 35px;
  border-radius: 15px;
  line-height: 2em;
}

.reminder-speak-bubble span {
  display: block;
  font-size: 18px;
}

.reminder-speak-bubble b {
  font-size: 23px;
  font-weight: 900;
  margin-top: -3px;
  display: table;
  font-family: "RobotoB";
}

p.privacy-case-content {
  z-index: 99;
  position: relative;
  max-width: 500px;
  margin: auto;
  text-align: center;
  font-size: 18px;
  margin-top: -125px;
  margin-bottom: 20px;
}

html[lang="en"] p.privacy-case-content {
  margin-top: -116px;
  margin-bottom: 13px;
}

p.privacy-case-content b {
  color: #007272;
  font-weight: 900;
  font-family: "RobotoB";
}

.before-tip-clear {
  margin-bottom: 130px;
}

.privacy-reminder-img img {
  max-width: 150px;
  margin-top: -35px;
  float: right;
}

.reminder-speak-bubble:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #ffc76a;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
}

.privacy-tip-content {
  background: #fff;
  padding: 25px;
  border-radius: 100px;
  font-size: 19px;
  line-height: 1.3em;
  margin-left: -100px;
  z-index: -1;
  padding-left: 120px;
  margin-right: 25px;
  padding-right: 42px;
  width: 43%;
}

.privacy-tip-content.tip-long {
  width: 60%;
}

.privacy-tip-img {
  padding: 0px;
}

.privacy-section {
  padding-bottom: 60px;
  padding-top: 60px;
}

.privacy-tip-row {
  width: 100%;
  margin-left: 25px;
  margin-top: 20px;
  float: left;
}

.privacy-tip-row-3col .col-sm-4 {
  padding: 45px;
}

.privacy-tip-row-3col .col-sm-4 p {
  font-size: 20px;
  text-align: center;
  margin-top: 25px;
}

.privacy-tip-row-3col .col-sm-4 img {
  max-width: 220px;
  margin: auto;
  display: block;
}

.privacy-tip-row.second-privacy-tip-row {
  margin-top: 5px;
}

div.privacy-section.second-feature-section:nth-child(2n) {
  background: #f4fbfb !important;
}

div.privacy-section.second-feature-section {
  background: #e4f5f5 !important;
}

img.privacy-title-icon {
  width: 38px;
  margin-top: -8px;
  margin-right: 5px;
}

/******For privact policy******/

.epp-privacy-background {
  padding-right: 15px;
  padding-left: 15px;
  background: #e4f5f5 !important;
  padding-bottom: 50px;
}

.epp-privacy-container {
  width: 85%;
  margin: auto;
}

.epp-privacy-title {
  padding-top: 30px;
  left: 0%;
  color: #237b70;
  font-weight: 600;
  font-size: 32px;
  letter-spacing: 0px;
  margin: auto;
  font-family: "RobotoR", "SourceHanSansCNR", "Arial", "Microsoft YaHei",
    "sans-serif";
}

html[lang="en"] .epp-privacy-title {
  font-size: 29px;
  font-weight: 700;
}

.epp-privacy-content {
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #333;
  //line-height: 1.6em;
  letter-spacing: 0.075em;
  margin: auto;
  text-align: justify;
}

html[lang="en"] .epp-privacy-content {
  font-size: 17px;
  text-align: justify;
  letter-spacing: 0.03em;
}

.epp-privacy-content p {
  padding-top: 10px;
}

.epp-privacy-content a {
  word-break: break-all;
}

.download-container {
  width: 1000px;
  max-width: 100%;
  margin-bottom: 150px;
}

.static_page_header_download .static_page_header_bg {
  background-position: 0% 50% !important;
  background-size: 100% !important;
}

.static_page_header_download {
  margin-bottom: 30px;
}

.download-item {
  background: #f1f9f9;
  padding: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: table;
}

.download-item.background {
  padding: 0px;
  width: 48%;
  margin: 1%;
}

.sticker-item-title {
  font-size: 22px;
  font-weight: 900;
  color: #005252;
}

.download-item .sticker-item-preview {
  width: 20%;
  padding: 2%;
  float: left;
}

a.item-download {
  display: block;
  float: left;
  background: #007272;
  color: #fff;
  padding: 15px 40px;
  float: right;
  border-radius: 3px;
  font-size: 18px;
  transition: 0.3s all;
  box-shadow: 0px 4px #005252;
}

.sticker-set a.item-download {
  position: absolute;
  bottom: 70px;
  right: 50px;
  margin-top: 10%;
}

.background a.item-download {
  padding: 7px 25px;
  margin-left: 8px;
  font-size: 15px;
}

a.item-download:hover {
  background: #006969;
  text-decoration: none;
  transition: 0.3s all;
  margin-top: 2px;
  margin-bottom: -2px;
  box-shadow: 0px 2px #005252;
}

.background-item-download {
  padding: 20px;
  font-size: 16px;
  display: table;
  width: 100%;
  position: relative;
}

.background-item-download span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
}

.faq-title {
  display: table;
  width: 100%;
  text-align: right;
  margin-bottom: 15px;
}

.faq-title h3 {
  color: #0079c1;
  float: left;
  margin-top: 10px;
  font-size: 22px;
}

span.faq-expand {
  cursor: pointer;
  background: #0079c1;
  color: #fff;
  padding: 8px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  float: right;
}

.faq-list {
  padding-left: 20px;
}

.faq-question img {
  width: 20px;
  margin-top: 3px;
  position: absolute;
  right: 20px;
  top: 17px;
}

.faq-expand-all {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.faq-question-container {
  border-top: 1px solid #007272;
  margin-top: -1px;
}

.faq-question {
  font-size: 19px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: -1px;
  cursor: pointer;
  padding-right: 60px;
  position: relative;
}

.faq-answer {
  line-height: 1.8em;
  font-size: 15px;
  margin-bottom: 20px;
  color: #666;
  padding-right: 60px;
}

.faq-section:not(.first-faq-section) {
  margin-top: 60px;
}

.faq-container {
  margin-bottom: 100px;
}

.pro-faq span.faq-expand {
  background: #1d4e75;
}

.pro-faq .faq-title h3 {
  color: #1d4e75;
}
#faq-question-container-functions-licence-scanner ol > li {
  list-style: auto !important;
}

#faq-question-container-functions-licence-scanner ol > li ul {
  padding-left: 20px;
}
#faq-question-container-functions-licence-scanner ol > li ul li {
  list-style: disc !important;
}

.orderlist-style li {
  list-style: auto;
}

ol.orderlist-style {
  padding-left: 30px;
}

div.privacy-section.second-feature-section.last-privacy-section {
  background: #d8efef !important;
}

.banner-center-container {
  background: #f6f1eb;
  text-align: center;
}

.banner-center-container img {
  width: 1100px;
}

.download-apk-page .main-title {
  text-align: left;
  font-size: 3rem;
  font-weight: 700;
  color: #337ab7;
  position: relative;
}

.download-apk-blue {
  background: #ebf3ff;
  border-radius: 25px;
  padding: 42px;
  text-align: left;
  margin-top: 30px;
  font-size: 17px;
}

.download-apk-blue b {
  display: block;
  margin-bottom: 15px;
}

img#DownloadApkGirl {
  width: 120px;
  margin-left: 65px;
  margin-bottom: -30px;
  margin-top: 6px;
}

.main-title-text {
  float: left;
}

.download-apk-container {
  margin-top: 150px;
}

.download-apk-grey {
  background: #f6f6f6;
  padding: 42px;
  border-radius: 25px;
  margin-bottom: 100px;
}

.download-apk-mama-container img {
  width: 200px;
}

.download-apk-mama-container {
  text-align: right;
}

.download-apk-grey ol,
.download-apk-grey ol li {
  list-style: decimal !important;
  font-size: 16px;
  margin-bottom: 5px;
}

.download-apk-grey h4:after {
  content: "";
  height: 3px;
  display: block;
  width: 50px;
  background: #1e6da2;
  margin-top: 10px;
}

.download-apk-grey ol {
  padding-left: 24px;
}

.download-apk-grey .download-apk-button {
  display: block;
  width: 250px;
  max-width: 100%;
  margin: auto;
  margin-top: 15px;
}
h4.scanner-features-title {
  color: #005252;
  font-family: "RobotoB";
  font-weight: 800;
  font-size: 21px;
  margin-top: 24px;
  margin-bottom: 24px;
}
.bottom-line-row {
  padding-bottom: 50px;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 50px !important;
}
.scanner-features-container h3 {
  font-size: 25px 45px;
  margin-bottom: 18px;
  font-family: "RobotoR";
  font-weight: 800;
}
.scanner-features-container {
  margin-bottom: 60px;
}
.services-detail-row {
  margin-bottom: 30px;
}
span.qr-service-note {
  display: table;
  font-size: 14px;
  margin-top: 4px;
  color: #007272;
}
.scanner-features-container img {
  width: 130px;
  float: left;
  padding-right: 20px;
}
.qr-services-detail h4 {
  font-size: 20px;
  font-weight: 800;
  color: #005252;
}
.qr-services-detail a {
  display: table;
}
.qr-services-detail a .arrow {
  border: solid #337ab7;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-bottom: 1px;
}

.overview-intro0 {
  margin-bottom: 20px;
  margin-top: 30px;
  font-size: 18px;
  text-align: center;
}

.overview-item-content {
  border: 1px solid #ddd;
  padding: 30px 20px;
  text-align: center;
  border-radius: 18px;
  box-shadow: 0px 5px 12px #eeeeee;
  font-size: 16px;
  margin-bottom: 32px;
  background-color: #fff;
}

.overview-intro0 img {
  width: 600px;
  max-width: 100%;
  display: block;
  margin: auto;
  margin-bottom: 25px;
}

.challenge-item h4,
.overview-item-content h4,
.ehealth-3col-item-content h4 {
  font-size: 19px;
  color: #1e5672;
  font-weight: 900;
  margin-top: 18px;
  margin-bottom: 12px;
}

.ehealth-3col-item-content h4 {
  color: #237b70;
}

.life-overview-3col img,
.ehealth-3col-item-content img {
  width: 200px;
  margin: auto;
  margin-bottom: 20px;
  display: block;
  transform: none !important;
}
.remarks-text {
  font-size: 14px;
  color: #007272;
  display: block;
  margin-top: 5px;
}
.enrolment-row {
  font-size: 15px;
  margin-left: -30px;
  margin-right: -30px;
}
.enrolment-row h4 {
  font-size: 20px;
}
.enrolment-title {
  text-align: center;
  margin-top: 14px;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 20px;
  color: #1e5672;
  // font-family: "RobotoB";
}
img.step-10000-main-img {
  display: block;
  margin: auto;
  width: 300px;
  margin-bottom: 30px;
  max-width: 100%;
}

.life-overview-3col-intro:before {
  content: "";
  width: 50px;
  height: 4px;
  display: block;
  background: #194b74;
  margin: auto;
  margin-bottom: 20px;
}

.life-overview-3col-intro {
  font-size: 18px;
  text-align: center;
  width: 640px;
  max-width: 100%;
  margin: auto;
  margin-bottom: 20px;
  color: #194b74;
}
#overview-park-container .elife-box-content.overview-leaderboard-award-box {
  margin-top: 20px;
}
.container.how-to-use-elife {
  margin-top: 200px;
  margin-bottom: 80px;
  font-size: 18px;
  text-align: center;
}
.container.how-to-use-elife .elife-box-content {
  padding-bottom: 60px;
}
.life-section-title {
  color: #1e5672;
  border: 2px solid #3e8fb8;
  display: table;
  padding: 18px 45px;
  border-radius: 100px;
  box-shadow: 3px 6px 0px #3e8fb8;
  margin: auto;
  background: #fff;
  position: relative;
  margin-top: -40px;
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 26px;
}

.life-section-title:after {
  content: "";
  background: url(../images/title-baby-deco.png);
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -72px;
  width: 100px;
  height: 83px;
  background-size: cover;
}

.life-section-title.lion-deco:after {
  background-image: url(../images/elife/title-lion-deco.png);
  top: -96px;
  width: 130px;
  height: 108px;
}

div#overview-park-container,
div#overview-step-challenge-container {
  margin-top: 180px;
  background: linear-gradient(#eaf6ff, #ffffff);
  font-size: 16px;
}

.overview-park-intro {
  font-size: 18px;
}

.game-mode-title {
  font-size: 22px;
  color: #1e5672;
  font-weight: 600;
  margin-bottom: 10px;
}

.game-mode-sub-title {
  font-size: 18px;
}

.challenge-title img {
  width: 100px;
  margin-bottom: 10px;
}

.challenge-item img {
  width: 240px;
}

.life-overview-button:nth-child(1),
.life-overview-button:nth-child(3) {
  margin-right: 1%;
}

.life-overview-button:nth-child(2),
.life-overview-button:nth-child(4) {
  margin-left: 1%;
}

.life-overview-button {
  padding: 15px;
  border: 1px solid #9dcbe0;
  border-radius: 8px;
  margin-top: 20px;
  width: 49%;
  font-size: 20px;
  font-weight: 600;
}

.life-overview-button a {
  color: #1e5672;
  display: block;
  text-align: left;
}

.challenge-item {
  font-size: 17px;
  margin-top: 20px;
}

img.life-overview-button-arrow {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
}

img.life-overview-button-img {
  width: 60px;
  margin-right: 10px;
}

.static-page-with-banner {
  padding-top: 110px;
}

.elife-box-content {
  background: #fff;
  padding: 60px 60px 0px 60px;
  border: 1px solid #9dcbe0;
  border-radius: 8px;
}

#overview-park-container .elife-box-content {
  text-align: center;
  margin-top: 40px;
  padding: 60px;
}

.page-elife-coins .elife-box-content {
  padding: 0px 60px 0px 60px;
}

.page-elife-coins .elife-box-content h5 {
  line-height: 1.6em;
  margin-bottom: 0px;
  margin-top: 20px;
}

.page-elife-coins .elife-box-content h3 {
  margin-top: 5px;
}

.page-elife-coins .hero-banner {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #eaf7ff;
  margin-top: 100px;
}
.hero-banner {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #eaf7ff;
  margin-top: 100px;
}
img.tutorial-qr {
  width: 200px;
}

.life-tutorial-phone-intro {
  margin-bottom: 30px;
}

.life-download-btn a {
  margin-bottom: 10px;
  display: block;
}

.tutorial-qr-col {
  padding-left: 0px;
  border-right: 1px solid #ddd;
}

.elife-box-content h3 {
  color: #1e5672;
  font-weight: 600;
  font-size: 26px;
}

.elife-box-content h3:after {
  content: "";
  display: block;
  width: 50px;
  height: 5px;
  background: #3da2db;
  margin-top: 15px;
  margin-bottom: 15px;
}

.elife-box-content h5 {
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 20px;
}

.life-tutorial-page h2.life-section-title {
  margin-bottom: -30px;
}

.earn-point-table {
  display: table;
  margin: auto;
  border-collapse: collapse;
  width: 100%;
  font-size: 16px;
}

.earn-point-table td,
.earn-point-table th {
  border: 1px solid #d0d9de;
  text-align: left;
  padding: 12px 8px;
}

.earn-point-table img {
  max-width: 300px;
}

.mobile-collectible-box img {
  margin-bottom: 10px;
}

.mobile-collectible-box .collectible-level {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}

.mobile-collectible-box {
  padding: 20px;
  border: 1px solid #aaa;
  margin-bottom: 20px;
  border-radius: 7px;
}

.collectible-lv-up-conditions {
  font-size: 16px;
}

.life-earnpoint-table-container h3 {
  color: #1e5672;
  font-weight: 600;
  margin-bottom: 10px;
}
.life-earnpoint-table-container .mission-des {
  font-size: 16px;
  margin-bottom: 10px;
}
.earn-point-table tr:nth-child(odd) {
  background-color: #f4fbff;
}

.life-earnpoint-table-container {
  margin-top: 40px;
  margin-bottom: 40px;
}

.point-remarks b {
  font-weight: 700;
  font-size: 18px;
  display: block;
}
.point-remarks {
  padding: 12px;
  margin-bottom: 10px;
  background: #ffe4b2;
  display: block;
}

.earn-point-table th {
  background: #2e5b74;
  color: #fff;
}

a.leaderboard-link,
a.elife-general-button {
  display: table;
  margin: auto;
  padding: 15px 30px;
  background: #1e5672;
  color: #fff;
  border-radius: 5px;
  margin-top: 20px;
}
.ranking-stop-update p {
  background: #fff;
  display: table;
  margin: auto;
  margin-bottom: 30px;
  margin-top: 80px;
  padding: 30px 50px;
  font-size: 20px;
  text-align: center;
  border-radius: 15px;
  position: relative;
  color: #063c57;
  border: 3px solid #1e75a0;
  box-shadow: 0px 4px 3px #d7e7f0;
}

.ranking-stop-update p:before {
  content: "";
  background: url(../images/title-baby-deco.png);
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -72px;
  width: 100px;
  height: 83px;
  background-size: cover;
}
div.appt-service {
  background: none !important;
  margin-top: -200px;
  margin-bottom: 100px;
}
div.appt-service .container {
  background: #fff;
  border: 1px solid #dedee9;
  border-radius: 20px;
  padding: 30px;
}
p.appt-service-title {
  font-size: 18px;
  background: #ffd998;
  padding: 8px 24px;
  border-radius: 100px;
  display: table;
  font-weight: 700;
  font-family: "RobotoR", "SourceHanSansCNR", "Arial", "Microsoft YaHei";
  font-weight: 800;
}
p.appt-service-name {
  color: #007272;
  font-size: 18px;
  font-family: "RobotoR", "SourceHanSansCNR", "Arial", "Microsoft YaHei";
  font-weight: 800;
  margin-bottom: 4px;
}
p.appt-service-detail {
  font-size: 16px;
  margin-bottom: 18px;
  color: #444;
}

a.elife-general-button:hover {
  background: #154056;
  text-decoration: none !important;
}

.overview-hero-banner {
  position: relative;
}

div#overview-download-btn {
  position: absolute;
  bottom: 20%;
  left: 53%;
}

#overview-download-btn img {
  width: 7vw;
}

.release-date-reminder {
  border-bottom: 2px solid #dfc198;
  padding: 12px 27px;
  display: table;
  margin: auto;
  margin-bottom: 40px;
  background: #ffe4b2;
  font-weight: 600;
  font-size: 22px;
}
.elife-box-content .release-date-reminder {
  margin: 0px;
}
.coins-gadget-table {
  padding: 12px;
  background: #f4fbff;
  margin: 0;
  margin-top: 20px;
  border: 1px solid #cad9e2;
  font-size: 16px;
}

.coins-gadget-table img {
  width: 350px;
  max-width: 100%;
}
.overview-video {
  margin-top: 30px;
}
.trial-launch-container div {
  background: #e7f6ff;
  padding: 18px;
  margin-top: 20px;
  font-size: 18px;
}
span.limited-event-tag {
  padding-left: 16px;
  color: #676767;
  margin-top: 5px;
  margin-bottom: 2px;
  display: block;
  font-size: 14px;
}

.separate-menu-item {
  padding-top: 10px;
  margin-top: 10px !important;
  border-top: 1px solid #eee;
}
li.separate-menu-item a {
  line-height: 1.35em;
}
.overview-step-intro-container h2.life-section-title.lion-deco {
  margin: auto;
  text-align: center;
  margin-top: -70px;
}
.overview-step-intro-container {
  margin-top: 150px;
  margin-bottom: 50px;
  background: #fafafa;
  padding: 35px;
  border-radius: 16px;
}

.overview-step-intro-container h2.life-section-title.lion-deco br {
  display: none;
}
.dropdown-menu > li.no-active-status > a.active {
  background: #fff !important;
  color: #333;
  font-weight: 400;
}
#overview-step-challenge-container .col-md-12 .game-mode-title {
  margin-top: 30px;
  margin-bottom: 30px;
}
.youtube-after-content {
  margin-top: 10px;
  margin-bottom: 10px;
  float: left;
}
div#overview-step-challenge-container .elife-box-content {
  padding: 20px;
  text-align: left;
}
#overview-step-challenge-container .container.life-overview-3col {
  max-width: 100%;
  padding: 0;
}
#overview-step-challenge-container .overview-item-content {
  font-size: 13px;
}
.elife-box-content.video-accordion h3:after {
  display: none;
}
.elife-box-content.video-accordion div.col-md-12 {
  display: flex;
  align-items: center;
}
.step-download-row {
  padding-left: 15px;
  padding-right: 15px;
}
.accordion-content {
  float: left;
  margin-top: 20px;
}
div#faq-question-container-step-challenge-cert
  .second-youtube-container.youtube-after-content {
  display: none !important;
}
.accordion-video-title {
  float: left;
  font-size: 18px;
  margin-top: 20px;
  color: #1e5672;
}
.workplace-enrol-box,
.individual-enrol-box {
  height: 320px;
  margin-bottom: 30px;
}

html[lang="en"] .workplace-enrol-box,
html[lang="en"] .individual-enrol-box {
  height: 400px;
}
div.my-family-orange-section {
  background: #fff5e7 !important;
}
div.my-family-orange-section:nth-child(2n) {
  background: #fffbf6 !important;
}
.my-family-orange-section h2.life-section-title {
  margin-top: 120px;
  border-color: #ffba57;
  box-shadow: 3px 6px 0px #db7415;
  color: #db7415;
}
.campaign-banner-container {
  background: #fdedd4;
}
.campaign-banner-container img {
  width: 950px;
  max-width: 100%;
  margin: auto;
  cursor: pointer;
}
h2.life-section-title.no-deco {
  margin-top: 32px;
}
.birth-cert-registration-step {
  margin-bottom: 30px;
}
.col-sm-3.birth-cert-step {
  text-align: center;
  margin-bottom: 16px;
  font-size: 16px;
}
.birth-cert-step img {
  background: #fff3e1;
  width: 340px;
  max-width: 100%;
  border-radius: 15px;
  display: block;
  margin: auto;
  margin-bottom: 16px;
}
.birth-cert-reminder-container img {
  max-width: 150px;
  margin: auto;
  display: block;
}
.add-family-title img {
  width: 140px;
  margin-right: 20px;
}
.add-family-title {
  color: #db7415;
  font-size: 20px;
  font-family: "RobotoB";
  font-weight: 600;
}
div#add-family-detail-container {
  padding: 0px;
}
.my-family-orange-section h2.life-section-title:after {
  content: "";
  background-image: url(../images/title-child-deco.png);
  top: -93px;
  width: 110px;
  height: 91px;
}
.my-family-orange-section h2.life-section-title.add-member-lady-deco {
  margin-top: 40px;
}
.my-family-orange-section h2.life-section-title.add-member-lady-deco:after {
  content: "";
  background-image: url(/static/media/add-family-title-deco.64128741.png);
  top: auto;
  left: -100px;
  transform: translateY(-50%);
  height: 127px;
}
.birth-cert-reminder {
  background: #ffd184;
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 20px;
  position: relative;
}
.birth-cert-reminder:after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 209, 132, 0);
  border-right-color: #ffd184;
  border-width: 20px;
  margin-top: -20px;
}
.birth-cert-other-method {
  text-align: center;
  display: block;
}
.birth-cert-other-method a {
  display: table;
  background: #db7415;
  margin: auto;
  color: #fff;
  padding: 10px 24px;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 16px;
}
.expand-panel-content {
  display: none;
  margin-top: 16px;
}
.add-member-method-box {
  background: #fff;
  border: 1px solid #ffdfc2;
  border-radius: 12px;
  font-size: 17px;
  margin-bottom: 10px;
}
.expand-panel-container .expand-panel-title {
  font-family: "RobotoB";
  padding: 20px;
  cursor: pointer;
  position: relative;
}
.expand-panel-title img.faq-expand-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  width: 20px;
}
img.add-family-method-icon {
  width: 60px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.expand-panel-container .expand-panel-title h4 {
  padding-left: 70px;
}
.my-family-caree-container {
  font-size: 16px;
  padding-bottom: 50px;
}
.expand-panel-content.active {
  display: block;
  padding: 0px 40px 20px 20px;
}
.expand-panel-content img {
  width: 400px;
  max-width: 100%;
}
.expand-panel-content .row {
  margin-bottom: 30px;
}
.step-number {
  display: table;
  width: 35px;
  height: 35px;
  background: #db7415;
  color: #fff;
  text-align: center;
  border-radius: 100px;
  line-height: 35px;
  margin-bottom: 12px;
}
.add-family-section {
  margin-top: 30px;
  margin-bottom: 50px;
}
.elife-box-content.video-accordion h3 {
  color: #1e5672;
  font-weight: 600;
  font-size: 22px;
  margin-top: 10px;
  margin-left: 16px;
  text-align: left;
  width: 100%;
}
.elife-box-content.video-accordion {
  margin-top: 20px;
  padding: 16px !important;
}
img.step-challenge-enrol-icon {
  display: block;
  width: 80px;
  margin-bottom: 10px;
}
img.step-accordion-icon {
  width: 60px;
}
div#overview-step-challenge-container .overview-item-text {
  height: 75px;
}
.white-floating-container {
  padding: 25px;
  border-radius: 18px;
  box-shadow: 0px 5px 12px #eeeeee;
  font-size: 16px;
  background-color: #fff;
  margin-bottom: 30px;
}
.terms-of-use-container .faq-answer h4 {
  color: #00436b;
  font-family: "RobotoR", "SourceHanSansCNR", "Arial", "Microsoft YaHei";
  font-weight: 600;
}
.terms-of-use-container .faq-answer ol {
  padding-left: 25px !important;
  margin-bottom: 26px;
}
.terms-of-use-container .faq-answer ol li {
  list-style: auto !important;
  margin-bottom: 14px;
  color: #333;
}
.terms-of-use-container .faq-answer h5 {
  color: #444;
  font-size: 17px;
  text-decoration: underline;
  margin-top: 20px;
}

a.scroll-anchor {
  display: block;
  position: relative;
  top: -50px;
  visibility: hidden;
}
.tabs {
  display: inline-flex;
  background-color: #1f75a0;
  border-radius: 55px;
  margin: 40px auto 30px;

  @media (max-width: 768px) {
    margin: 17px auto 20px;
  }

  .tab {
    padding: 14px 40px;
    border: 3px solid #1f75a0;
    cursor: pointer;
    transition: all 0.2s;
    color: #fff;
    font-size: 18px;
    border-radius: 55px;

    @media (max-width: 768px) {
      font-size: 16px;
      padding: 8px 30px;
    }

    &.active {
      background-color: #fff;
      font-weight: 700;
      color: #1e5672;
    }
  }
}

.tab-content {
  > * {
    display: none;

    & .active {
      display: block;
    }
  }
}

.row.tabs-row {
  text-align: center;
}

@media (max-width: 1580px) {
  .promotion-logo {
    width: 85px;
    float: left;
  }

  .promotion-nav .promotion-logo img {
    width: 70px;
    margin-top: 10px;
  }

  .header-menu-container .header-menu-item {
    margin-right: 20px;
    font-size: 15px;
  }
  .promotion-nav .main-view {
    padding: 0px 23px;
  }

  .header-menu-container .header-menu-item span.arrow-down {
    margin-left: 0px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 9px solid #107cc1;
  }

  .promotion-nav .change-language .each-language div {
    font-size: 15px;
  }

  .promotion-nav .change-language .each-language {
    margin: 0px;
  }

  .change-language {
    padding-top: 8px;
  }
}

@media (min-width: 1550px) and (max-width: 1800px) {
  .privacy-header-text-col {
    margin-left: -5vw;
  }
}

@media (min-width: 1201px) and (max-width: 1550px) {
  .privacy-header-text-col {
    margin-left: -9vw;
  }

  .static_page_header_privacy .static_page_header_bg {
    background-position: 130% 100% !important;
    background-size: 65% !important;
  }

  .static_page_header_privacy {
    padding-top: 4%;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .privacy-header-text-col {
    margin-left: -3vw;
  }

  .static_page_header_privacy .static_page_header_bg {
    background-position: 130% 100% !important;
    background-size: 65% !important;
  }

  html[lang="en"] .static_page_header_privacy p.static_page_top_title {
    font-size: 40px;
    line-height: 1.2em;
  }

  .static_page_header_privacy p span {
    font-size: 22px;
    width: 90%;
  }
}

@media (min-width: 769px) and (max-width: 1799px) {
  .privacy-photo {
    width: 600px;
    max-width: 100vw;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  p.privacy-case-content {
    width: 430px;
    margin-top: -105px;
    font-size: 17px;
  }

  .privacy-tip-content {
    font-size: 16px;
    width: 47%;
  }
}

@media (min-width: 769px) and (max-width: 1260px) {
  .promotion-nav .promotion-logo img {
    width: 80px;
    margin-top: 6px;
  }

  .promotion-logo {
    width: 110px;
  }

  .arrow-down {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 9px solid #107cc1;
    margin-left: 3px;
  }

  .header-menu-container .header-menu-item {
    font-size: 16px;
    margin-right: 15px;
  }

  .static_page_header_privacy {
    margin-top: 0px;
  }
}

@media (max-width: 1084px) {
  .static_page_header_download {
    margin-top: 66px;
  }
}

@media (max-width: 850px) {
  .background-item-download span {
    font-size: 16px;
    display: table;
    position: relative;
    margin-top: 10px;
  }

  .background a.item-download {
    padding: 7px 5px;
    width: 31.3%;
    margin-right: 2%;
    margin-left: 0px;
    text-align: center;
  }
}

@media (max-width: 1200px) {
  .desktop-show-hide-tab {
    display: none;
  }
  .tablet-show {
    display: block;
  }
  .game-mode-sub-title br {
    display: none;
  }
  #overview-download-btn .download-btn-row {
    float: none;
    margin-top: 20px;
  }
  .download-btn-row .overview-qr {
    height: auto;
    width: 160px !important;
    margin: auto;
    margin-bottom: 20px;
  }

  #overview-download-btn a {
    width: auto;
    float: none;
    display: contents;
  }
  div#overview-download-btn {
    position: initial;
    margin-bottom: 0;
    text-align: center;
  }
  #overview-download-btn img.overview-download-button {
    width: auto !important ;
    margin-bottom: 10px;
    height: 45px;
  }
  .second-download-now a {
    display: block;
    text-align: center;
    width: 100%;
  }

  .second-download-now img {
    width: auto;
    margin-right: 10px;
    height: 45px;
  }
}

@media (max-width: 992px) {
  img.step-accordion-icon {
    width: 55px;
  }

  .elife-box-content.video-accordion div {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .elife-box-content.video-accordion h3 {
    font-size: 18px;
  }

  div#overview-step-challenge-container .elife-box-content {
    padding: 16px;
  }

  .workplace-enrol-box,
  .individual-enrol-box {
    height: auto !important;
  }

  html[lang="en"] .workplace-enrol-box,
  html[lang="en"] .individual-enrol-box {
    height: auto !important;
  }

  #overview-step-challenge-container .life-download-btn {
    padding: 0px;
    margin-top: 15px;
  }
  .static-page-with-banner {
    padding-top: 65px;
  }

  .epp-privacy-container {
    width: 100%;
  }

  .epp-privacy-container .faq-question {
    font-size: 22px;
  }

  .faq-answer {
    padding-right: 0px;
  }

  img.step-horizontal-arrow {
    position: relative;
    transform: rotate(90deg);
    right: 0;
    top: 12px;
    margin-top: 15px;
    width: 32px;
  }

  .overview-intro0 img {
    width: 400px;
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .expand-panel-content.active {
    padding: 0px 20px;
    margin-top: 0;
  }
  .birth-cert-step img {
    max-width: 70%;
  }
  .my-family-orange-section h2.life-section-title {
    padding: 15px 35px;
  }
  .my-family-orange-section h2.life-section-title.add-member-lady-deco:after {
    left: -60px;
    height: 115px;
  }
  .add-family-title img {
    display: block;
    margin: auto;
    margin-bottom: 0px;
  }
  .add-family-title {
    text-align: center;
    margin-bottom: 20px;
  }
  img.add-family-method-icon {
    display: none;
  }
  .expand-panel-container .expand-panel-title h4 {
    padding-left: 0;
    margin-right: 45px;
  }
  .birth-cert-reminder:after,
  .birth-cert-reminder-container img {
    display: none;
  }
  span.limited-event-tag {
    padding-left: 0px;
    font-weight: 400;
    margin-bottom: -5px;
    margin-top: 0px;
  }
  .trial-launch-container div br {
    display: none;
  }
  .page-elife-coins .elife-box-content {
    padding: 0 10px;
  }
  .page-elife-coins .elife-box-content h3 {
    margin-top: 5px;
  }
  .life-earnpoint-table-container .col-lg-6.col-sm-12,
  .life-earnpoint-table-container .col-lg-12.col-sm-12 {
    padding: 0px;
  }
  .collectible-Tier {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .release-date-reminder {
    font-size: 18px;
  }

  .overview-park-intro {
    text-align: center;
  }

  .life-tutorial-phone img {
    max-width: 300px;
    display: block;
    margin: auto;
    margin-bottom: 40px;
  }

  .elife-box-content h3 {
    text-align: center;
    font-size: 26px;
  }

  .elife-box-content h3:after {
    margin-left: auto;
    margin-right: auto;
  }

  .elife-box-content h5 {
    font-size: 16px;
    text-align: center;
  }

  img.tutorial-qr {
    margin: auto;
    display: block;
  }

  div.tutorial-qr-col {
    padding: 0;
    border: 0;
  }

  .life-download-btn {
    margin-top: 20px;
  }

  .life-download-btn img {
    width: 200px;
    max-width: 100%;
    display: block;
    margin: auto;
  }

  .elife-box-content {
    padding: 0;
  }
  .container.how-to-use-elife .elife-box-content {
    padding: 15px;
    padding-top: 60px;
  }

  .life-tutorial-page .static_page_header.static_page_header_download {
    margin-bottom: 100px;
  }

  .main-title-text br {
    display: none;
  }

  .main-title-text {
    font-size: 2.6rem;
  }

  .download-apk-container {
    margin-top: 100px;
  }

  .download-apk-blue,
  .download-apk-grey {
    padding: 30px;
  }

  span.animation-episode-name {
    font-size: 16px;
  }

  span.animation-episode {
    font-style: 14px;
  }

  img.animation-episode-icon {
    width: 55px;
  }

  .animation-episode-info {
    padding-bottom: 10px;
  }

  .download-item.space-for-btn a.item-download {
    position: inherit;
    display: table !important;
    margin-top: 10px;
  }

  .static_page_header_download {
    margin-top: 35px;
  }

  .privacy-tip-row-3col .col-sm-4 {
    width: 100%;
  }

  .static_page_header_download.static_page_header img {
    max-width: 100vw;
  }

  .static_page_header_download {
    margin-bottom: 0px;
  }

  .download-item {
    width: 95vw;
    margin: auto;
    margin-bottom: 20px;
  }

  .download-item.background {
    max-width: 500px;
    width: 90%;
    margin: auto;
    margin-bottom: 30px;
  }

  .background-item-download {
    padding: 13px 20px;
  }

  .sticker-set a.item-download {
    bottom: 50px;
    font-size: 16px;
    padding: 20px 30px;
  }

  .col-sm-12.download-banner-graphic {
    padding: 0px;
    margin-top: -10px;
  }

  .static_page_header_download.static_page_header img,
  .static_page_header_download .static_page_header_bg {
    width: 110%;
    max-width: 110%;
    margin-left: -5%;
  }

  p.privacy-case-content,
  html[lang="en"] p.privacy-case-content {
    max-width: 100%;
    margin-top: -3%;
    margin-bottom: 30px;
    font-size: 17px;
  }

  .privacy-reminder-img {
    z-index: 1;
    float: left;
  }

  div.second-feature-section .privacy-reminder-img img {
    position: absolute;
    left: -20px;
    width: 110px;
    margin-top: -10px;
  }

  .reminder-speak-bubble {
    padding: 20px;
    text-align: left;
    padding-left: 50px;
    padding-right: 8px;
  }

  .col-sm-6.privacy-header-text-col {
    margin: auto;
  }

  .static_page_header_privacy {
    padding-top: 50px;
    margin-bottom: 0px;
  }

  .static_page_header_privacy .static-page-header-left {
    background: none;
    margin: inherit;
    width: 100%;
    max-width: 100%;
    padding: 0px !important;
    margin: 0px;
  }

  .static_page_header_privacy .static-page-header-left img {
    max-width: 100vw;
    margin-bottom: 0;
  }

  .static_page_header_privacy p {
    text-align: center;
  }

  .static_page_header_privacy p span {
    font-size: 22px;
    width: 90%;
    margin-left: 5%;
    margin-top: 5px;
    margin-bottom: 45px;
  }

  .static_page_header_privacy p {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .static_page_header_privacy .container .row {
    height: auto !important;
    overflow: hidden;
    max-height: initial;
  }

  .privacy-title {
    font-size: 23px;
    line-height: 1.2em;
  }

  .privacy-text {
    font-size: 17px;
  }

  .privacy-photo img {
    width: 150%;
  }

  .reminder-speak-bubble {
    padding: 18px;
    padding-left: 30px;
  }

  .privacy-tip-img {
    width: 25%;
    min-width: 140px;
  }

  .privacy-tip-content {
    width: 83%;
    margin-left: -100px;
    margin-top: 6px;
    padding-right: 35px;
    margin-right: 0;
    min-height: 118px;
    display: table;
  }

  .privacy-tip-content.tip-long {
    width: 83%;
  }

  .privacy-tip-content span {
    display: table-cell;
    vertical-align: middle;
  }

  .reminder-speak-bubble b {
    font-size: 21px;
    line-height: 1.2em;
  }

  .reminder-speak-bubble span {
    font-size: 17px;
  }

  .second-feature-section .privacy-reminder img {
    left: 0%;
    transform: translateX(0%) !important;
  }

  .privacy-tip-row-3col .col-sm-4 img {
    width: 200px;
  }

  .privacy-tip-row-3col .col-sm-4 {
    padding: 0px 0px 30px 0px;
  }

  .privacy-tip-row-3col .col-sm-4 p {
    margin-top: 10px;
    font-size: 18px;
  }

  .privacy-tip-row-3col {
    margin-top: 40px;
    display: table;
    float: left;
  }

  .privacy-tip-row-3col .col-sm-4 {
    width: 50%;
    float: left;
  }

  img.privacy-title-icon {
    transform: none !important;
    width: 30px;
    margin-right: 3px;
  }
}

@media (max-width: 550px) {
  .download-item .sticker-item-preview {
    width: 33.3%;
    padding: 10px;
  }
}

@media (max-width: 500px) {
  .static_page_header_privacy p {
    font-size: 25px;
    margin-bottom: 10px;
  }

  .sticker-set a.item-download {
    padding: 15px 20px;
    right: 25px;
  }

  .download-item {
    width: 90vw;
    padding-left: 20px;
    padding-right: 20px;
  }

  .sticker-item-title {
    font-size: 20px;
  }

  .static_page_header_download.static_page_header img,
  .static_page_header_download .static_page_header_bg {
    width: 150%;
    max-width: 150%;
    margin-left: -25%;
  }

  .static_page_header_privacy p span {
    font-size: 22px;
  }

  html[lang="en"] .static_page_header_privacy p.static_page_top_title {
    font-size: 30px !important;
  }

  .static_page_header_privacy p span {
    font-size: 20px;
  }

  //no margin after apply this style
  .privacy-reminder {
    //padding: 0;
  }

  .privacy-tip-row {
    margin-left: 0px;
  }

  .privacy-tip-content {
    width: 88%;
    font-size: 17px;
    text-align: left;
    min-height: 104px;
    margin-top: 16px;
  }

  .privacy-tip-content.tip-long {
    width: 88%;
  }

  .privacy-tip-content br {
    display: none;
  }
}

@media (max-width: 450px) {
  .privacy-tip-img {
    min-width: 125px;
    margin-top: 4px;
  }

  .privacy-tip-content {
    width: 90%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 110px;
    font-size: 16px;
    min-height: 95px;
  }
}

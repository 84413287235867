.page-elife-parklist {

    .display-desktop {
        display: block;
    }

    .display-mobile {
        display: none;
    }

    @media (max-width: 768px) {
        .display-desktop {
            display: none;
        }

        .display-mobile {
            display: block;
        }
    }

    .hero-banner {
        position: relative;
        display: flex;
        justify-content: center;
        background-color: #eaf7ff;
        margin-top: 100px;

        @media (max-width:1259px) {
            margin-top: 65px;
        }

        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 50%;
        }

        .display-desktop {
            display: block;
        }

        .display-mobile {
            display: none;
        }

        @media (max-width: 768px) {
            .display-desktop {
                display: none;
            }

            .display-mobile {
                display: block;
                width: 100vw;
            }
        }
    }

    .container-fluid {
        @media (max-width: 768px) {
            background: linear-gradient(180deg, #EAF8FF 0%, #FFFFFF 100%);
        }

        .main-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .search {
                width: 100%;
                margin-bottom: 28px;

                .title {
                    font-size: 18px;
                    color: #222;
                    margin-bottom: 8px;
                }

                .input {
                    border: 1px solid #aaa;
                    border-radius: 2px;
                    padding: 9px 12px;
                    display: flex;
                    align-items: center;
                    background: #fff;

                    input,
                    form {
                        border: none;
                        flex: 1;
                        width: 100%;

                        &:focus {
                            outline: none;
                        }
                    }
                }

                .ico-search,
                .ico-clear {
                    cursor: pointer;
                    margin-left: 5px;
                }
            }

            .tabs {
                display: inline-flex;
                background-color: #1F75A0;
                border-radius: 55px;
                margin: 30px auto;

                @media (max-width: 768px) {
                    width: 100%;
                    justify-content: space-between;
                    margin: 23px auto 16px;
                }

                .tab {
                    padding: 14px 40px;
                    border: 3px solid #1F75A0;
                    cursor: pointer;
                    transition: all 0.2s;
                    color: #fff;
                    font-size: 18px;
                    border-radius: 55px;

                    @media (max-width: 768px) {
                        font-size: 16px;
                        padding: 8px 0;
                        width: 33%;
                        text-align: center;
                    }

                    &.active {
                        background-color: #fff;
                        font-weight: 700;
                        color: #1E5672;
                    }
                }
            }

            .items {
                background-color: #fff;
                border: 1px solid #CBECFF;
                border-radius: 5px;
                box-shadow: 0px 4px 4px 0px #0000000D;
                padding: 0 24px;
                width: 100%;
                margin: 0 auto;

                .no-result {
                    padding: 32px 0;
                    text-align: center;
                    font-size: 18px;
                    color: #222;
                }

                .item {
                    padding: 12px 0;
                    border-bottom: 1px solid #ccc;
                    display: flex;
                    align-items: center;
                    font-size: 18px;

                    &:last-child {
                        border-bottom: none;
                    }

                    .name {
                        font-weight: 'bold';
                    }

                    .address {
                        color: #888;
                    }
                }

                @media (max-width: 768px) {
                    padding: 0 16px;

                    .item {
                        font-size: 16px;
                        flex-direction: column;
                        align-items: flex-start;

                        .name {
                            width: 100%;
                            margin-bottom: 8px;
                        }

                        .address {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .footer-image {
        width: 100%;
        max-width: 1099px;
        margin: 40px auto 0;
    }
}
.life-redemption-page {
  .hero-banner {
    position: relative;
    display: flex;
    justify-content: center;
    background-color: #eaf7ff;
    margin-top: 100px;
  }

  h2.life-section-title {
    border-radius: 8px;
    text-align: center;
    font-size: 21px;
    line-height: 1.4em;
    margin-top: 80px;
  }
  img.redemption-graphic {
    display: table;
    width: 700px;
    max-width: 100%;
    margin: auto;
    margin-top: 40px;
  }
  .gift-redemption-intro {
    text-align: center;
    font-size: 16px;

    b {
      font-family: "RobotoB", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
  }

  .gift-redemption-intro-container {
    padding-top: 60px;
    .redemption-time-square-container {
      background: #ebf8fc;
      text-align: center;
      padding: 32px;
      margin-bottom: 50px;
      border: 1px solid #9dcbe0;
      border-radius: 4px;
      position: relative;

      img.wonderland-coin-graphic {
        width: 70px;
        position: absolute;
        left: -35px;
        top: 50%;
      }
      img.wonderland-ball-graphic {
        width: 80px;
        position: absolute;
        right: 5%;
        top: -40px;
      }
      img.wonderland-family-graphic {
        position: absolute;
        bottom: 0;
        width: 200px;
        right: 0;
      }
      span.time-limited-tag {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -22px;
        background: #2883b1;
        color: #fff;
        padding: 10px 15px;
        font-size: 16px;
      }

      .wonderland-logo {
        width: 220px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .time-square-details {
        font-size: 16px;
        width: 500px;
        max-width: 100%;
        margin: auto;
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;

        strong {
          font-family: "RobotoB", "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-weight: 600;
        }
      }

      @media (min-width: 1800px) {
        .time-square-details {
          width: 600px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .gift-redemption-intro-container {
      padding-left: 0px;
      padding-right: 0px;

      .redemption-time-square-container {
        padding: 24px 24px 0px 24px;

        img.wonderland-family-graphic {
          position: relative;
        }
        img.wonderland-coin-graphic {
          width: 50px;
          left: -20px;
          top: 70%;
        }
        img.wonderland-ball-graphic {
          width: 50px;
          right: 5%;
          top: -20px;
        }
      }
    }
  }
  .gift-redemption-details-container {
    background: #f7fdfd;
    padding-top: 60px;
    padding-bottom: 60px;
    font-size: 16px;

    h4 {
      font-family: "RobotoB", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: 800;
    }
    .redemption-terms {
      font-size: 13px;
      color: #666;
    }
    .redemption-terms li {
      margin-bottom: 5px;
    }
    .redemption-terms strong {
      font-weight: 600;
      margin-bottom: 5px;
      display: block;
    }
    .gift-redemption-intro {
      font-size: 18px;
      color: #333;
      text-align: center;
      width: 800px;
      margin: auto;
      max-width: 100%;
      margin-bottom: 8px;
    }
    .gift-redemption-video-container {
      margin-bottom: 50px;
    }
    span.location-notice {
      color: #007272;
      border-top: 1px solid #ddd;
      width: 100%;
      display: block;
      padding-top: 16px;
    }
    .gift-single-col-content {
      background: #fff;
      text-align: center;
      border-radius: 16px;
      box-shadow: 0px 5px 10px #086e7c2b;
      color: #178a9b;
      font-size: 17px;
      margin-bottom: 30px;
      padding-bottom: 40px;
      width: 100%;
    }

    @media (max-width: 768px) {
      .gift-single-col-content {
        width: auto;
      }
    }
    img {
      width: 200px;
      max-width: 100%;
    }
    h5 {
      background-color: #016aa5;
      font-size: 18px;
      color: #fff;
      padding: 18px 20px;
      text-align: left;
      margin-top: 0;
      border-radius: 16px 16px 0px 0px;
    }
    .gift-redemption-review,
    .gift-redemption-location-list {
      background: #fff;
      border-radius: 8px;
      padding: 20px;
      box-shadow: 0px 4px 5px #086e7c14;
      margin-bottom: 40px;
      position: relative;

      .row {
        border-bottom: 1px solid #eee;
        margin: 0px;
        .location-list-name {
          font-family: "RobotoB", "Helvetica Neue", Helvetica, Arial, sans-serif;
          clear: both;
        }
        .location-list-name,
        .location-list-address {
          padding-bottom: 12px;
          padding-top: 12px;
        }

        @media (max-width: 768px) {
          .location-list-name {
            padding: 16px 0px 0px 0px;
          }
          .location-list-address {
            padding: 2px 0px 16px 0px;
          }
        }
      }
      .row.no-border-row {
        border: 0px !important;
      }
      p.gift-coming-soon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #d7faff87;
        padding: 20px 36px;
        font-size: 20px;
        color: #007272;
        border: 1px solid #76b7b8;
        border-radius: 8px;
      }
    }
    .location-list-header {
      border: 0px !important;
      padding-bottom: 10px;
      padding-top: 10px;
      color: #007272;
    }
    @media (max-width: 768px) {
      .row.location-list-header {
        display: none;
      }
    }
    img.redemption-gift-preview {
      width: 900px;
      display: block;
      margin: auto;
      max-width: 100%;
    }
  }
}

/* Slider Animation CSS */
.animated {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
   //  -webkit-animation-fill-mode: both;
   //  animation-fill-mode: both;
 }
 
 @-webkit-keyframes fadeOutLeft {
    0% {
       opacity: 1;
       -webkit-transform: translateX(0);
    }
    100% {
       opacity: 0;
       -webkit-transform: translateX(-100px);
    }
 }
 
 @keyframes fadeOutLeft {
    0% {
       opacity: 1;
       transform: translateX(0);
    }
    100% {
       opacity: 0;
       transform: translateX(-100px);
    }
 }
 
 .fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft;
 }
 
 
 @-webkit-keyframes fadeInLeft {
    0% {
       display: 0;
       -webkit-transform: translateX(100px);
    }
    100% {
       opacity: 1;
       -webkit-transform: translateX(0);
    }
 }
 
 @keyframes fadeInLeft {
    0% {
       opacity: 0;
       transform: translateX(100px);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
 }
 
 .fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
 }
 
 .hide{
    display: none;
 }
/* Slider Animation CSS */
@mixin stroke($color: #000, $size: 1px) {
  text-shadow: -#{$size} -#{$size} 0 $color, 0 -#{$size} 0 $color,
    #{$size} -#{$size} 0 $color, #{$size} 0 0 $color, #{$size} #{$size} 0 $color,
    0 #{$size} 0 $color, -#{$size} #{$size} 0 $color, -#{$size} 0 0 $color;
}

.page-privacy-game {
  .btn {
    background-color: #fff;
    padding: 10px 0;
    border: 2px solid #1c8e91;
    border-radius: 999px;
    font-size: 24px;
    font-weight: 600;
    transition: 200ms all ease-in-out;

    &:hover {
      box-shadow: 3px 3px 0 rgba(28, 142, 145, 0.6);
    }
  }

  .game-container {
    color: #333;
    width: 100%;
    background-color: #aeeae4;
    margin-top: 120px;
    min-height: calc(100vh - 120px);

    @media (max-width: 1260px) {
      margin-top: 64px;
      min-height: calc(100vh - 64px);
    }

    .popup-container {
      position: fixed;
      z-index: 99999999;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      display: none;

      .shadow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.45);
        opacity: 0;
        transition: 200ms all ease-in-out;
        cursor: pointer;
      }

      .popup {
        background-color: #fff;
        border: 3px solid #1c8e91;
        box-shadow: 3px 3px 0 #1c8e91;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        padding: 50px;
        width: 90%;
        max-width: 450px;
        opacity: 0;
        margin-top: 30px;
        transition: 200ms all ease-in-out;

        .title {
          font-size: 32px;
          font-weight: 600;
          text-align: center;
        }

        img {
          width: 90%;
          max-width: 242px;
          margin: 30px auto;
          display: block;
        }

        .content {
          text-align: center;
          font-size: 24px;
          font-weight: 400;

          .highlight {
            color: #f35d08;
            font-weight: 600;
            font-size: 30px;
          }
        }

        .btn-start {
          background-color: #f35d08;
          width: 100%;
          text-align: center;
          padding: 5px 0;
          margin: 0 auto;
          color: #fff;
          font-size: 28px;
          font-weight: 600;
          border-radius: 999px;
          margin-top: 30px;
          cursor: pointer;
          border: 0;

          &:hover {
            box-shadow: 3px 3px 0 rgba(243, 93, 8, 0.6);
          }
        }

        @media screen and (max-width: 768px) {
          padding: 30px;

          .title {
            font-size: 20px;
          }

          .content {
            font-size: 16px;

            .highlight {
              font-size: 20px;
            }
          }

          .btn-start {
            font-size: 18px;
          }
        }
      }

      &.active {
        pointer-events: all;
        display: block;

        .shadow {
          opacity: 1;
        }

        .popup {
          opacity: 1;
          margin-top: 0;
        }
      }
    }

    .stage-intro {
      position: relative;

      .title {
        text-align: center;

        font-size: 20px;
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);

        @media (max-width: 768px) {
          top: 15%;
          font-size: 14px;
        }
      }

      img.intro {
        width: 100%;

        &.desktop {
          margin: 40px auto;
        }

        &.mobile {
          display: none;
          margin: 0 auto;
          max-width: 300px;
        }

        @media (max-width: 768px) {
          &.mobile {
            display: block;
          }

          &.desktop {
            display: none;
          }
        }
      }

      .lead-in {
        text-align: center;

        text-align: center;
        font-size: 24px;

        @media (max-width: 992px) {
          font-size: 18px;
        }
      }

      .btn-start {
        border-radius: 80px;
        border: 3px solid #fff;
        background: #f35d08;
        color: #fff;
        font-size: 28px;
        margin: 30px auto;
        display: table;
        padding: 12px 104px;
        cursor: pointer;

        &:hover {
          box-shadow: 3px 3px 0 rgba(243, 93, 8, 0.6);
        }

        @media (max-width: 768px) {
          font-size: 22px;
          width: 100%;
          margin: 15px auto;
        }
      }
    }

    .stage-menu {
      .title {
        text-align: center;
        font-size: 32px;
        margin: 190px auto 95px;
        font-weight: bold;
      }

      .items {
        display: flex;
        justify-content: space-between;

        .item {
          padding: 15px 15px 25px 15px;
          background-color: #fff;
          border-radius: 20px;
          width: 30%;
          transition: all 0.2s ease-in-out;
          cursor: pointer;

          .thumbnail {
            width: 100%;
            height: 0;
            position: relative;
            padding-top: 100%;
            border-radius: 15px;
            overflow: hidden;
            border: 0;

            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              object-fit: cover;
              width: 110%;
              height: 110%;
            }
          }

          .name {
            font-size: 18px;
            text-align: center;
            margin-top: 25px;
          }

          &:nth-child(1) .thumbnail img {
            object-position: 43%;
          }

          &:nth-child(2) .thumbnail img {
            object-position: 5%;
          }

          &:nth-child(3) .thumbnail img {
            object-position: 15%;
          }

          &:hover {
            transform: scale(1.05);
            border: 3px solid #1c8e91;
            box-shadow: 3px 3px 0 #1c8e91;
          }
        }
      }

      @media (max-width: 768px) {
        .title {
          font-size: 20px;
          margin: 20px 0;
        }

        .items {
          flex-wrap: wrap;
          justify-content: center;

          .item {
            width: 100%;
            margin-bottom: 15px;
            max-width: 400px;

            .thumbnail {
              padding-top: 40%;
            }
          }
        }
      }
    }

    .stage-game {
      max-width: 1600px;
      width: 95%;

      .info {
        position: relative;
        margin-top: 50px;

        .score {
          border-radius: 99px;
          border: 3px solid #1c8e91;
          background: #fff;
          display: table;
          padding: 15px 38px;
          z-index: 9;
          position: relative;

          img {
            width: 50px;

            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }

        .time-container {
          border-radius: 60px;
          border: 3px solid #1c8e91;
          background: #fff;
          position: absolute;
          top: 50%;
          left: 230px;
          transform: translateY(-50%);
          width: calc(100% - 230px - 60px);
          height: 44px;

          .time {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            height: 18px;
            padding: 0 15px;
          }

          .progress {
            width: 100%;
            border-radius: 100px;
            background: #09d2bf;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }

        .timer {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);

          img {
            height: 100px;
          }

          .value {
            color: #096163;
            font-size: 24px;
            position: absolute;
            text-align: center;
            top: 58%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      .canvases {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;

        .canvas {
          width: 49%;
          cursor: pointer;

          canvas {
            width: 100%;
          }
        }
      }

      @media (max-width: 768px) {
        .info {
          margin-top: 15px;

          .score {
            padding: 10px 20px;

            img {
              width: 30px;
            }
          }

          .time-container {
            left: 130px;
            width: calc(100% - 130px - 30px);
          }

          .timer {
            img {
              height: 60px;
            }

            .value {
              font-size: 20px;
            }
          }
        }

        .canvases {
          flex-wrap: wrap;
          justify-content: center;
          margin-top: 10px;

          .canvas {
            width: 100%;
          }
        }
      }
    }

    .stage-result {
      & > .title {
        margin: 15px auto 0;

        img {
          max-width: 200px;
          margin: 0 auto;
          display: block;
        }
      }

      .founds {
        font-size: 20px;
        text-align: center;
        font-weight: 600;
        letter-spacing: 2px;

        .highlight {
          font-size: 36px;
          color: #f35d08;
          transform: translateY(5px);
          padding: 0 5px;
          display: inline-block;
          @include stroke(#fff, 1px);
        }
      }

      .result-text {
        width: 100%;
      }

      .btns {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;

        .btn {
          width: 30%;

          &.btn-share {
            background-color: #f35d08;
            color: #fff;
            border-color: #fff;

            &:hover {
              box-shadow: 3px 3px 0 rgba(243, 93, 8, 0.6);
            }
          }

          @media (max-width: 768px) {
            width: 32%;
            font-size: 18px;
            padding: 6px;
          }
        }
      }

      .remind {
        text-align: center;
        font-weight: 600;
        font-size: 28px;
        margin: 60px 0;

        img {
          margin-left: 5px;
        }

        @media (max-width: 768px) {
          font-size: 16px;

          img {
            width: 14px;
          }
        }
      }

      .tips {
        background-color: #fff;
        border: 2px solid #1c8e91;
        box-shadow: 3px 3px 0 #1c8e91;
        border-radius: 20px;
        padding: 0 60px;
        margin-bottom: 50px;

        .tip {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 50px 0;

          &:nth-child(even) {
            flex-direction: row-reverse;
          }

          .content {
            width: 52%;

            .title {
              font-size: 24px;
              font-weight: 600;
              margin-bottom: 24px;
            }

            .detail {
              line-height: 2;
              font-size: 16px;

              ul {
                margin-left: 15px;

                li {
                  list-style: disc;
                }
              }
            }
            .app-download {
              a {
                display: table;
                width: 29.3%;
                float: left;
                margin-left: 1%;
              }
            }
          }

          .image {
            width: 45%;

            img {
              width: 100%;
            }
          }
        }

        @media (max-width: 768px) {
          padding: 20px 10px;

          .tip {
            margin: 20px 0;
            flex-direction: column-reverse !important;

            .content,
            .image {
              width: 80%;
            }
          }
        }
      }
    }
  }
}

[lang="en"] {
  .page-privacy-game .game-container {
    .stage-result {
      .result-text .text-container .text {
        width: 60%;

        @media (max-width: 600px) {
          font-size: 10px;
          left: 42%;

          .highlight {
            font-size: 18px;
          }
        }
      }

      @media (max-width: 600px) {
        .btns {
          .btn {
            width: 32%;
            font-size: 12px;
          }
        }
      }
    }
  }
}

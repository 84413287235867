.mobileOverlay {
    display: none;
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 199;
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
    right: 0px;
    bottom: 0px;
    left: 0px;
}

@media (max-width: 1260px) {
    .header-menu-item.youtube {
        border-top: 1px solid #c3dce6;
    }

    html.open-menu {
        touch-action: none;
        overscroll-behavior: none;
        -webkit-overflow-scrolling: touch;
        overflow: hidden;

        body,
        #root {
            touch-action: none;
            overscroll-behavior: none;
            -webkit-overflow-scrolling: touch;
            overflow: hidden;
        }
    }

    /* header */

    .header {
        //background-color: rgba(255,255,255,0.2);
        //background-color: #fff;
        //box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
        position: fixed;
        width: 100%;
        z-index: 9999;
        //transform: translateZ(100px);
        max-height: 100%;
        overflow-y: auto;
        display: block;
        top: 0px;
        right: 0px;
        left: 0px;
        -webkit-overflow-scrolling: touch !important;
        overscroll-behavior-y: auto;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .header::-webkit-scrollbar {
        display: none;
    }

    .header-container {
        float: left;
        background: #fff;
        width: 100%;
        border-bottom: 1px solid #eded;
    }


    /* Safari 10.1+ (alternate method) */

    @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
            .mobile-change-language {
                // padding-bottom: 80px;
            }
        }
    }

    /* Safari 9.0 (iOS Only) */
    @supports (-webkit-text-size-adjust: none) and (not (-ms-ime-align: auto)) and (not (-moz-appearance: none)) {
        .mobile-change-language {
            // padding-bottom: 80px;
        }
    }

    /* Safari 6+7 (iOS Only) */
    @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-color-index: 0) {
        .mobile-change-language {
            // padding-bottom: 80px;
        }
    }

    /* menu */

    .header .menu {
        clear: both;
        transition: max-height 0.2s ease-out;
    }

    .header .menu a {
        font-size: 16px;
    }

    span.arrow-down {
        float: right;
    }

    a.mobile-has-dropdown {
        border-top: 1px solid #c3dce6;
    }

    /* menu btn */

    .header .menu-btn {
        display: none;
    }

    .header .menu-btn:checked~.menu-icon .navicon {
        background: transparent;
    }

    .header .menu-btn:checked~.menu-icon .navicon:before {
        transform: rotate(-45deg);
    }

    .header .menu-btn:checked~.menu-icon .navicon:after {
        transform: rotate(45deg);
    }

    .header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
    .header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
        top: 0;
    }

    header ul.dropdown-menu {
        position: initial;
        width: 100%;
        float: none;
        margin-top: 0px !important;
        box-shadow: none;
    }

    .dropdown ul.dropdown-menu a {
        background: #fff;
        border-bottom: 1px solid #ddd;
        padding: 8px 20px;
    }

    /* language */
    .each-language {
        width: 33.3%;
        float: left;
        text-align: center;
        border-right: 1px solid #ddd;
        background: #fff;
    }

    .each-language a {
        display: inline-block;
        width: 100%;
        padding-top: 15px;
    }

    .each-language.on {
        background: #75add8;
    }

    .each-language.on div {
        color: #fff;
    }

    .each-language a img {
        width: 100%;
        height: 52px;
        margin-top: -37px;
    }

    .each-language div {
        font-size: 17px;
        line-height: 1em;
        border-bottom: 1px solid #ddd;
    }

    .each-language div:focus {
        outline: none;
        stroke: none;
    }
}

/* 48em = 768px */

@media (min-width: 1260px) {
    .header {
        display: none;
    }

    .header li {
        float: left;
    }

    .header li a {
        padding: 20px 30px;
    }

    .header .menu {
        clear: none;
        float: right;
        max-height: none;
    }

    .header .menu-icon {
        display: none;
    }
}

@media (max-width: 1260px) {

    /* Hide scrollbar for IE, Edge and Firefox */
    .header {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .header ul {
        margin: 0;
        padding: 0;
        list-style: none;
        overflow: hidden;
        background-color: #fff;
    }

    ul.menu.show {
        //border-bottom: 1px solid #ddd;
        background: none;
        transform: translateZ(100px);
        z-index: 999;
    }

    #mobileNav {
        display: table;
        position: relative;
        width: 100%;
    }

    .header .menu-icon {
        cursor: pointer;
        display: inline-block;
        float: right;
        position: relative;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        height: 100%;
        width: 100%;
        display: block;
    }

    .header .menu-icon .navicon {
        background: #333;
        display: block;
        height: 2px;
        position: relative;
        transition: background 0.2s ease-out;
        width: 18px;
        left: 50%;
        top: 50%;
        transform: translateX(-80%);
    }

    .mobile-menuicon-container {
        height: 65px;
        width: 65px;
        display: block;
        position: absolute;
        right: 0;
        z-index: 99999;
    }

    .header .menu-icon .navicon:before,
    .header .menu-icon .navicon:after {
        background: #333;
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        transition: all 0.2s ease-out;
        width: 100%;
        border-top: 1px solid #c3dce6;
    }

    .header .menu-icon .navicon:before {
        top: 5px;
    }

    .header .menu-icon .navicon:after {
        top: -5px;
    }

    .header .logo {
        display: block;
        float: left;
        font-size: 2em;
        padding: 10px 20px;
        text-decoration: none;
        width: 105px;
    }

    .header .disabledLogo {
        display: block;
        float: left;
        font-size: 2em;
        padding: 10px 20px;
        text-decoration: none;
        width: 105px;
        cursor: default;
    }

    .dropdown-menu>li>a:hover {
        background: #fff;
    }

    .header li a:hover {
        background: #e4eff5;
    }

    #ProNav {
        display: none;
    }

    .header li a,
    .mobile-has-dropdown,
    .mobile-normal-menu-item {
        display: block;
        padding: 12px 20px;
        border-right: 1px solid #f4f4f4;
        text-decoration: none;
        background: #e4eff5;
        border-top: 1px solid #c3dce6;
    }

    .header li a:hover,
    .header .menu-btn:hover {
        background-color: #f4f4f4;
    }

    .disabledMobileItem {
        display: block;
        padding: 12px 20px;
        border-right: 1px solid #f4f4f4;
        text-decoration: none;
        background: #e4eff5;
        border-top: 1px solid #c3dce6;
        cursor: default;
    }

    .disabledMobileItem:hover {
        background-color: #e4eff5 !important;
    }
}

@font-face {
  font-family: "RobotoR";
  src: url("../fonts/Roboto-Regular.eot");
  src: url("../fonts/Roboto-Regular.eot") format("embedded-opentype"),
    url("../fonts/Roboto-Regular.woff") format("woff"),
    url("../fonts/Roboto-Regular.ttf") format("truetype"),
    url("../fonts/Roboto-Regular.svg") format("svg");
}

@font-face {
  font-family: "RobotoM";
  src: url("../fonts/Roboto-Medium.eot");
  src: url("../fonts/Roboto-Medium.eot") format("embedded-opentype"),
    url("../fonts/Roboto-Medium.woff") format("woff"),
    url("../fonts/Roboto-Medium.ttf") format("truetype"),
    url("../fonts/Roboto-Medium.svg") format("svg");
}

@font-face {
  font-family: "RobotoB";
  src: url("../fonts/Roboto-Bold.eot");
  src: url("../fonts/Roboto-Bold.eot") format("embedded-opentype"),
    url("../fonts/Roboto-Bold.woff") format("woff"),
    url("../fonts/Roboto-Bold.ttf") format("truetype"),
    url("../fonts/Roboto-Bold.svg") format("svg");
  font-weight: 900;
}

@font-face {
  font-family: "SourceHanSansCNR";
  src: url("../fonts/SourceHanSansCN-Normal.eot");
  src: url("../fonts/SourceHanSansCN-Normal.eot") format("embedded-opentype"),
    url("../fonts/SourceHanSansCN-Normal.woff") format("woff"),
    url("../fonts/SourceHanSansCN-Normal.ttf") format("truetype"),
    url("../fonts/SourceHanSansCN-Normal.svg") format("svg");
}

@font-face {
  font-family: "SourceHanSansCNM";
  src: url("../fonts/SourceHanSansCN-Medium.eot");
  src: url("../fonts/SourceHanSansCN-Medium.eot") format("embedded-opentype"),
    url("../fonts/SourceHanSansCN-Medium.woff") format("woff"),
    url("../fonts/SourceHanSansCN-Medium.ttf") format("truetype"),
    url("../fonts/SourceHanSansCN-Medium.svg") format("svg");
}

body {
  margin: 0;
  padding: 0;
  color: #595959;
  font-size: 0.9vw;
  background: #f8f8f8;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  color: #1a7978;
  text-decoration: none;
  cursor: pointer;
}

a.underline {
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5 {
  padding: 0;
  margin: 0;
  font-weight: normal;
}

p {
  margin: 0;
}

.promotion-nav {
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 9999;
  color: #0980a7;
  font-size: 18px;
  height: 120px;
  display: flex;
  align-items: center;
  transition: all 0.5s;
  width: 100%;
  max-width: 100% !important;

  ç .main-view {
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .promotion-logo img {
    width: 100px;
    height: auto;
  }

  .change-language {
    margin: 0;
  }

  .change-language {
    .each-language {
      display: inline-block;
      width: 32px;
      height: 32px;
      line-height: 32px;
      margin: 0 5px;
      text-align: center;
      cursor: pointer;

      a {
        display: inline-block;
      }

      div {
        position: relative;

        div {
          display: contents;
          display: ruby-base-container;
        }
      }

      img {
        position: absolute;
        width: auto;
        height: 32px;
        left: 0px;
      }
    }
  }

  .change-language {
    .each-language.on {
      background: #0980a7;
      color: #fff;

      a {
        color: #fff;
      }
    }
  }
}

.promotion-nav.scroll-down {
  position: fixed;
  height: 80px;
  background: #fff;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.5s;

  .promotion-logo img {
    width: 80px;
  }
}

.promotion-page {
  font-family: "RobotoR", "SourceHanSansCNR", "Arial", "Microsoft YaHei",
    "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  position: relative;
  width: 100%;

  .popup-bg {
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10000;
  }

  .covid-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10001;
    width: 700px;
    max-width: 95%;
    transform: translate(-50%, -50%);

    img.popup-image {
      max-width: 100%;
    }

    .popup-btn-container {
      position: absolute;
      top: 66%;
      left: 50%;
      z-index: 10002;
      width: 180px;
      transform: translate(-50%, -50%);

      a.popup-btn-href {
        position: absolute;
        padding-top: 10px;
        margin-top: 10px;
        width: 180px;
      }

      img {
        max-width: 96%;
        margin-left: 2%;
      }

      .popup-btn {
        z-index: 1;
        margin-top: -6px;
        transition: 0.3s all;
        position: absolute;
      }

      .popup-btn:hover {
        margin-top: 0px;
        transition: 0.3s all;
      }

      .popup-btn-shadow {
        z-index: 0;
      }
    }

    .popup-btn-container-en {
      top: 67%;
    }

    .close-popup-href {
      position: absolute;
      right: 13%;
      top: 22%;
      width: 40px;
      cursor: pointer;
      opacity: 0.7;
      transition: 0.3s all;
    }

    .close-popup {
      width: 100%;
    }

    .close-popup:hover {
      opacity: 1;
      transition: 0.3s all;
    }

    .display-desktop {
      display: block;
    }

    .display-mobile {
      display: none;
    }
  }

  .promotion-page-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-overflow-scrolling: touch;
  }

  .main-view {
    width: 100%;
  }

  .promotion-banner {
    position: relative;
    width: 100%;
    // background: url('../images/banner_bg.png') no-repeat;
    background-position: top center;
    background-size: cover;
    overflow: hidden;

    .ehealth-website-banner {
      position: relative;
      width: 100%;
      margin-top: 120px;

      .ehealth-website-image {
        width: 100%;
        cursor: pointer;
      }

      .homebanner-link:focus {
        img {
          outline: #1a7978 dotted 2px !important;
        }
      }
    }

    .main-view {
      position: relative;
      padding-top: 40px;
      padding-bottom: 258px;
    }

    .banner-txt {
      position: relative;
      width: 540px;
      margin-left: 290px;
      color: #1a7978;
      z-index: 99;

      h1 {
        font-size: 51px;
        line-height: 60px;
        margin-bottom: 35px;
        font-weight: 700;
      }

      p {
        font-size: 28px;
        line-height: 36px;

        b {
          font-weight: bold;
          /*background-color: rgba(28,176,185,0.18);
					display: inline-block;
					padding: 2px 10px;*/
        }
      }

      p.tc {
        line-height: 45px;
      }

      .app-download {
        margin-top: 35px;

        span {
          cursor: pointer;
        }

        span img {
          width: auto;
          height: 45px;
        }

        a {
          margin-left: 35px;
          display: inline-block;
          padding: 5px;
        }
      }
    }

    .icon-bg {
      position: absolute;
      top: 18%;
      left: 20px;
      width: 234px;
      height: auto;
    }

    .family-bg {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 40%;
      max-width: 600px;
      height: auto;
    }

    .right-bg {
      position: absolute;
      right: -400px;
      bottom: 10%;
      width: 606px;
      height: auto;
      z-index: 0;
    }

    .phone {
      width: 437px;
      height: 547px;
      position: absolute;
      bottom: 0;
      right: 134px;
      overflow: visible;
      z-index: 99;

      .record {
        position: absolute;
        width: 343px;
        height: auto;
        left: -143px;
        bottom: 10px;
        z-index: 111;
        transform: rotateY(90deg);
        transform-origin: right center;
      }

      .phone-screen {
        position: absolute;
        width: 280px;
        height: auto;
        bottom: 0;
        right: 33px;
        opacity: 0;
      }

      .phone-framework {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: auto;
        z-index: 110;
      }

      .phone-bg {
        position: absolute;
        width: 280px;
        height: auto;
        bottom: 0;
        right: 33px;
        z-index: 1;
      }
    }

    .display-desktop {
      display: block;
    }

    .display-mobile {
      display: none;
    }
  }

  .odd-bg.promotion-features {
    background: #f8fdfd;
  }

  .medication-bg.promotion-features {
    // background: url('../images/left_two.png') no-repeat;
    background-size: auto 70% !important;
    background-color: #fff !important;
    background-position: center left !important;
  }

  .appointment-bg.promotion-features {
    // background: url('../images/right_six.png') no-repeat;
    background-size: auto 70% !important;
    background-color: #fff !important;
    background-position: center right !important;
  }

  .ppp-bg.promotion-features {
    // background: url('../images/right_one.png') no-repeat;
    background-size: auto 70% !important;
    background-color: #fff !important;
    background-position: center right !important;
  }

  .mission-bg.promotion-features {
    padding: 80px 0 140px 0;
    // background: url('../images/bottom_bg.png') no-repeat;
    background-size: cover !important;
    background-color: #fff !important;
    background-position: center center !important;
  }

  #promotionDownloadPart {
    // background: url('../images/promotion_bg.png');
    background-size: cover !important;
    background-position: 60% !important;
    margin-top: -10px;

    .promotion-features {
      position: relative;
      width: 100%;
      padding: 0;
      background: 0;
      padding-top: 50px;

      .youtube-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;
        /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
      }

      .responsive-iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border: 0px;
      }

      .promotion-download-content {
        width: 100%;
        display: block;
        margin: auto;
        margin-top: 20px;
        margin-bottom: -1vh;
        height: auto;
        max-width: 700px;
      }

      .main-view {
        width: 780px;
        max-width: 100%;
        margin: 0 auto;
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        overflow: visible;

        div {
          margin: auto;
        }
      }

      .feature-content {
        max-width: 100%;
        overflow: hidden;
      }
    }
  }

  .promotion-features {
    position: relative;
    width: 100%;
    padding: 80px 0;
    background: #fff;
    border: 0px;

    .promotion-download-content {
      width: 100%;
    }

    .feature-title {
      text-align: center;
      color: #1a7978;
      font-weight: 700;
      font-size: 60px;
      line-height: 70px;
      margin-top: 0px;
      margin-bottom: 60px;

      span {
        display: block;
        width: 80px;
        height: 10px;
        background: #ffc059;
        margin: 5px auto;
      }
    }

    .main-view {
      width: 990px;
      margin: 0 auto;
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      overflow: visible;
    }

    .part-title {
      color: #303030;
      font-size: 44px;
      line-height: 57px;
    }

    .part-dec {
      color: #595959;
      font-size: 32px;
      line-height: 44px;
      margin-top: 15px;
      width: 90%;

      span {
        display: block;
      }
    }

    .part-dec.tc {
      width: 100%;
    }

    .display-mobile {
      display: none;
    }

    .display-desktop {
      display: block;
    }

    .image-area {
      position: relative;
      overflow: visible;

      .part-img {
        width: 419px;
        height: auto;
      }

      .news-img {
        width: 453px;
        height: auto;
      }

      .news-icon {
        position: absolute;
        bottom: 0;
        right: -110px;
        width: 165px;
        height: auto;
      }

      .hm-icon {
        position: absolute;
        bottom: -3px;
        right: -110px;
        width: 175px;
        height: auto;
      }

      .doctor-icon {
        position: absolute;
        bottom: 0;
        left: -105px;
        width: 175px;
        height: auto;
      }

      .growth-icon {
        position: absolute;
        bottom: -19px;
        right: -70px;
        width: 175px;
        height: auto;
      }

      .appointment-icon {
        position: absolute;
        bottom: 0;
        right: -110px;
        width: 165px;
        height: auto;
      }

      .adr-icon {
        position: absolute;
        bottom: -19px;
        left: -105px;
        width: 165px;
        height: auto;
      }

      .ppp-icon {
        position: absolute;
        bottom: -19px;
        left: -105px;
        width: 192px;
        height: auto;
      }

      .caregiver-icon {
        position: absolute;
        bottom: -19px;
        right: -110px;
        width: 175px;
        height: auto;
      }
    }

    .left-one-bg {
      position: absolute;
      bottom: -80px;
      left: 109px;
      width: 132px;
      height: auto;
    }

    .right-one-bg {
      position: absolute;
      bottom: 110px;
      right: 0;
      width: 28px;
      height: auto;
    }

    .right-two-bg {
      position: absolute;
      bottom: 80px;
      right: 90px;
      width: 40px;
      height: auto;
    }

    .left-two-bg {
      position: absolute;
      bottom: 0px;
      left: 180px;
      width: 160px;
      height: auto;
    }

    .right-three-bg {
      position: absolute;
      bottom: 40px;
      right: -60px;
      width: 118px;
      height: auto;
    }

    .mission-dec {
      font-weight: 500;
      font-size: 29px;
      line-height: 40px;
      text-align: center;
      width: 850px;
      margin: 0 auto;
      color: #303030;
    }
  }

  .mission-bg .feature-title {
    margin-bottom: 20px;
  }

  .footer {
    background-color: #1a7978;
    color: #fff;
    padding-bottom: 20px;

    table {
      width: 100%;
    }

    a {
      color: #fff;
      text-decoration: none;
    }

    a:focus {
      outline: white dotted 2px;
    }

    .display-desktop {
      width: 330px;
    }

    .display-desktop-en {
      max-width: 350px;
    }

    .display-mobile {
      display: none;
      text-align: center;
    }

    .align-right {
      text-align: right;
    }

    .copyright-warp {
      display: none;
    }
  }

  .copyright {
    font-size: 14px !important;
    line-height: 22px;
    color: #fff;
    height: 60px;
    display: block;
    text-align: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    word-break: break-word;
    margin-top: 40px;

    div {
      position: relative;

      div {
        display: contents;
        display: ruby-base-container;
      }
    }
  }

  .w3clogo {
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 40px;
    cursor: pointer;

    img {
      position: relative;
    }
  }

  .badge {
    font-size: 12px;
    color: #fff;
    padding-bottom: 5px;
  }

  .margin-l-40 {
    margin-left: 40px;
  }

  .margin-l-10 {
    margin-left: 10px;
  }

  .margin-r-40 {
    margin-right: 40px;
  }

  .hyperlink {
    cursor: pointer;
    color: #fff;
  }

  div#fixed_download_bar {
    z-index: 99;

    div#fixed_download_desktop {
      position: fixed;
      bottom: 70px;
      right: 20px;
      z-index: 999;

      img {
        width: 220px;
        height: auto;
      }

      img.close_qr {
        width: 20px;
        height: auto;
        position: absolute;
        cursor: pointer;
        right: 40px;
      }
    }

    .display-desktop {
      display: block;
    }

    .display-mobile {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  html[lang="en"] .footer .badge span {
    display: block;
    word-break: break-word;
    white-space: break-spaces;
    font-size: 12px;
  }

  span.arrow-right-1st {
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid #206ca2;
    display: inline-block;
    margin-left: 10px;
  }

  .covid-popup {
    width: 95%;
    max-width: 450px;
    transform: translate(-50%, -50%);

    .close-popup {
      position: absolute !important;
      right: 5% !important;
      top: 1% !important;
      width: 40px !important;
    }

    .display-desktop {
      display: none !important;
    }

    .display-mobile {
      display: block !important;
    }

    .popup-btn-container {
      top: 55% !important;
      width: 120px !important;
    }

    .popup-btn-container a {
      width: 100% !important;
    }

    .popup-btn-container-en {
      top: 57%;
    }
  }

  .promotion-nav {
    font-size: 14px;
    height: 75px;

    .main-view {
      padding: 0 15px;
    }

    .promotion-logo img {
      width: 65px;
    }

    .change-language {
      .each-language {
        width: 24px;
        height: 24px;
        line-height: 24px;

        div {
          position: relative;

          div {
            display: contents;
            display: ruby-base-container;
          }
        }

        img {
          position: absolute;
          width: auto;
          height: 24px;
          left: 0px;
        }
      }
    }
  }

  .promotion-nav.scroll-down {
    height: 40px;
    background: #fff;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.5s;

    .promotion-logo img {
      width: 40px;
    }
  }

  .promotion-page {
    .promotion-banner {
      .ehealth-website-banner {
        margin-top: 65px;
        margin-bottom: 10px;
        z-index: 1;
      }

      .main-view {
        padding: 20px 20px 0 20px;
      }

      .banner-txt {
        width: 100%;
        position: relative;
        margin-left: 0;
        margin-top: 0px;
        margin-bottom: 30px;

        h1 {
          font-size: 27px;
          line-height: 36px;
          font-weight: 600;
          margin-bottom: 20px;
        }

        p {
          font-size: 16px;
          line-height: 22px;
        }

        p.tc {
          line-height: 26px;
        }

        .app-download {
          text-align: center;

          span {
            margin-left: 15px;
            margin-right: 15px;
            display: block;
          }

          a {
            display: inline-block;
            margin-left: 0;
            width: 100%;
          }

          a:focus {
            outline: #1a7978 dotted 2px !important;
          }

          span:last-child {
            // margin-top: 15px;
            margin-left: 15px;
          }
        }
      }

      .icon-bg {
        width: 98px;
        top: inherit;
        bottom: 160px;
      }

      .family-bg {
        display: none;
      }

      .right-bg {
        width: 340px;
        right: -219px;
        z-index: 0;
      }

      .phone {
        position: inherit;
        width: 223px;
        height: 279px;
        margin: 0 auto;
        right: 20px;

        .record {
          width: 200px;
          left: -60px;
        }

        .phone-screen {
          width: 143px;
          right: 17px;
        }

        .phone-bg {
          width: 143px;
          right: 17px;
        }
      }

      .display-desktop {
        display: none;
      }

      .display-mobile {
        display: block;
      }
    }

    .appointment-bg.promotion-features {
      // background: url('../images/right_six.png') no-repeat;
      background-size: auto 30% !important;
      background-color: #fff;
      background-position: center right !important;
    }

    .medication-bg.promotion-features {
      // background: url('../images/left_two.png') no-repeat;
      background-size: auto 30% !important;
      background-color: #fff;
      background-position: center left !important;
    }

    .ppp-bg.promotion-features {
      // background: url('../images/right_one.png') no-repeat;
      background-size: auto 30% !important;
      background-color: #fff;
      background-position: center right !important;
    }

    .mission-bg.promotion-features {
      padding: 70px 0;
    }

    .promotion-features {
      overflow: hidden;
      padding: 25px 0;

      .feature-title {
        font-size: 28px;
        line-height: 33px;
        margin-top: 60px;
        margin-bottom: 25px;

        span {
          width: 52px;
          height: 7px;
          background: #ffc059;
        }
      }

      .main-view {
        display: block;
        width: 100%;
        padding: 0 50px;
      }

      .mission-dec {
        width: 100%;
        padding: 0 27px;
        font-size: 17px;
        line-height: 24px;
      }

      .part-title {
        text-align: center;
        font-size: 21px;
        line-height: 24px;
      }

      .part-dec {
        text-align: center;
        font-size: 18px;
        line-height: 24px;
        width: 100%;
        margin-bottom: 20px;
        margin-top: 10px;
      }

      .display-mobile {
        display: block;
      }

      .display-desktop {
        display: none;
      }

      .image-area {
        text-align: center;

        .part-img {
          width: 90%;
        }

        .news-img {
          width: 100%;
        }

        .hm-icon {
          bottom: -1px;
          right: -6%;
          width: 35%;
        }

        .news-icon {
          bottom: -1px;
          right: -6%;
          width: 30%;
        }

        .doctor-icon {
          bottom: 0;
          right: auto;
          left: -4% !important;
          width: 32%;
          left: auto;
        }

        .growth-icon {
          bottom: 0;
          right: -9%;
          width: 35%;
        }

        .appointment-icon {
          bottom: 0;
          right: -4%;
          width: 30%;
        }

        .adr-icon {
          bottom: -6px;
          left: -5%;
          width: 30%;
        }

        .ppp-icon {
          bottom: -2px;
          left: -5% !important;
          width: 35%;
          left: auto;
        }

        .caregiver-icon {
          bottom: -3px;
          right: -6%;
          width: 33%;
        }
      }

      .left-one-bg {
        top: 70px;
        left: -5px;
        width: 20%;
      }

      .right-one-bg {
        top: -20px;
        right: 15%;
        width: 8%;
      }

      .right-two-bg {
        top: 10%;
        right: 5%;
        width: 6%;
      }

      .left-two-bg {
        bottom: 55%;
        left: 2%;
        width: 15%;
      }

      .right-three-bg {
        bottom: 0;
        right: 8%;
        width: 16%;
      }
    }

    .copyright {
      font-size: 12px;
      padding-top: 5px;

      .w3clogo {
        margin-right: 20px;
      }
    }

    .margin-l-40 {
      margin-left: 20px;
    }

    .margin-r-40 {
      margin-right: 20px;
    }

    div#fixed_download_bar {
      position: fixed;
      z-index: 99;
      width: auto;
      padding: 0;
      bottom: 55px;
      right: 0;

      img {
        height: 140px;
      }

      span {
        width: 100%;
        text-align: right;
        float: right;
        margin-bottom: -5px;
      }

      .display-desktop {
        display: none !important;
      }

      .display-mobile {
        display: block !important;

        #close_qr_div img {
          width: 20px;
          height: auto !important;
          position: absolute;
          right: 15px;
        }

        #close_qr_div img:focus {
          outline: #1a7978 dotted 2px;
        }
      }
    }

    #promotionDownloadPart {
      background-size: auto 100% !important;
      // background: url('../images/promotion_bg.png');
      background-position: 35% 50%;

      .main-view {
        padding: 0 30px;
      }
    }
  }
}

@media (min-width: 769px) and(max-width: 1000px) {
  .promotion-page {
    .promotion-banner {
      .ehealth-website-banner {
        margin-top: 100px;
      }

      .main-view {
        padding-top: 50px;
        padding-bottom: 200px;
      }

      .banner-txt {
        width: 40%;
        margin-left: 15%;

        h1 {
          font-size: 41px;
          line-height: 48px;
          margin-bottom: 30px;
        }

        p {
          font-size: 20px;
          line-height: 26px;
        }

        p.tc {
          line-height: 32px;
        }

        .app-download {
          span {
            margin-left: 10px;
            margin-right: 10px;
            display: block;
          }

          span:last-child {
            margin-top: 15px;
            margin-left: 10px;
          }
        }
      }

      .icon-bg {
        width: 150px;
      }

      .phone {
        width: 380px;
        height: 476px;
        right: 50px;

        .record {
          width: 300px;
          left: -80px;
        }

        .phone-screen {
          width: 244px;
          right: 28px;
        }

        .phone-bg {
          width: 244px;
          right: 28px;
        }
      }
    }

    .promotion-features {
      .feature-title {
        font-size: 50px;
        line-height: 58px;
      }

      .main-view,
      .mission-dec {
        width: 80%;
      }

      .mission-dec {
        font-size: 26px;
        line-height: 32px;
      }

      .part-title {
        font-size: 37px;
        line-height: 39px;
      }

      .part-dec {
        font-size: 24px;
        line-height: 30px;
      }

      .image-area {
        .part-img {
          width: 360px;
        }

        .news-img {
          width: 400px;
        }

        .hm-icon {
          bottom: 0;
          right: -70px;
          width: 130px;
        }

        .news-icon {
          bottom: 0;
          right: -70px;
          width: 130px;
        }

        .doctor-icon {
          bottom: 0;
          right: -75px;
          width: 135px;
        }

        .growth-icon {
          bottom: -15px;
          right: -70px;
          width: 155px;
        }

        .appointment-icon {
          bottom: 0;
          right: -70px;
          width: 130px;
        }

        .adr-icon {
          bottom: -15px;
          left: -65px;
          width: 130px;
        }

        .ppp-icon {
          bottom: -15px;
          right: -100px;
          width: 160px;
        }

        .caregiver-icon {
          bottom: -15px;
          right: -70px;
          width: 140px;
        }
      }
    }
  }
}

@media (min-width: 1000px) and(max-width: 1200px) {
  .promotion-page {
    .promotion-banner {
      .main-view {
        padding-bottom: 240px;
      }

      .banner-txt {
        width: 400px;
        margin-left: 180px;

        h1 {
          font-size: 41px;
          line-height: 48px;
          margin-bottom: 30px;
        }

        p {
          font-size: 20px;
          line-height: 26px;
        }

        p.tc {
          line-height: 32px;
        }

        .app-download {
          span:last-child {
            margin-left: 20px;
          }
        }
      }

      .icon-bg {
        width: 150px;
      }

      .phone {
        width: 380px;
        height: 476px;

        .record {
          width: 300px;
          left: -80px;
        }

        .phone-screen {
          width: 244px;
          right: 28px;
        }

        .phone-bg {
          width: 244px;
          right: 28px;
        }
      }
    }

    .promotion-features {
      .feature-title {
        font-size: 50px;
        line-height: 58px;
      }

      .main-view,
      .mission-dec {
        width: 80%;
      }

      .mission-dec {
        font-size: 26px;
        line-height: 32px;
      }

      .part-title {
        font-size: 40px;
        line-height: 39px;
      }

      .part-dec {
        font-size: 24px;
        line-height: 30px;
      }

      .image-area {
        .part-img {
          width: 360px;
        }

        .news-img {
          width: 400px;
        }

        .news-icon {
          bottom: 0;
          right: -60px;
          width: 130px;
        }

        .hm-icon {
          bottom: 0;
          right: -60px;
          width: 130px;
        }

        .doctor-icon {
          bottom: 0;
          right: -75px;
          width: 135px;
        }

        .growth-icon {
          bottom: -8px;
          right: -60px;
          width: 160px;
        }

        .appointment-icon {
          bottom: 0;
          right: -60px;
          width: 130px;
        }

        .adr-icon {
          bottom: -15px;
          left: -60px;
          width: 130px;
        }

        .ppp-icon {
          bottom: -15px;
          right: -95px;
          width: 160px;
        }

        .caregiver-icon {
          bottom: -15px;
          right: -60px;
          width: 140px;
        }
      }
    }
  }
}

@media (min-width: 1200px) and(max-width: 1300px) {
  .promotion-page {
    .promotion-banner {
      .main-view {
        padding-bottom: 240px;
      }

      .banner-txt {
        width: 480px;
        margin-left: 260px;
      }

      .icon-bg {
        width: 180px;
        left: 60px;
      }
    }
  }
}

@media (min-width: 1441px) {
  .promotion-nav {
    .main-view {
      padding: 50px 80px;
      width: 1440px;
      margin: 0 auto;
    }
  }

  .promotion-page {
    .main-view {
      width: 1440px;
      margin: 0 auto;
    }

    .promotion-banner {
      .right-bg {
        width: 606px;
      }
    }
  }
}

@media (min-width: 1301px) and(max-width: 1730px) {
  .promotion-page {
    .promotion-banner {
      .icon-bg {
        width: 180px;
        left: 95px;
      }
    }
  }
}

@media (max-width: 500px) {
  .promotion-page {
    .covid-popup {
      .popup-btn-container {
        width: 38vw;
      }
    }
  }
}

@media (min-width: 1800px) {
  .promotion-page {
    .covid-popup {
      width: 850px;
    }
  }
}

.phone-screen.one-img {
  z-index: 101;
  animation: opacityIn 2s forwards;
}

.phone-screen.two-img {
  z-index: 102;
  animation: opacityIn 2s forwards;
}

.phone-screen.three-img {
  z-index: 103;
  animation: opacityIn 2s forwards;
}

.phone-screen.four-img {
  z-index: 105;
  animation: opacityIn 2s forwards;
}

.show-record {
  animation: sketchIn 1s forwards;
}

@keyframes opacityIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes opacityIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes sketchIn {
  from {
    transform: rotateY(90deg);
  }

  to {
    transform: rotateY(0deg);
  }
}

@-webkit-keyframes sketchIn {
  from {
    transform: rotateY(90deg);
  }

  to {
    transform: rotateY(0deg);
  }
}

/*.feature-title,*/
.mission-dec {
  opacity: 1;
  transform: translateY(50px);
}

/*.feature-content{
	opacity: 0;
	transform: translateY(50px);
}*/
.to-front {
  z-index: 1;
}

.part-img {
  opacity: 0;
  transform: scale(0);
  transform-origin: center bottom;
}

.news-icon,
.growth-icon,
.adr-icon,
.caregiver-icon,
.hm-icon {
  opacity: 0;
  transform: scale(0);
  transform: translateX(-300px);
}

.doctor-icon,
.appointment-icon,
.ppp-icon {
  opacity: 0;
  transform: scale(0);
  transform: translateX(300px);
}

#showFearture0 {
  /*.feature-title{
		animation: txtIn 1s forwards;
	}
	.feature-content{
		animation: txtIn 1s 0.5s forwards;
	}*/
  .part-img {
    animation: zoomIn 1s forwards;
  }

  .news-icon {
    animation: iconLeftIn 1s 1.5s forwards;
  }
}

#showFearture1 {
  /*.feature-content{
		animation: txtIn 1s forwards;
	}*/
  .part-img {
    animation: zoomIn 1s forwards;
  }

  .doctor-icon {
    animation: iconRightIn 1s 1.5s forwards;
  }
}

#showFearture2 {
  /*.feature-content{
		animation: txtIn 1s forwards;
	}*/
  .part-img {
    animation: zoomIn 1s forwards;
  }

  .growth-icon {
    animation: iconLeftIn 1s 1.5s forwards;
  }
}

#showFearture3 {
  /*.feature-content{
		animation: txtIn 1s forwards;
	}*/
  .part-img {
    animation: zoomIn 1s forwards;
  }

  .appointment-icon {
    animation: iconRightIn 1s 1.5s forwards;
  }
}

#showFearture4 {
  /*.feature-content{
		animation: txtIn 1s forwards;
	}*/
  .part-img {
    animation: zoomIn 1s forwards;
  }

  .adr-icon {
    animation: iconLeftIn 1s 1.5s forwards;
  }
}

#showFearture5 {
  /*.feature-content{
		animation: txtIn 1s forwards;
	}*/
  .part-img {
    animation: zoomIn 1s forwards;
  }

  .ppp-icon {
    animation: iconRightIn 1s 1.5s forwards;
  }
}

#showFearture6 {
  /*.feature-title{
		animation: txtIn 1s forwards;
	}*/
  .mission-dec {
    animation: txtIn 1s 0.5s forwards;
  }
}

#hideFearture0 {
  /*.feature-title{
		animation: txtOut 1s 0.5s forwards;
	}
	.feature-content{
		animation: txtOut 1s forwards;
	}*/
  .part-img {
    animation: zoomOut 0.5s 1s forwards;
  }

  .news-icon {
    animation: iconLeftOut 0.5s forwards;
  }
}

#hideFearture1 {
  /*.feature-content{
		animation: txtOut 1s forwards;
	}*/
  .part-img {
    animation: zoomOut 0.5s 1s forwards;
  }

  .doctor-icon {
    animation: iconRightOut 0.5s forwards;
  }
}

#hideFearture2 {
  /*.feature-content{
		animation: txtOut 1s forwards;
	}*/
  .part-img {
    animation: zoomOut 0.5s 1s forwards;
  }

  .growth-icon {
    animation: iconLeftOut 0.5s forwards;
  }
}

#hideFearture3 {
  /*.feature-content{
		animation: txtOut 1s forwards;
	}*/
  .part-img {
    animation: zoomOut 0.5s 1s forwards;
  }

  .appointment-icon {
    animation: iconRightOut 0.5s forwards;
  }
}

#hideFearture4 {
  /*.feature-content{
		animation: txtOut 1s forwards;
	}*/
  .part-img {
    animation: zoomOut 0.5s 1s forwards;
  }

  .adr-icon {
    animation: iconLeftOut 0.5s forwards;
  }
}

#hideFearture5 {
  /*.feature-content{
		animation: txtOut 1s forwards;
	}*/
  .part-img {
    animation: zoomOut 0.5s 1s forwards;
  }

  .ppp-icon {
    animation: iconRightOut 0.5s forwards;
  }
}

#hideFearture6 {
  /*.feature-title{
		animation: txtOut 1s 0.5s forwards;
	}*/
  .mission-dec {
    animation: txtOut 1s forwards;
  }
}

*:focus {
  outline: #1a7978 dotted 2px;
}

.hyperlink:focus {
  outline: #ffffff dotted 2px;
}

.w3clogo:focus {
  outline: #ffffff dotted 2px;
}

@keyframes txtIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes txtIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes iconLeftIn {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes iconLeftIn {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes iconRightIn {
  from {
    opacity: 0;
    transform: translateX(50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes iconRightIn {
  from {
    opacity: 0;
    transform: translateX(50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes txtOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(50px);
  }
}

@-webkit-keyframes txtOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(50px);
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}

@-webkit-keyframes zoomOut {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes iconLeftOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-50px);
  }
}

@-webkit-keyframes iconLeftOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-50px);
  }
}

@keyframes iconRightOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(50px);
  }
}

@-webkit-keyframes iconRightOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(50px);
  }
}

@media (max-width: 1043px) {
  .footer {
    table {
      margin-top: 20px;
    }

    .display-desktop {
      display: none !important;
    }

    .display-mobile {
      display: block !important;
    }
  }

  .footer .badge {
    margin-left: 0px !important;
    margin-right: 0px !important;
    white-space: break-spaces;
    text-align: center !important;
  }

  .w3clogo {
    margin-right: 0px !important;
  }
}

@media (max-width: 1584px) {
  .footer {
    .copyright-warp-en {
      display: block !important;
    }

    .copyright-en {
      margin-top: 0px !important;
    }

    .copyright-en .margin-l-40 {
      margin-left: 0px !important;
    }
  }
}

@media (max-width: 1296px) {
  .footer {
    .copyright-warp {
      display: block !important;
    }

    .copyright {
      margin-top: 0px !important;
    }

    .copyright .margin-l-40 {
      margin-left: 0px !important;
    }
  }
}

@media (max-width: 1188px) {
  .footer {
    .display-desktop-en {
      max-width: none !important;
    }
  }
}

.promulgation-page {
  .display-mobile {
    display: none;
  }

  // .ehealth-website-banner {
  //     position: relative;
  //     width: 100%;
  //     margin-top: 120px;
  // }
  .center-div {
    margin: auto;
  }

  .second-feature-title {
    color: #337ab7;
  }

  .promulgation-section-content:after {
    content: "";
    width: 150%;
    height: 1px;
    background: #bee0e5;
    position: absolute;
    bottom: -20px;
    right: 10%;
    z-index: -1;
  }

  .second-youtube-container {
    width: 900px;
    padding-top: 506px;
    box-shadow: none;
  }

  .promulgation-section {
    margin-bottom: 40px;
  }

  .promulgation-icon {
    width: 70px;
    margin-bottom: 10px;
  }

  .promulgation-section-title {
    font-size: 27px;
    color: #357bb2;
    font-weight: 700;
    line-height: 1.3em;
    margin-bottom: 10px;
  }

  .promulgation-section-button {
    font-size: 18px;
  }

  .section2n .promulgation-section-content:after {
    left: 10%;
  }

  .promulgation-section-content {
    padding-left: 12% !important;
  }

  .section2n .promulgation-section-content {
    padding-left: 10% !important;
  }

  .container.introduction-text-container {
    padding: 24px 0px;
    font-size: 18px;
    line-height: 30px;
  }
}

.promulgation-promotion-truck-page {
  .display-mobile {
    display: none;
  }

  .ehealth-website-banner {
    position: relative;
    width: 100%;
  }

  .container.content-title-container {
    text-align: center;
    padding: 18px 0px;
  }

  .content-title {
    display: inline-block;
    padding: 12px 12px;
    margin: auto;
  }

  span.content-title-text {
    font-size: 28px;
    text-align: center;
    font-weight: 900;
    color: #0079c1;
    margin-bottom: 5px;
    font-family: "RobotoB";
  }

  img.content-title-icon {
    height: 45px;
    margin-top: -15px;
  }

  .swiper-container {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
    transition: all 1s;
  }

  .swiper-nav-container {
    text-align: center;
  }

  span.swiper-nav-item {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: #d9d9d9;
    border-radius: 50%;
    margin: 0px 8px;
    cursor: pointer;
  }

  span.swiper-nav-item.active {
    background: #0079c1;
  }

  .swiper-item.inactive {
    opacity: 0.4;
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
  }

  @keyframes slide-out {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0.4;
    }
  }

  @-webkit-keyframes slide-out {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0.4;
    }
  }
}

.promulgation-promotional-videos-page {
  .ehealth-website-banner {
    position: relative;
    width: 100%;
  }

  span.content-title-text {
    font-size: 30px;
    text-align: center;
    font-weight: 900;
    color: #0079c1;
    margin-bottom: 5px;
    font-family: "RobotoB";
  }

  img.content-title-icon {
    height: 45px;
    margin-top: -15px;
  }

  .container.introduction-text-container {
    padding: 24px 0px;
    font-size: 18px;
    line-height: 30px;
  }
}

.promulgation-promotional-videos-page .second-youtube-container,
.promulgation-page .second-youtube-container {
  width: 1100px;
  padding-top: 620px;
}

.promulgation-landing-video {
  margin-top: 20px;
  margin-bottom: 50px;
}

.promulgation-landing-intro {
  margin-top: 30px;
}

.video-title-container {
  padding-left: 0px;
}

.promulgation-page-title img {
  margin-right: 10px;
}

.promulgation-page-title {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
}

.video-title-container {
  text-align: center;
  margin-bottom: 20px;
}

.promulgation-promotional-videos-page .col-md-6 .second-youtube-container {
  padding-top: 302px;
}

.promulgation-promotional-videos-page .col-md-8 .second-youtube-container {
  padding-top: 395px;
}

.promulgation-promotional-videos-page .col-md-6:first-child {
  padding-left: 0px;
}

.promulgation-promotional-videos-page .col-md-6:last-child {
  padding-right: 0px;
}

.promotional-videos-full {
  margin-bottom: 50px;
}

.truck-slider-container {
  margin-bottom: 50px;
}

.promulgation-section-title br {
  content: "";
}

.promulgation-section-title br:after {
  content: " ";
}

.promulgation-landing-intro,
.promulgation-item-intro {
  font-size: 19px;
  line-height: 1.6em;
  color: #333;
}

.promulgation-back a {
  color: #fff;
  font-size: 16px;
}

.promulgation-back {
  text-align: center;
  background: #337ab7;
  display: block;
  width: 120px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 10px 5px;
  border-radius: 100px;
}

.promulgation-back a:hover {
  color: #fff;
  text-decoration: none;
}

.schedule-footnote {
  margin-top: 30px;
  font-size: 12px;
  color: #666;
}

.mt-20 {
  margin-top: 20px;
}

.promulgation-social-media-icon {
  width: 30px;
  display: inline;
  margin-left: 5px;
}

.promulgation-download-section a,
.promulgation-social-media-section a {
  display: block;
  margin-bottom: 10px;
}

.truck-event-photos .col-md-6 {
  padding: 10px;
}

.text-highlight {
  font-size: 23px;
  text-align: center;
  background: #0179c1;
  color: #fff;
  padding: 10px;
  margin-top: 20px;
}

.interactive-pic-book-download img {
  width: 25px;
  margin-left: 5px;
}

.interactive-pic-book-download a {
  text-align: center;
  background: #337ab7;
  display: table;
  width: auto;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 12px;
  padding: 15px 50px;
  border-radius: 5px;
  color: #fff;
  font-size: 22px;
}

.interactive-pic-book-download {
  text-align: center;
  font-size: 15px;
  padding-bottom: 50px;
  border-bottom: 2px solid #c2ddee;
  margin-bottom: 30px;
}

.ad-item-title {
  background: #eef7fc;
  padding: 12px 25px 2px 25px;
  color: #094e84;
  font-weight: 800;
  font-size: 19px;
  display: table;
  border-radius: 10px 20px 0px 0px;
}

.ad-item-title img {
  height: 40px;
  margin-right: 10px;
}

img.advertisement-photo {
  border-top: 8px solid #eef7fb;
}

.advertisement-item-row {
  margin-bottom: 40px;
}

hr.blueborder {
  border-width: 2px;
  border-color: #c2ddee;
}

.interactive-pic-book-download a:hover {
  background: #26669d;
  color: #fff;
  text-decoration: none;
}

.storytelling-photo-container img {
  margin-bottom: 20px;
}

.storytelling-photo-container br {
  display: none;
}

ul.dropdown-menu li {
  margin-bottom: -1px;
}

@media (max-width: 1799px) {
  .promulgation-promotional-videos-page
    .promotional-videos-full
    .second-youtube-container,
  .promulgation-page .second-youtube-container {
    width: 900px;
    padding-top: 507px;
  }
}

@media (max-width: 992px) {
  .promulgation-page .second-youtube-container,
  .promulgation-promotional-videos-page
    .promotional-videos-full
    .second-youtube-container {
    padding-top: 57%;
    width: 750px !important;
  }

  .promulgation-animation-container
    .col-lg-6.col-sm-12
    .second-youtube-container {
    padding-top: 57% !important;
    width: 750px !important;
  }
  .promulgation-promotional-videos-page .col-md-6 .second-youtube-container,
  .promulgation-promotional-videos-page .col-md-8 .second-youtube-container {
    width: auto;
    padding-top: 57%;
  }
}

@media (max-width: 768px) {
  .promulgation-page .section2n .promulgation-section-content,
  .promulgation-page .promulgation-section-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .promulgation-page .promulgation-section-content:after {
    display: none;
  }

  .promulgation-page .promulgation-section-content div {
    text-align: center;
    padding-left: 0px !important;
  }

  .promulgation-page .promulgation-icon {
    margin-left: auto;
    margin-right: auto;
  }

  .promulgation-promotion-truck-page .static_page_header,
  .promulgation-promotional-videos-page .static_page_header {
    padding-top: 0%;
    margin-bottom: 0px;
  }

  .promulgation-page .static_page_header {
    padding-top: 0%;
  }

  .promulgation-promotion-truck-page .static_page_header img,
  .promulgation-promotional-videos-page .static_page_header img,
  .promulgation-page .static_page_header img {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0px;
  }

  .promulgation-landing-intro,
  .promulgation-item-intro {
    font-size: 14px;
    line-height: 1.6em;
    color: #333;
  }

  .truck-schedule img {
    margin-bottom: 10px;
  }

  .promulgation-promotion-truck-page .container.content-title-container {
    padding-top: 20px;
  }

  .promulgation-promotion-truck-page .static_page_header {
    margin-bottom: 0px;
  }

  .promulgation-page .promulgation-section-title {
    font-size: 22px;
  }

  .promulgation-promotion-truck-page .display-mobile {
    display: block;
  }

  .promulgation-promotion-truck-page .display-desktop {
    display: none;
  }

  .promotional-videos-full .second-youtube-container {
    padding-top: 57%;
  }

  .promulgation-promotional-videos-page .col-md-6 .second-youtube-container,
  .promulgation-promotional-videos-page .col-md-8 .second-youtube-container {
    padding-top: 56%;
  }

  .promulgation-promotional-videos-page .col-md-6,
  .promulgation-promotional-videos-page .col-md-8 {
    padding: 0px !important;
    margin-bottom: 20px;
  }

  .promulgation-promotion-truck-page span.content-title-text,
  .promulgation-promotional-videos-page span.content-title-text {
    font-size: 22px;
    line-height: 1.2em;
  }

  .ad-item-title {
    font-size: 16px;
    padding: 9px 17px 2px 17px;
    border-radius: 5px;
  }

  .ad-item-title img {
    height: 29px;
  }

  img.advertisement-photo {
    margin-bottom: 40px;
  }

  .advertisement-item-row {
    margin-bottom: 0px;
  }

  .interactive-pic-book-download img {
    width: 24px;
  }

  .interactive-pic-book-download a {
    font-size: 16px;
    width: 100%;
    padding: 10px;
  }

  .text-highlight {
    font-size: 14px;
    margin-bottom: 20px;
  }
}

.page-elife-earn-point {
  .display-desktop {
    display: block;
  }

  .display-mobile {
    display: none;
  }

  @media (max-width: 768px) {
    .display-desktop {
      display: none;
    }

    .display-mobile {
      display: block;
    }
  }

  .hero-banner {
    position: relative;
    display: flex;
    justify-content: center;
    background-color: #eaf7ff;
    margin-top: 100px;

    @media (max-width: 1259px) {
      margin-top: 65px;
    }
  }
}

.page-elife-leaderboard {

    .display-desktop {
        display: block;
    }

    .display-mobile {
        display: none;
    }

    @media (max-width: 768px) {
        .display-desktop {
            display: none;
        }

        .display-mobile {
            display: block;
        }
    }

    .hero-banner {
        position: relative;
        display: flex;
        justify-content: center;
        background-color: #eaf7ff;
        margin-top: 100px;

        @media (max-width:1259px) {
            margin-top: 65px;
        }

        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 70%;
        }

        .display-desktop {
            display: block;
        }

        .display-mobile {
            display: none;
        }

        @media (max-width: 768px) {
            .display-desktop {
                display: none;
            }

            .display-mobile {
                display: block;
                width: 100vw;
            }
        }
    }

    .container-fluid {
        background: linear-gradient(180deg, #EAF8FF 0%, #FFFFFF 100%);
        position: relative;


        .deco {
            position: absolute;

            @media (max-width: 768px) {
                display: none;
            }

            &.deco-1 {
                top: 369px;
                right: 0;
                transform: translateX(30%);
            }

            &.deco-2 {
                top: 691px;
                left: 0;
                transform: translateX(-50%);
            }


        }

        .main-container {
            display: flex;
            flex-direction: column;
            background-image: url(../images/elife-leaderboard/ranking-bg.png);
            background-position: top center;

            @media (max-width: 768px) {
                background-image: none;
            }

            .tabs {
                display: inline-flex;
                background-color: #1F75A0;
                border-radius: 55px;
                margin: 58px auto 30px;

                @media (max-width: 768px) {
                    margin: 17px auto 20px;
                }

                .tab {
                    padding: 14px 40px;
                    border: 3px solid #1F75A0;
                    cursor: pointer;
                    transition: all 0.2s;
                    color: #fff;
                    font-size: 18px;
                    border-radius: 55px;

                    @media (max-width: 768px) {
                        font-size: 16px;
                        padding: 8px 30px;
                    }

                    &.active {
                        background-color: #fff;
                        font-weight: 700;
                        color: #1E5672;
                    }
                }
            }

            .ranking-header {
                width: 156px;
                height: auto;
                display: none;
                margin: 0 auto -3px;

                @media (max-width: 768px) {
                    display: block;
                }
            }

            .top-3 {
                display: flex;
                flex-direction: row;
                padding: 0 20px;


                .rank {
                    flex: 33%;

                    .inner {
                        position: relative;
                        height: 0;
                        width: 100%;
                        padding-top: 156%;

                        >* {
                            position: absolute;
                        }

                        .number {
                            width: 60%;
                            text-align: center;
                            bottom: 62%;
                            left: 50%;
                            transform: translateX(-50%);
                            color: #333;
                            font-weight: 700;
                            font-size: 20px;

                            .text {
                                display: block;
                            }

                            .integer {
                                display: none;
                            }
                        }

                        .name {
                            width: 60%;
                            text-align: center;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-weight: 700;
                            font-size: 23px;
                            top: 63%;
                        }

                        .bg {
                            width: 100%;
                            height: auto;
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            max-width: unset;
                        }
                    }

                    &.rank-1 {
                        order: 2;

                        .name {
                            color: #563900;
                            font-size: 28px;
                            top: 60%;
                        }

                        .number {
                            bottom: 67%;
                        }

                        .bg {
                            width: 123%;
                        }
                    }

                    &.rank-2 {
                        order: 1;

                        .name {
                            color: #333;
                        }
                    }

                    &.rank-3 {
                        order: 3;

                        .name {
                            color: #7B3A15;
                        }
                    }

                    @media (max-width: 992px) {
                        .inner {
                            .number {
                                font-size: 20px;
                            }

                            .name {
                                font-size: 19px;
                            }
                        }

                        &.rank-1 {
                            .name {
                                font-size: 19px;
                            }
                        }
                    }

                }
            }

            @media (max-width: 768px) {
                .top-3 {
                    flex-wrap: wrap;
                    flex-direction: column;
                    padding: 0;

                    .rank {
                        flex: 1;
                        width: 100%;
                        padding: 4px;
                        box-sizing: border-box;
                        border-radius: 5px;
                        box-shadow: 0px 5px 7px 0px #29517F1A;
                        margin-bottom: 8px;

                        img {
                            display: none;
                        }

                        .inner {
                            padding-top: unset;
                            height: unset;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            padding: 24px;
                            background: #fff;
                            border-radius: 3px;

                            >* {
                                position: unset;
                            }

                            .number {
                                position: unset;
                                transform: unset;
                                font-size: 20px;
                                font-weight: 700;
                                width: 32px;
                                margin-right: 5px;
                                text-align: left;

                                .text {
                                    display: none;
                                }

                                .integer {
                                    display: block;
                                }
                            }

                            .name {
                                font-size: 16px;
                                color: #444;
                                transform: unset;
                                position: unset;
                                font-weight: 400;
                                width: 100%;
                                text-align: left;
                            }
                        }

                        &.rank-1 {
                            order: 1;
                            background-image: linear-gradient(90deg, #DF9502 0%, #FAE58C 37%, #DF9502 66.5%, #795101 100%);

                            .number {
                                color: #8D5E01;
                            }
                        }

                        &.rank-2 {
                            order: 2;
                            background-image: linear-gradient(90deg, #BBBBBB 0%, #DDDDDD 37%, #AAAAAA 66.5%, #CCCCCC 100%);

                            .number {
                                color: #777;
                            }
                        }

                        &.rank-3 {
                            order: 3;
                            background-image: linear-gradient(90deg, #EDC693 0%, #FCE3C1 37%, #D9B482 66.5%, #EDC693 100%);

                            .number {
                                color: #7B3A15;
                            }
                        }
                    }
                }
            }

            .update-time {
                text-align: center;
                font-size: 16px;
                margin-bottom: 10px;

                @media (max-width: 768px) {
                    font-size: 14px;
                }
            }

            .rankings {
                background-color: #fff;
                border: 1px solid #CBECFF;
                border-radius: 5px;
                box-shadow: 0px 4px 4px 0px #0000000D;
                padding: 11px 24px;
                width: 100%;
                margin: 32px auto 0;

                .rank {
                    padding: 24px 0;
                    border-bottom: 1px solid #ccc;
                    display: flex;
                    align-items: center;

                    .number {
                        font-size: 24px;
                        font-weight: 700;
                        text-align: center;
                        width: 50px;
                        margin-right: 16px;
                        border-right: 1px solid #aaaaaa;
                        color: #666;
                    }

                    .name {
                        font-size: 22px;
                        color: #444;
                    }

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }

            @media (max-width: 768px) {
                .rankings {
                    padding: 8px 24px;

                    .rank {
                        padding: 16px 0;

                        .number {
                            font-size: 18px;
                            text-align: left;
                            width: 24px;
                            border: none;
                            margin-right: 8px;
                        }

                        .name {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .footer-image {
            width: 100%;
            max-width: 1099px;
            margin: 167px auto 0;

            @media (max-width: 768px) {
                margin-top: 91px;
            }
        }
    }
}